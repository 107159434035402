import React from "react";
import { StyledAnimateFadeIn } from "./styles.js";

const AnimateFadeIn = ({ children, delay }) => {
  return (
    <StyledAnimateFadeIn
      viewport={{ 
        once: true,
        amount: "some",
      }}
      initial={{
        opacity: 0,
        y: "3rem",
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        duration: 0.5,
        ease: "easeOut",
        delay: delay,
      }}
    >
      {children}
    </StyledAnimateFadeIn>
  );
};

export default AnimateFadeIn;
