import styled, { css } from "styled-components";

export const StyledMenuIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 0;
  transform: translateY(-45%);
  pointer-events: none;
  transition: all 0.2s ease;
  right: -20%;

  [data-is-menu-open="true"] & {
    opacity: 0.2;
    transition: all 2.5s ease;
    transform: translateY(-50%);
  }

  svg {
    position: absolute;
    width: auto;
    top: 50%;
    right: 0;
    width: 170%;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      top: 50%;
      transform: translateY(-50%);
      height: 160vh;
    `)}
  }
`;

export const StyledMenuWrapper = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      width: 100%;
      display: flex;
  `)}
`;

export const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  color: ${({ theme }) => theme.colors.white};
  padding: calc(100vw / 12 * 1.5) calc(100vw / 12 * 1);
  display: flex;
  align-items: center;
  pointer-events: none;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding: calc(100vw/12 * 1.5) calc(100vw/12 * 1);
  `)}

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s ease 0.5s;
    pointer-events: none;
  }

  &[data-is-menu-open="true"] {
    opacity: 1;
    pointer-events: visible;

    &:after {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }

  a,
  [data-menu-showreel] span,
  [data-switch-lang] a {
    transition: color 0.2s ease;
  }

  [data-switch-lang] {
    position: relative;
    margin-top: 4rem;
    z-index: 2;
    text-transform: uppercase;
    display: flex;

    > div {
      &:first-child {
        margin-right: 2rem;
        a {
          margin-right: 2rem;
        }
        &::after {
          content: "/";
          display: inline-block;
        }
      }
    }

    a {
      &[data-disabled="true"] {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  a {
    .no-touch & {
      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }

  [data-menu-showreel] {
    .no-touch & {
      &:hover {
        span {
          color: ${({ theme }) => theme.colors.accent};
        }
      }
    }
  }
`;

export const StyledMenuMain = styled.div`
  padding-bottom: calc(100vw / 12 * 1);
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      padding-right: calc(100vw / 12 * 1);
  `)}

  li {
    margin-top: 1.2rem;
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.5s ease;

    a {
      display: inline-block;
      cursor: pointer;
    }

    [data-is-menu-open="true"] & {
      opacity: 1;
      transform: translateY(0%);

      &:nth-child(1) {
        transition-delay: 0.05s;
      }
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.15s;
      }
      &:nth-child(4) {
        transition-delay: 0.2s;
      }
      &:nth-child(5) {
        transition-delay: 0.25s;
      }
      &:nth-child(6) {
        transition-delay: 0.3s;
      }
      &:nth-child(7) {
        transition-delay: 0.35s;
      }
      &:nth-child(8) {
        transition-delay: 0.8s;
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const StyledMenuOthers = styled.div`
  font-weight: lighter;
  color: #cdcdcd;
  li {
    margin-top: 1.2rem;
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.5s ease;

    a {
      display: inline-block;
      cursor: pointer;
    }

    &[data-menu-showreel] {
      span {
        display: flex;
        align-items: center;
      }
      svg {
        margin-left: 1rem;
        path {
          stroke: currentColor;
          stroke-width: 2px;
        }
      }
    }

    [data-is-menu-open="true"] & {
      opacity: 1;
      transform: translateY(0%);

      &:nth-child(1) {
        transition-delay: 0.05s;
      }
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.15s;
      }
      &:nth-child(4) {
        transition-delay: 0.2s;
      }
      &:nth-child(5) {
        transition-delay: 0.25s;
      }
      &:nth-child(6) {
        transition-delay: 0.3s;
      }
      &:nth-child(7) {
        transition-delay: 0.35s;
      }
      &:nth-child(8) {
        transition-delay: 0.8s;
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
`;
