const EditorialData = ({ text, list, cta }) => {
  const normalizedData = {
    text,
    list,
    cta
  };

  return normalizedData;
};

export default EditorialData;
