import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledHeader = styled(motion.header)`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  svg {
    transition: fill 0.5s ease;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
    path {
      transition: fill .2s;
    }
  }

  &[data-header-theme="dark"] {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
    [data-is-menu-open] {
      > div {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &[data-header-theme="light"],  &[data-header-theme="grey"] {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
    [data-is-menu-open] {
      > div {
        background-color: ${({ theme }) => theme.colors.black};
      }
    }
  }
`;

export const StyledHeaderWrapper = styled.div`
  color: white;
  padding: 1.6rem 2.4rem;
  will-change: transform;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.5s ease;

  [data-header-type="visible"] & {
    transform: translateY(0);
  }

  [data-header-type="hidden"] & {
    transform: translateY(-100%);
  }
  ${({ theme }) => theme.mediaQuery.desktop(`padding: 2.8rem 5.4rem`)}

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 130%;
    position: absolute;
    opacity: .4;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
    background: linear-gradient(0deg, #00000000, black);
  }
`;

export const StyledHeaderIcon = styled.div`
  width: 3rem;
  cursor: pointer;
  pointer-events: visible;
  ${({ theme }) => theme.mediaQuery.desktop(`width: 4rem;`)}

  .-logotypo {
    opacity: 0;
    transition: all .4s ease;
    [data-header-type="top"] & {
      opacity: 1;
    }
  }
`;

export const StyledHeaderHamburger = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2.2rem;
  width: 2.8rem;
  height: 2.8rem;
  transition: transform 0.3s ease;
  cursor: pointer;
  pointer-events: visible;

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    width: 7.2rem;
    height: 7.2rem;
    right: 5.4rem;
  `)};

  > div {
    pointer-events: none;
    height: 2px;
    width: 1.8rem;
    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      width: 6rem;
    `)};
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    transform-origin: 0 center;
    transition: all 0.3s ease;
    transition: transform 0.3s ease;
    &:first-child {
      transform: translate(-50%, -0.4rem);
      ${({ theme }) =>
        theme.mediaQuery.desktop(`
        transform: translate(-50%, -1rem);
      `)};
    }
    &:last-child {
      transform: translate(-50%, 0.4rem);
      ${({ theme }) =>
        theme.mediaQuery.desktop(`
        transform: translate(-50%, 1rem);
      `)};
    }
  }

  &[data-is-menu-open="false"] {
    &:hover {
      .no-touch & {
        transform: translateY(-50%) scaleX(0.5);
      }
    }
  }

  &[data-is-menu-open="true"] {
    transform: translateY(-50%) scale(1);
    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      transform: translateY(-50%) scale(1.5);
    `)};
    > div {
      &:first-child {
        transform: translate(-22.5%, -0.64rem) rotate(45deg) scaleX(1);
      }
      &:last-child {
        transform: translate(-22.5%, 0.64rem) rotate(-45deg) scaleX(1);
      }
      ${({ theme }) =>
        theme.mediaQuery.desktop(`
          &:first-child {
            transform: translate(-22.5%, -1rem) rotate(45deg) scaleX(0.48);
          }
          &:last-child {
            transform: translate(-22.5%, 1rem) rotate(-45deg) scaleX(0.48);
          }
      `)};
    }
  }
`;
