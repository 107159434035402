import React from "react";
import { Icon } from "../";
import { StyledRegularLink } from "../Typography";
import {
  StyledPrefooter,
  StyledPrefooterTitle,
  StyledPrefooterLogo,
  StyledPrefooterLink,
} from "./styles.js";

const Prefooter = ({ text, link }) => {
  return (
    <StyledPrefooter>
      <StyledPrefooterLogo>
        <Icon name="pre-footer-bolt" />
      </StyledPrefooterLogo>
      <StyledPrefooterTitle>{text}</StyledPrefooterTitle>
      {link && (
        <StyledPrefooterLink>
          <div className="js-anim-reveal-fade-in">
            <StyledRegularLink href={link.href}>
              <div>
                <Icon name="caret-right" />
                <span>{link.text}</span>
                <Icon name="caret-right" />
              </div>
            </StyledRegularLink>
          </div>
        </StyledPrefooterLink>
      )}
    </StyledPrefooter>
  );
};

export default Prefooter;
