import styled, { css } from "styled-components";

export const StyledQuote = styled.div`
  ${({ theme }) => theme.mediaQuery.tablet(`
      padding-top: 0;
      padding-bottom: 0;
      padding-left: ${theme.col(1)};;
      padding-right: ${theme.col(1)};;
  `)}
`;

export const StyledQuoteSlide = styled.div`
  text-align: center;
  padding: 0 ${({ theme }) => theme.col(1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  strong {
    margin-top: 5.6rem;
    font-weight: 600;
  }

  span {
    color: ${({ theme }) => theme.colors.white20};
  }
`;

export const StyledQuoteThumb = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 3px solid transparent;

  .no-touch & {
    &:hover {
      border-color: ${({ theme }) => theme.colors.accent};
    }
  }

  .swiper-slide-thumb-active & {
    border-color: ${({ theme }) => theme.colors.accent};
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`;

export const StyledQuotePagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;

  .swiper-slide {
    width: auto;
    transform: scale(0.55);
    margin: 0 2rem;
    transition: transform 0.3s ease;

    &.swiper-slide-thumb-active {
      transform: scale(1);
    }
  }
`;
