const HeroProject = ({ media, title, customer }) => {
  const normalizedData = {
    media,
    title,
    customer,
  };

  return normalizedData;
};

export default HeroProject;
