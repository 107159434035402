const ListProjects = ({
  title,
  load_more,
  reel,
  list,
  unit_filters,
  industry_filters,
  placeholder,
}) => {
  const normalizedData = {
    title: title,
    load_more: load_more,
    reel: reel,
    list: list,
    unit_filters: unit_filters,
    industry_filters: industry_filters,
    placeholder,
  };

  return normalizedData;
};

export default ListProjects;
