import React from "react";
import {
  StyledEditorialMedia,
  StyledEditorialMediaContent,
  StyledEditorialMediaImage,
} from "./styles.js";
import { Headline, SubHeaderSmall } from "../Typography/index.js";
import {
  Media,
  Richtext,
  AnimateFadeIn,
  EditorialWrapper,
  WrapperLink,
  Cta,
} from "../";

const EditorialMedia = ({ title, text, image, alignment, cta }) => {
  return (
    <StyledEditorialMedia data-alignment={alignment}>
      <StyledEditorialMediaContent>
        <EditorialWrapper>
          <Headline data-title>
            <AnimateFadeIn>{title}</AnimateFadeIn>
          </Headline>
          <div>
            <div data-richtext>
              <AnimateFadeIn>
                <SubHeaderSmall>
                  <Richtext>{text}</Richtext>
                </SubHeaderSmall>
              </AnimateFadeIn>
            </div>
            {cta.label && (
              <div data-cta>
                <AnimateFadeIn>
                  <WrapperLink data={cta}>
                    <Cta>{cta.label}</Cta>
                  </WrapperLink>
                </AnimateFadeIn>
              </div>
            )}
          </div>
        </EditorialWrapper>
      </StyledEditorialMediaContent>

      <StyledEditorialMediaImage>
        <Media image={image} />
      </StyledEditorialMediaImage>
    </StyledEditorialMedia>
  );
};

export default EditorialMedia;
