import React, { useContext, useEffect, useRef } from "react";
import { StyledCursor } from "./styles.js";
import { MouseContext } from "../../contexts/Mouse.js";
import { Icon } from "../index.js";

const Cursor = () => {
  const { cursorType, cursorText } = useContext(MouseContext);
  const domCursor = useRef(null);

  useEffect(() => {
    if (document.querySelector("body").classList.contains("is-touch")) return;
    const initCursor = async () => {
      const gsap = (await import('gsap')).default
      const Observer = (await import('gsap/dist/Observer')).default
      gsap.registerPlugin(Observer);
  
      let xTo = gsap.quickTo(domCursor.current, "x", {
        duration: 0.4,
        ease: "power2.easeInOut",
      });
      let yTo = gsap.quickTo(domCursor.current, "y", {
        duration: 0.4,
        ease: "power2.easeInOut",
      });
  
      Observer.create({
        target: window, // can be any element (selector text is fine)
        type: "pointer", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
        onMove: (e) => {
          xTo(e.event.x);
          yTo(e.event.y);
        },
      });
    }
    initCursor().catch(console.error);
  }, []);

  return (
    <StyledCursor data-type={cursorType} ref={domCursor}>
      <div data-circle></div>

      <div data-text>{cursorText}</div>

      <div data-icon-arrow>
        <Icon name="caret-right" />
      </div>

      <div data-icon-drag>
        <Icon name="caret-right" />
        <Icon name="caret-right" />
      </div>
    </StyledCursor>
  );
};

export default Cursor;
