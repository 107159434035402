const VideoPlayer = ({ video_id, poster }) => {
  const normalizedData = {
    video_id,
    poster,
  };

  return normalizedData;
};

export default VideoPlayer;
