import {
  StyledPeopleHero,
  StyledPeopleHeroParallax,
  StyledPeopleHeroCase,
  StyledPeopleHeroTitle,
  StyledPeopleHeroCta,
} from "./styles.js";

import { Display, StyledRegularLink } from "../Typography";

import {
  Media,
  AnimateFadeIn,
  AnimateSplitFadeIn,
  WrapperLink,
  AnimateParallax,
  Cta,
} from "../";

const renderItem = (items) => {
  return items.map((item, i) => {
    return (
      <StyledPeopleHeroCase key={i}>
        <div>
          <Media image={item.image} />
        </div>
      </StyledPeopleHeroCase>
    );
  });
};

const HorizontalPreview = ({ title, list, cta }) => {
  const itemPerColumns = 2;
  return (
    <StyledPeopleHero>
      <StyledPeopleHeroTitle>
        <Display>
          <h1>
            <AnimateSplitFadeIn>{title}</AnimateSplitFadeIn>
          </h1>
        </Display>
      </StyledPeopleHeroTitle>

      <AnimateParallax>
        <StyledPeopleHeroParallax>
          {list.map((item, i) => {
            let arr = [];

            if ((i + 1) % itemPerColumns === 0) {
              arr.push(list[i - 1]);
              arr.push(list[i]);

              return (
                <div key={i}>
                  <AnimateFadeIn>{renderItem(arr)}</AnimateFadeIn>
                </div>
              );
            } else {
              arr = [];
            }
          })}
        </StyledPeopleHeroParallax>
      </AnimateParallax>

      {cta && (
        <StyledPeopleHeroCta>
          <AnimateFadeIn>
            <WrapperLink data={cta}>
              <Cta>{cta.label}</Cta>
            </WrapperLink>
          </AnimateFadeIn>
        </StyledPeopleHeroCta>
      )}
    </StyledPeopleHero>
  );
};

export default HorizontalPreview;
