import React, { useContext } from "react";
import { StyledReel, StyledClose } from "./styles.js";
import { ReelContext } from "../../contexts/Reel.js";

const Reel = ({ reelData }) => {
  const { reelType, reelChangeHandler } = useContext(ReelContext);

  return (
    <StyledReel data-type={reelType}>
      <StyledClose
        onClick={() => {
          reelChangeHandler("closed");
        }}
      >
        <svg viewBox="0 0 20 20">
          <rect
            x="4.46191"
            y="4.45801"
            width="14.7778"
            height="0.527778"
            transform="rotate(45 4.46191 4.45801)"
            fill="white"
          />
          <rect
            x="4.08887"
            y="15.0137"
            width="14.7778"
            height="0.527778"
            transform="rotate(-45 4.08887 15.0137)"
            fill="white"
          />
        </svg>
      </StyledClose>

      <div data-video>
        <div>
          {reelType == "active" ? (
            <video
              poster={`/_next/image?url=${encodeURIComponent(
                reelData.poster.url
              )}&w=1200&q=75`}
              autoPlay={true}
              controls={true}
              loop={true}
              muted={false}
              playsInline={true}
            >
              <source
                type="video/mp4"
                src="https://player.vimeo.com/progressive_redirect/playback/794055194/rendition/1080p/file.mp4?loc=external&signature=9628645f98e0c3154cb33b44f5ebfdbe4e6291b6ac287477ee14d140f4ec0f57"
              />
            </video>
          ) : null}
        </div>
      </div>
    </StyledReel>
  );
};

export default Reel;
