import styled, { css } from "styled-components";

export const StyledVideoPlayer = styled.div`
  padding: 0 calc(100vw / 12 * 1);
  --plyr-video-control-background-hover: ${({ theme }) => theme.colors.accent};
  --plyr-color-main: ${({ theme }) => theme.colors.accent};

  .plyr__poster {
    background-size: cover;
  }

  > div {
    position: relative;
    aspect-ratio: 16/9;

    iframe {
      background-color: #000;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;
