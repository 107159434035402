const BannerDynamo = ({
  title,
  cta
}) => {
  const normalizedData = {
    title,
    cta
  }

  return normalizedData
}

export default BannerDynamo
