import styled, { css } from "styled-components";

export const StyledPeopleCard = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 140%;
  height: 0;

  img {
    object-fit: cover;
  }

  &[data-animoji] {
    padding-bottom: 0;
    height: 100%;
    background-color: white;
  }
`;

export const StyledPeopleCardRatio = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &[data-animoji] {
    position: relative;
  }
`;

export const StyledPeopleCardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2.4rem 3.2rem;
  z-index: 9;
  height: auto;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledPeopleCardContentAnimoji = styled.div`
  padding: 4rem;
  z-index: 9;
  text-align: center;
`;

export const StyledPeopleCardAnimoji = styled.div`
  position: relative;
  overflow: hidden;

  > div {
    position: relative;
    width: 100%;
    padding-bottom: 70%;
    height: 0;
  }

  video {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledPeopleCardAnimojiText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;

  [data-richtext] {
    color: #737373;
  }
`;
