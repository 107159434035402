import styled, { css } from "styled-components";

export const StyledClientCarouselWrapper = styled.div`
  position: relative;
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
`;

export const StyledClientCarouselSlide = styled.div``;

export const StyledClientCarouselContent = styled.div`
  position: relative;
  text-align: center;

  [data-wrapper-link] {
    @media only screen and (max-width: 600px) {
      max-width: 26rem;
      margin: 0 auto;
    }
  }

  [data-editorial-wrapper] {
    ${({ theme }) =>
      theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*1);
        padding-right: calc(100vw/12*1);
      `)}
  }
`;

export const StyledClientCarouselRichtext = styled.div``;

export const StyledClientCarousel = styled.div``;

export const StyledLogoCarousel = styled.div`
  margin-top: ${({ theme }) => theme.col(0.5)};
  margin-bottom: ${({ theme }) => theme.col(0.5)};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  width: calc(100vw + 100vw / 12 * 4);
  left: calc(-1 / 12 * 100vw);

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    width: calc(100vw + 100vw / 12 * 2);
    left: calc(-2 / 12 * 100vw);
  `)}

  img {
    object-fit: contain;
  }

  > div {
    width: calc(100% / 4);
    padding: 0 0.7rem;
    position: relative;

    &:nth-child(5),
    &:nth-child(8) {
      [data-logo] {
        display: none;
      }
    }
    &:nth-child(n + 13) {
      display: none;
    }

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      &:nth-child(1),&:nth-child(7) {
        [data-logo] {
          display: none;
        }
      }

      &:nth-child(5),&:nth-child(8) {
        [data-logo] {
          display: block;
        }
      }

      &:nth-child(n+13 ){
        display: block;
      }
    `)}

    &:nth-child(n + 5) {
      transform: translate(-50%, -10%);
    }

    &:nth-child(n + 9) {
      transform: translate(0%, -20%);
    }

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      padding: 0 1.4rem;
      width: calc(100% / 7);

      &:nth-child(n + 5) {
        transform: none;
      }

      &:nth-child(n + 8) {
        transform: translate(-50%, -10%);
      }

      &:nth-child(n + 9) {
        transform: translate(-50%, -10%);
      }

      &:nth-child(n + 10) {
        transform: translate(-50%, -10%) !important;
      }
  
      &:nth-child(n + 14) {
        transform: translate(0%, -10%);
      }
    `)}

    [data-logo] {
      width: 50%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      > div {
        height: 100%;
        width: 100%;
        position: relative;
      }
    }

    svg {
      path {
        fill: #232323;
      }
    }
  }
`;
