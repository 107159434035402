import styled from "styled-components";

export const TitleSmall = styled.div`
  ${({ theme }) => theme.typo("titleSmall")};
`;

export const Title = styled.div`
  ${({ theme }) => theme.typo("title")};
`;

export const Headline = styled.div`
  ${({ theme }) => theme.typo("headline")};
`;

export const Display = styled.div`
  ${({ theme }) => theme.typo("display")};
`;

export const Caption = styled.div`
  ${({ theme }) => theme.typo("caption")};
`;

export const CaptionBig = styled.div`
  ${({ theme }) => theme.typo("captionBig")};
`;

export const SubHeader = styled.div`
  ${({ theme }) => theme.typo("subHeader")};
`;

export const SubHeaderHeavy = styled.div`
  ${({ theme }) => theme.typo("subHeader")};
  font-weight: 600;
`;

export const SubHeaderSmall = styled.div`
  ${({ theme }) => theme.typo("subHeaderSmall")};
`;

export const BodyBig = styled.div`
  ${({ theme }) => theme.typo("bodyBig")};
`;

export const Body = styled.div`
  ${({ theme }) => theme.typo("body")};
`;

export const StyledBigLink = styled.div`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => theme.typo("subHeaderSmall")};
  font-weight: 600;
  color: inherit;
  position: relative;

  .no-touch & {
    &:hover {
      color: ${({ theme }) => theme.color("accent")};
      > div {
        > div {
          opacity: 1;
          transform: translateX(-100%);
        }
        > span {
          & + div {
            opacity: 0;
            transform: translateX(50%);
          }
        }
      }
    }
  }

  > div {
    display: flex;
    position: relative;
    align-items: center;

    > div {
      pointer-events: none;
      opacity: 0;
      width: ${({ theme }) => theme.rem(20)};
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transform: translateX(-150%);
      transition: all ${({ theme }) => theme.ease}
        ${({ theme }) => theme.timing.fast};
      left: 0;
    }

    > span {
      transition: color ${({ theme }) => theme.ease}
        ${({ theme }) => theme.timing.fast};
      & + div {
        opacity: 1;
        position: absolute;
        left: 100%;
        transform: translateX(0%);
      }
    }
  }
`;
export const StyledRegularLink = styled.div`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => theme.typo("bodyBig")};
  font-weight: 600;
  color: inherit;
  position: relative;

  .no-touch & {
    &:hover {
      color: ${({ theme }) => theme.color("accent")};
      > div {
        > div {
          opacity: 1;
          transform: translateX(-150%);
        }
        > span {
          & + div {
            opacity: 0;
            transform: translateX(180%);
          }
        }
      }
    }
  }

  > div {
    display: flex;
    position: relative;
    align-items: center;

    > div {
      pointer-events: none;
      opacity: 0;
      width: ${({ theme }) => theme.rem(20)};
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transform: translateX(-180%);
      transition: all ${({ theme }) => theme.ease}
        ${({ theme }) => theme.timing.fast};
      left: 0;
    }

    > span {
      transition: color ${({ theme }) => theme.ease}
        ${({ theme }) => theme.timing.fast};
      & + div {
        opacity: 1;
        position: absolute;
        right: 0%;
        left: inherit;
        transform: translateX(150%);
      }
    }
  }
`;
