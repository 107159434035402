import { motion } from "framer-motion";
import React from "react";
import Image from "next/image";

import { StyledMedia } from "./styles.js";

const Media = ({
  image,
  video,
  muted = true,
  loop = true,
  autoPlay = true,
}) => {
  return video || image ? (
    <StyledMedia>
      {video && video.mp4 && video.webm ? (
        <motion.video
          poster={`/_next/image?url=${encodeURIComponent(
            image.url
          )}&w=1200&q=75`}
          autoPlay={autoPlay}
          loop={loop}
          muted={muted}
          playsInline={true}
          onViewportEnter={(e) => {
            e.target.childNodes.forEach((el) => {
              el.src = el.dataset.src;
            });

            setTimeout(() => {
              e.target.load();
            });
          }}
          onViewportLeave={(e) => {
            e.target.childNodes.forEach((el) => {
              el.src = false;
            });
          }}
          viewport={{
            amount: "some",
          }}
        >
          <source type="video/mp4" data-src={video.mp4} />
          <source type="video/webm" data-src={video.webm} />
        </motion.video>
      ) : image ? (
        <Image
          layout="fill"
          src={image.url ? image.url : "/preview.png"}
          alt={image.alt}
          placeholder="blur"
          blurDataURL={
            image.url
              ? `/_next/image?url=${encodeURIComponent(image.url)}&w=1200&q=10`
              : "/preview.png"
          }
        />
      ) : null}
    </StyledMedia>
  ) : null;
};

export default Media;
