import React from "react";
import { StyledGrid, StyledGridElement } from "./styles.js";
import AnimateFadeIn from "../AnimateFadeIn";
import { Media } from "../";

const Grid = ({ list }) => {
  return (
    <AnimateFadeIn>
      <StyledGrid data-length={list.length}>
        {list.map((el, i) => {
          return (
            <StyledGridElement
              data-mobile={el.media.image.url !== el.mobile_media.image.url}
              type={el.full_half}
              key={i}
            >
              <div>
                {el.media.image.url === el.mobile_media.image.url ? (
                  <Media {...el.media} />
                ) : (
                  <>
                    <Media {...el.media}/>
                    <Media {...el.mobile_media}/>
                  </>
                )}
              </div>
            </StyledGridElement>
          );
        })}
      </StyledGrid>
    </AnimateFadeIn>
  );
};

export default Grid;
