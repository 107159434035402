const BannerThunderV3 = ({
  title,
  cta,
  label,
  form_id,
  form_action,
  form_url,
  job_id,
  driver,
  portfolio,
  questions,
}) => {
  const type = ["trakstar", "factorial"].includes(driver) ? "typeform" : null

  const typeformHiddenProps = {}

  if(!!type){
    Object.assign(typeformHiddenProps,{
      position: job_id,
      driver,
      action: form_action,
      portfolio: Number(portfolio),
    })

    if(driver == "factorial"){
      Object.assign(typeformHiddenProps,{
        questions
      })
    }
  }

  const normalizedData = {
    title,
    type,
    cta,
    label,
    form_id,
    form_url,
    typeformHiddenProps,
  };

  return normalizedData;
};

export default BannerThunderV3;
