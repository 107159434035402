const Prefooter = ({ text }) => {
    const normalizedData = {
        text: text,
        link: {
            href: '#',
            text: 'Contattaci'
        },
    }

    return normalizedData
}

export default Prefooter