import styled, { css } from "styled-components";

// export const StyledCursor = styled.div`
//   position: fixed;
//   border-radius: 100%;
//   z-index: 999;
//   pointer-events: none;
//   width: 2px;
//   height: 2px;
//   will-change: transform;

//   span {
//     text-transform: uppercase;
//     text-align: center;
//     color: white;
//     ${({theme}) => theme.typo('caption')};
//     font-weight: 600;
//     padding: 0 20%;
//     display: none;
//   }

//   > div {
//     position: absolute;
//     width: 15rem;
//     height: 15rem;
//     left: -7.5rem;
//     top: -7.5rem;
//     background: ${({ theme }) => theme.colors.accent};
//     transform: scale(0.05);
//     transition: all 0.3s ease;
//     border-radius: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   &[data-type="project"] {
//     > div {
//       transform: scale(1);
//     }
//     span {
//       display: block;
//     }
//   }

//   &[data-type="arrow-slider-next"] {
//     > div {
//       transform: scale(1);
//       span {
//         display: none;
//       }
//     }
//     svg {
//       width: 100%;
//     }
//   }
// `;

export const StyledCursor = styled.div`
  position: fixed;
  z-index: 999;
  pointer-events: none;
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 15rem;
  left: -7.5rem;
  top: -7.5rem;
  border-radius: 100%;

  .is-touch & {
    display: none;
  }

  [data-circle] {
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease;
    transform: scale(0.035);
    background: #0082ff70;
  }

  [data-text] {
    text-transform: uppercase;
    text-align: center;
    color: white;
    ${({ theme }) => theme.typo("caption")};
    font-weight: 600;
    padding: 0 20%;
    position: relative;
    z-index: 2;
  }

  [data-icon-arrow] {
    position: absolute;
    opacity: 0;
    transition: all 0.4s ease;
    svg {
      width: 2rem;
      height: 2rem;
      color: white;
    }
  }

  [data-icon-drag] {
    position: absolute;
    opacity: 0;
    transition: all 0.4s ease;
    display: flex;

    > div {
      transition: all 0.2s ease;
      margin: 0 0.5rem;
      &:first-child {
        transform: scale(-1, 1);
      }
    }

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      color: white;
    }
  }

  &[data-type="arrow-slide-prev"] {
    [data-icon-arrow] {
      opacity: 1;
      transform: scale(-1, -1);
    }
    [data-circle] {
      transform: scale(0.5);
      background: ${({ theme }) => theme.colors.accent};
    }
  }

  &[data-type="arrow-slide-next"] {
    [data-icon-arrow] {
      opacity: 1;
    }
    [data-circle] {
      transform: scale(0.5);
      background: ${({ theme }) => theme.colors.accent};
    }
  }

  &[data-type="hovered"] {
    [data-circle] {
      transform: scale(1);
    }
  }
  &[data-type="drag"] {
    [data-circle] {
      transform: scale(0.6);
    }
    [data-icon-drag] {
      opacity: 1;
    }
  }
  &[data-type="hide"] {
    [data-circle] {
      transform: scale(0);
    }
  }
`;
