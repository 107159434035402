import React, { useContext } from "react";
import {
  StyledEditorialCards,
  StyledEditorialCardsContent,
  StyledEditorialCardsSlider,
} from "./styles.js";
import {
  Richtext,
  PeopleCard,
  Icon,
  AnimateFadeIn,
  EditorialWrapper,
  WrapperLink,
} from "../";
import { StyledRegularLink, Headline } from "../Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCreative } from "swiper";

import { MouseContext } from "../../contexts/Mouse";

const EditorialCards = ({ title, text, cta, cards, animoji }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  return (
    <StyledEditorialCards>
      <StyledEditorialCardsContent>
        <EditorialWrapper>
          {title && (
            <Headline data-title>
              <AnimateFadeIn>{title}</AnimateFadeIn>
            </Headline>
          )}

          {text && (
            <div data-richtext>
              <AnimateFadeIn>
                <Richtext>{text}</Richtext>
              </AnimateFadeIn>
            </div>
          )}

          {cta.label && (
            <div data-cta>
              <AnimateFadeIn>
                <WrapperLink data={cta}>
                  <div>
                    <StyledRegularLink>
                      <div>
                        <Icon name="caret-right" />
                        <span>{cta.label}</span>
                        <Icon name="caret-right" />
                      </div>
                    </StyledRegularLink>
                  </div>
                </WrapperLink>
              </AnimateFadeIn>
            </div>
          )}
        </EditorialWrapper>
      </StyledEditorialCardsContent>

      <StyledEditorialCardsSlider
        onMouseEnter={() => {
          cursorChangeHandler("drag");
          cursorChangeText("");
        }}
        onMouseLeave={() => {
          cursorChangeHandler("");
          cursorChangeText("");
        }}
      >
        <Swiper
          modules={[EffectCreative]}
          slidesPerView={"auto"}
          effect={"creative"}
          slideToClickedSlide={true}
          creativeEffect={{
            progressMultiplier: 1,
            limitProgress: 2,
            next: {
              translate: ["105%", 0, 0],
              opacity: 0.3,
            },
            prev: {
              scale: 0.8,
              opacity: 0,
            },
          }}
        >
          {cards.map((card, i) => {
            card.animoji = animoji;

            return (
              <SwiperSlide key={i}>
                <PeopleCard {...card} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StyledEditorialCardsSlider>
    </StyledEditorialCards>
  );
};

export default EditorialCards;
