const EditorialMedia = ({ title, text, image, alignment, cta }) => {
  const normalizedData = {
    title,
    text,
    image,
    alignment,
    cta,
  };

  return normalizedData;
};

export default EditorialMedia;
