import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledForm = styled.div`
  margin-top: calc(100vw / 12 * 2);
  margin-bottom: calc(100vw / 12 * 2);
  position: relative;
  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    right: calc(100vw / 12 * 2);
    width: calc(100vw / 12 * 3.5);
    translate: 0 -50%;
  `)}

  &[data-type="pipedrive"] {
    position: relative;
    
    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      float: right;
      right: 0;
      translate: 0 0;
    `)}

    iframe {
      width: 100%;
      height: 1230px;
      border: 1px solid #fff;
      border-radius: 8px;
    }
  }

  &[data-type="typeform"] {
    position: relative;
    min-height: 60rem;
    
    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      float: right;
      right: 5vw;
      translate: 0 0;
      top: -5vw;
    `)}

    iframe {
      width: 100%;
      height: 100%;
      border: 1px solid #fff;
      border-radius: 8px;
    }
  }

  > div {
    /* height: 60rem; */
    position: relative;

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      height: auto;
      aspect-ratio: 430/600;
    `)}
  }

  .my-form {
    height: 100% !important;
    width: 100% !important;
    min-height: 60rem;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const StyledHeroContacts = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: calc(100vw / 12 * 3.5) calc(100vw / 12 * 1) calc(100vw / 12 * 1)
    calc(100vw / 12 * 1);
  margin-bottom: ${({ theme }) => theme.col(-1)};
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    padding: calc(100vw / 12 * 1.5) calc(100vw / 12 * 1) calc(100vw / 12 * 1)
    calc(100vw / 12 * 1);
  `)}

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
    padding: calc(100vw / 12 * 1.5) calc(100vw / 12 * 2) calc(100vw / 12 * 1)
    calc(100vw / 12 * 2);
  `)}
`;

export const StyledContent = styled.div`
  z-index: 9;
  width: 100%;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    width: 100%;
  `)}
`;

export const StyledOffices = styled.div`
  margin-top: ${({ theme }) => theme.col(2)};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) =>
      theme.mediaQuery.tablet(`
      width: 50%;
    `)}
  ul {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: flex;
      flex-wrap: wrap;
    `)}
    li {
      white-space: nowrap;
      margin-bottom: 3.2rem;
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
        width: 50%;
      `)}
    }
  }
`;

export const StyledTitle = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    float: left;
    width: 30%;
  `)}
`;

export const StyledHeroLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 66.6vw;
  z-index: 0;
  pointer-events: none;
  z-index: 0;
  scale: -1 -1;

  path {
    will-change: opacity;
    animation: 2s ${pulse} ease-in-out infinite alternate;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }

  path {
    opacity: 0;
    will-change: opacity;

    &:nth-child(1) {
      animation-delay: 0.3s;
    }
    &:nth-child(2) {
      animation-delay: 0.6s;
    }
    &:nth-child(3) {
      animation-delay: 0.9s;
    }
    &:nth-child(4) {
      animation-delay: 1.2s;
    }
    &:nth-child(5) {
      animation-delay: 1.5s;
    }
    &:nth-child(6) {
      animation-delay: 1.8s;
    }
    &:nth-child(7) {
      animation-delay: 2.1s;
    }
    &:nth-child(8) {
      animation-delay: 2.4s;
    }
    &:nth-child(9) {
      animation-delay: 2.7s;
    }
  }
`;
