import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledStandardModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: stretch;
  justify-content: center;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: #131212;

  ${({ type }) =>
    type === "default" ||
    ("background" &&
      css`
        align-items: center;
      `)}
`;

export const StyledStandardModalContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  align-items: center;

  ${({ theme, type }) =>
    type === "default" &&
    css`
      display: block;
      min-width: calc(100vw - 48px);
      background-color: ${theme.colors.white};
      padding: ${theme.rem(24)};
      padding-bottom: ${theme.rem(40)};
      position: relative;
      overflow: auto;
      border-radius: 4px 4px 40px 4px;
      max-height: 100vh;
    `}

  ${({ theme, type }) =>
    type === "background" &&
    css`
      display: block;
      min-width: min-content;
      background-color: ${theme.colors.gray1};
      padding: ${theme.rem(24)};
      padding-bottom: ${theme.rem(40)};
      position: relative;
      overflow: auto;
      border-radius: 4px 4px 40px 4px;
      max-height: 100vh;
    `}

  [data-wrapper-iframe] {
    max-width: 60rem;
    border: 1px solid white;
    overflow: hidden;
    width: 100vw;
    height: 60vh;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      border-radius: 1.5rem;
      height: 80vh;
    `)};
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledStandardModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.rem(40)};

  h4 {
    ${({ theme }) => theme.typo("titleSmall")};
    font-weight: 300;
    span {
      color: ${({ theme }) => theme.color("primary")};
    }
  }

  button {
    margin-left: ${({ theme }) => theme.rem(24)};
    height: 24px;
  }
`;

export const StyledStandardModalClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.rem(24)};
  position: sticky;
  cursor: pointer;
  background: none;
  border: 0;

  padding: 0;
  position: absolute;
  top: ${({ theme }) => theme.rem(30)};
  right: ${({ theme }) => theme.rem(10)};
`;

export const StyledHeroLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  pointer-events: none;
  z-index: 0;

  path {
    will-change: opacity;
    animation: 2s ${pulse} ease-in-out infinite alternate;
  }

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    translate: -50% 0;
    scale: 2.5;
  }

  path {
    opacity: 0;
    will-change: opacity;

    &:nth-child(1) {
      animation-delay: 0.3s;
    }
    &:nth-child(2) {
      animation-delay: 0.6s;
    }
    &:nth-child(3) {
      animation-delay: 0.9s;
    }
    &:nth-child(4) {
      animation-delay: 1.2s;
    }
    &:nth-child(5) {
      animation-delay: 1.5s;
    }
    &:nth-child(6) {
      animation-delay: 1.8s;
    }
    &:nth-child(7) {
      animation-delay: 2.1s;
    }
    &:nth-child(8) {
      animation-delay: 2.4s;
    }
    &:nth-child(9) {
      animation-delay: 2.7s;
    }
  }
`;
