const LauncherBoxes = ({ title, slider, text, list, cta }) => {
  const normalizedData = {
    title: title,
    slider: slider,
    text: text,
    list: list,
    cta: cta,
  };

  return normalizedData;
};

export default LauncherBoxes;
