const BannerThunder = ({
  title,
  is_typeform,
  cta,
  label,
  form_id,
  trakstar_job_id,
}) => {
  const normalizedData = {
    title,
    is_typeform,
    cta,
    label,
    form_id,
    trakstar_job_id,
  };

  return normalizedData;
};

export default BannerThunder;
