import styled, { css } from "styled-components";

export const StyledPrefooter = styled.div`
  text-align: center;
  padding-top: ${({ theme }) => theme.col(3)};
  padding-bottom: ${({ theme }) => theme.col(3)};
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        padding-top: 0;
        padding-bottom: 0;
        padding-left: ${({ theme }) => theme.col(1)};
        padding-right: ${({ theme }) => theme.col(1)};
    `)}

  &:after {
    content: "";
    display: block;
    left: 0;
    bottom: ${({ theme }) => theme.col(-1)};
    position: absolute;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0deg, #131212, transparent);
    height: 200%;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
            height: ${({ theme }) => theme.col(5)};
        `)}
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        padding-left: ${theme.col(3)};
        padding-right: ${theme.col(3)};
    `)}
`;

export const StyledPrefooterTitle = styled.h2`
  ${({ theme }) => theme.typo("title")};
  margin-bottom: ${({ theme }) => theme.rem(32)};
  z-index: 1;
  position: relative;
`;

export const StyledPrefooterLink = styled.div`
  z-index: 1;
  position: relative;
`;

export const StyledPrefooterLogo = styled.div`
  display: block;
  position: absolute;
  width: 70%;
  left: 50%;
  transform: translate(-50%, calc(-0.5 / 12 * 100vw));
  top: 0;
  opacity: 0.7;
  z-index: 0;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        width: ${theme.col(2.5)};
    `)}
`;
