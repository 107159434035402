import styled from "styled-components";

export const StyledFooter = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typo("body")};
  padding: ${({ theme }) => theme.rem(32)} ${({ theme }) => theme.rem(24)};

  &:after {
    content: "";
    background-color: ${({ theme }) => theme.colors.black};
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    top: -1px;
    left: 0;
  }

  a {
    transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    .no-touch & {
      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
        padding: ${theme.col(1)};
    `)}
`;

export const StyledFooterLogoCaffeina = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(40)};
  width: 50%;
  padding-right: calc(100vw / 12 * 2);

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
          margin: 0;
          width: ${theme.col(3)};
          padding-right: ${theme.col(1)};
      `)};
`;

export const StyledFooterWrapper = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.desktop(`
        display: flex;
    `)}

  > div {
    &:first-of-type {
      display: flex;
    }
    &:last-of-type {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }
`;

export const StyledFooterOffices = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: ${({ theme }) => theme.rem(40)} 0;
  border-top: 1px solid #373737;
  border-bottom: 1px solid #373737;

  > div {
    width: 50%;
    margin-bottom: ${({ theme }) => theme.rem(24)};

    &:last-of-type {
      margin: 0;
    }

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
            width: auto;
            margin-bottom: 0;
            padding-right: ${theme.col(0.5)};
            min-width: ${theme.rem(250)};
        `)};
    p {
      color: #cdcdcd;
      font-weight: lighter;
    }
  }

  strong {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.rem(8)};
    display: block;
  }
`;

export const StyledFooterSocials = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.rem(40)} 0;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    justify-content: space-between;
  `)};

  li {
    width: 33.3%;
    margin-bottom: 2rem;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      width: auto;
      margin-right: 0;
    `)};
  }

  a {
    font-weight: 600;
  }
`;

export const StyledFooterNavs = styled.div`
  &[data-nav-mobile] {
    display: block;
    width: 50%;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: none;
    `)}
  }

  &[data-nav-desktop] {
    display: none;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: block;
    `)}
  }
  nav {
    ${({ theme }) => theme.mediaQuery.desktop(`display: flex;`)};
  }

  ul {
    margin-bottom: ${({ theme }) => theme.rem(16)};

    &:last-of-type {
      margin-bottom: 0;
    }

    ${({ theme }) => theme.mediaQuery.desktop(`margin-bottom: 0;`)};

    li {
      margin-bottom: ${({ theme }) => theme.rem(16)};
      ${({ theme }) => theme.mediaQuery.tablet(`min-width: 25rem`)};

      p {
        color: #cdcdcd;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:first-of-type {
      text-transform: uppercase;
    }
    &:last-of-type {
      color: #afafaf;
      font-weight: 600;
    }
  }

  a {
    font-weight: 600;
  }
`;

export const StyledFooterData = styled.div`
  padding: ${({ theme }) => theme.rem(40)} 0;

  ${({ theme }) =>
    theme.mediaQuery.wide(`
    display: flex;
  `)}

  > div {
    &:first-of-type {
      ${({ theme }) =>
        theme.mediaQuery.wide(`
          width: 50%;
      `)}
    }
  }
`;

export const StyledFooterLogos = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 1rem;
  ${({ theme }) =>
    theme.mediaQuery.wide(`
      margin-top: 0;
      justify-content: flex-end;
      width: 50%;
  `)}

  div {
    position: relative;
    width: auto;
    height: 4.3rem;
    ${({ theme }) =>
      theme.mediaQuery.wide(`
      height: 6rem;
      padding-left: 1.6rem;
  `)}
  }

  img {
    height: 100%;
    width: auto;
    display: block;
    object-fit: contain;
  }
`;

export const StyledFooterEnd = styled.div`
  ${({ theme }) => theme.typo("caption")};
  color: #afafaf;
  font-weight: lighter;

  div {
    text-transform: uppercase;

    p {
      color: #cdcdcd;
    }
  }

  nav {
    a {
      text-transform: uppercase;
      white-space: nowrap;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
      flex-direction: row;
      `)}
    }

    li {
      margin-top: ${({ theme }) => theme.rem(16)};

      ${({ theme }) =>
        theme.mediaQuery.desktop(`
                margin-top: 0;
            `)}
    }
  }
`;
