import React from "react";
import { TitleSmall, Headline } from "../Typography";
import { Media, AnimateFadeIn } from "../";
import {
  StyledHeroProject,
  StyledHeroProjectMedia,
  StyledHeroProjectContent,
} from "./styles.js";

const HeroProject = ({ media, title, customer }) => {
  return (
    <StyledHeroProject>
      {media && (
        <StyledHeroProjectMedia>
          <div data-speed="0.5">
            <Media {...media} />
          </div>
        </StyledHeroProjectMedia>
      )}

      <StyledHeroProjectContent>
        {customer && (
          <TitleSmall>
            <AnimateFadeIn>{customer}</AnimateFadeIn>
          </TitleSmall>
        )}
        {title && (
          <Headline>
            <AnimateFadeIn delay=".1">
              <h1>{title}</h1>
            </AnimateFadeIn>
          </Headline>
        )}
      </StyledHeroProjectContent>
    </StyledHeroProject>
  );
};

export default HeroProject;
