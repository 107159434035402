// https://stackoverflow.com/questions/37612823/apply-filter-on-svg-clip-path

import { useRouter } from "next/router";
import React, { useState, useEffect, useContext } from "react";
import { ListItem, Card, WrapperLink, Cta, Icon, AnimateFadeIn } from "../";
import { TitleSmall, Body } from "../Typography";

import {
  StyledListProjects,
  StyledListProjectsCard,
  StyledListProjectsNormalList,
  StyledListProjectsFeaturedList,
  StyledListProjectsHero,
  StyledListProjectsHeroTitle,
  StyledFilter,
  StyledAllWrapperList,
  StyledReelTrigger,
  StyledIndustryFilters,
  StyledHole,
} from "./styles.js";

import { MouseContext } from "../../contexts/Mouse";
import { ReelContext } from "../../contexts/Reel";

const ListProjects = ({
  title,
  load_more,
  reel,
  list,
  placeholder,
  unit_filters,
  industry_filters,
}) => {
  const { reelChangeHandler } = useContext(ReelContext);
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  const [industryOpen, setIndustryOpen] = useState(false);
  const [mainSelectStatus, setMainSelectStatus] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [industryFilter, setIndustryFilter] = useState("");
  const [filteredCases, setFilteredCases] = useState(false);

  const { locale } = useRouter();

  const setupCases = (data) => {
    const featuredCases = [];
    const normalCases = [];

    data.forEach((element) => {
      if (element.highlighted) {
        featuredCases.push(element);
      } else {
        normalCases.push(element);
      }
    });
    setFilteredCases({ featuredCases, normalCases });
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  };

  const filterCases = (data, activeFilter) => {
    const filteredData = data.filter((item) => {
      const z = item.unit.filter((unit) => {
        return unit.slug == activeFilter;
      });
      return z.length > 0;
    });
    setupCases(filteredData);
  };

  const filterIndustryCases = (data, industryFilter) => {
    const filteredData = data.filter((item) => {
      const z = item.industry.filter((industry) => {
        return industry.slug == industryFilter;
      });
      return z.length > 0;
    });
    setupCases(filteredData);
  };

  useEffect(() => {
    filterCases(list, "");
    setupCases(list);
  }, []);

  useEffect(() => {
    if (activeFilter) filterCases(list, activeFilter);
    else {
      setupCases(list);
    }
  }, [activeFilter]);

  useEffect(() => {
    if (industryFilter) filterIndustryCases(list, industryFilter);
    else {
      setupCases(list);
    }
  }, [industryFilter]);

  useEffect(() => {
    const onScroll = () => {
      if (mainSelectStatus === true) {
        setMainSelectStatus(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [mainSelectStatus]);

  return (
    <StyledListProjects>
      <StyledListProjectsHero>
        <StyledListProjectsHeroTitle>
          <h1>
            {title} <span>Caffeina</span>{" "}
          </h1>
          <StyledFilter>
            <button
              data-status={mainSelectStatus}
              onClick={() => {
                setMainSelectStatus(!mainSelectStatus);
                setIndustryOpen(false);
                setIndustryFilter("");
              }}
            >
              <span>
                {activeFilter === ""
                  ? "Caffeina"
                  : activeFilter
                      .replace("-", " ")
                      .replace("-en", "")
                      .replace(" en", "")}
              </span>
              <svg width="34" height="20" viewBox="0 0 34 20" fill="none">
                <path
                  d="M2 2L17 18L32 2"
                  stroke="currentColor"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {unit_filters && (
              <ul data-status={mainSelectStatus}>
                <li>
                  <span
                    onClick={() => {
                      setActiveFilter("");
                      setMainSelectStatus(!mainSelectStatus);
                    }}
                    onMouseOver={() => {
                      cursorChangeHandler("hide");
                      cursorChangeText("");
                    }}
                    onMouseLeave={() => {
                      cursorChangeHandler("");
                      cursorChangeText("");
                    }}
                  >
                    Caffeina
                  </span>
                </li>
                {unit_filters.map((item, i) => {
                  return (
                    <li
                      key={i}
                      data-active={item.slug === activeFilter ? "active" : null}
                    >
                      <span
                        onClick={() => {
                          setActiveFilter(item.slug);
                          setMainSelectStatus(!mainSelectStatus);
                        }}
                        onMouseOver={() => {
                          cursorChangeHandler("hide");
                          cursorChangeText("");
                        }}
                        onMouseLeave={() => {
                          cursorChangeHandler("");
                          cursorChangeText("");
                        }}
                      >
                        {item.name}
                      </span>
                    </li>
                  );
                })}
                <div
                  onClick={() => {
                    setMainSelectStatus(!mainSelectStatus);
                  }}
                />
              </ul>
            )}
          </StyledFilter>

          <StyledIndustryFilters
            data-active-filter={activeFilter ? true : false}
          >
            <div data-trigger-industry-wrapper>
              <div
                data-is-open={industryOpen}
                data-trigger-industry
                onClick={() => {
                  setIndustryOpen(!industryOpen);
                  if (industryOpen) setIndustryFilter(null);
                }}
              >
                <Body>
                  {locale == "en"
                    ? "Browse by industry"
                    : "Naviga per industry"}
                </Body>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
                  <path
                    d="M1 1L5 5L9 1"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {industryFilter ? (
                <div
                  data-tag
                  onClick={() => {
                    setIndustryOpen(false);
                    setIndustryFilter(null);
                  }}
                >
                  <Body>
                    {industryFilter.replace(/-en$/i, "")}
                  </Body>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path
                      d="M9.16601 4.58366H5.41602V0.833659C5.41602 0.583659 5.24935 0.416992 4.99935 0.416992C4.74935 0.416992 4.58268 0.583659 4.58268 0.833659V4.58366H0.832682C0.582682 4.58366 0.416016 4.75033 0.416016 5.00033C0.416016 5.25033 0.582682 5.41699 0.832682 5.41699H4.58268V9.16699C4.58268 9.41699 4.74935 9.58366 4.99935 9.58366C5.24935 9.58366 5.41602 9.41699 5.41602 9.16699V5.41699H9.16601C9.41601 5.41699 9.58268 5.25033 9.58268 5.00033C9.58268 4.75033 9.41601 4.58366 9.16601 4.58366Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              ) : null}
            </div>

            <ul data-is-open={industryOpen}>
              {industry_filters.map((item, i) => {
                return (
                  <li key={i}>
                    <span
                      data-active={item.slug == industryFilter ? true : false}
                      onClick={() => {
                        setIndustryOpen(false);
                        setIndustryFilter(item.slug);
                        window.scrollTo({
                          top: window.innerHeight / 2,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <TitleSmall>{item.name}</TitleSmall>
                    </span>
                  </li>
                );
              })}
            </ul>
          </StyledIndustryFilters>
        </StyledListProjectsHeroTitle>

        <StyledReelTrigger
          data-status={mainSelectStatus}
          onMouseOver={() => {
            cursorChangeHandler("hide");
            cursorChangeText("");
          }}
          onClick={() => {
            reelChangeHandler("active");
          }}
          onMouseLeave={() => {
            cursorChangeHandler("");
            cursorChangeText("");
          }}
        >
          <svg width="46" height="51" viewBox="0 0 46 51" fill="none">
            <path
              d="M2 49V2L44 25.5L2 49Z"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div>
            <svg viewBox="0 0 287 312">
              <clipPath id="clip-path">
                <path d="M83.6305 25.8554C112.906 8.98304 127.544 0.546875 143.618 0.546875C159.691 0.546875 174.329 8.98304 203.605 25.8554L226.494 39.047C255.77 55.9193 270.408 64.3555 278.445 78.2508C286.482 92.1462 286.482 109.019 286.482 142.763V169.146C286.482 202.891 286.482 219.763 278.445 233.659C270.408 247.554 255.77 255.99 226.494 272.863L203.605 286.054C174.329 302.927 159.691 311.363 143.618 311.363C127.544 311.363 112.906 302.927 83.6305 286.054L60.7411 272.863C31.4653 255.99 16.8274 247.554 8.79067 233.659C0.753906 219.763 0.753906 202.891 0.753906 169.146V142.763C0.753906 109.018 0.753906 92.1462 8.79067 78.2508C16.8274 64.3555 31.4653 55.9194 60.7411 39.047L83.6305 25.8554Z" />
              </clipPath>

              <g clipPath="url(#clip-path)">
                <image href={reel.poster.url} width="100%" height="100%" />
              </g>
            </svg>
          </div>
        </StyledReelTrigger>
      </StyledListProjectsHero>

      <StyledAllWrapperList data-status={mainSelectStatus}>
        <StyledListProjectsFeaturedList>
          {filteredCases &&
            filteredCases.featuredCases.map((item, i) => {
              return (
                <StyledListProjectsCard
                  key={item.cta.url}
                  onMouseOver={() => {
                    cursorChangeHandler("hovered");
                    cursorChangeText(
                      locale == "en"
                        ? "Explore the project"
                        : "Esplora il progetto"
                    );
                  }}
                  onMouseLeave={() => {
                    cursorChangeHandler("");
                    cursorChangeText("");
                  }}
                  onClick={() => {
                    cursorChangeHandler("");
                    cursorChangeText("");
                  }}
                >
                  <WrapperLink data={item.cta}>
                    <Card
                      title={item.customer}
                      description={item.title}
                      media={item.media}
                    />
                  </WrapperLink>
                </StyledListProjectsCard>
              );
            })}

          {/* Hole */}
          {filteredCases && filteredCases.featuredCases.length % 2 ? (
            <StyledHole>
              <div data-wrapper>
                <TitleSmall>{placeholder.title}</TitleSmall>
                <div data-cta>
                  <WrapperLink data={placeholder.cta}>
                    <Cta>{placeholder.cta.label}</Cta>
                  </WrapperLink>
                </div>
              </div>
              <div data-thunder>
                <AnimateFadeIn>
                  <Icon name="pre-footer-bolt" />
                </AnimateFadeIn>
              </div>
            </StyledHole>
          ) : null}
        </StyledListProjectsFeaturedList>

        {filteredCases && (
          <StyledListProjectsNormalList>
            <ListItem
              list={filteredCases.normalCases.map((el) => {
                return {
                  pretitle: el.customer,
                  position: el.title,
                  cta: el.cta,
                };
              })}
            />
          </StyledListProjectsNormalList>
        )}
      </StyledAllWrapperList>
    </StyledListProjects>
  );
};

export default ListProjects;
