import React from "react";
import { StyledLauncherLinks } from "./styles.js";
import { AnimateFadeIn, Richtext, ListItem } from "../";

const LauncherLinks = ({ text, list }) => {
  const listLinks = [];
  list.map((el, i) => {
    let obj = {
      position: el.title,
      description: el.text,
      cta: el.cta
    };
    listLinks.push(obj);
  });

  return (
    <StyledLauncherLinks>
      <AnimateFadeIn>
        <Richtext>{text}</Richtext>
      </AnimateFadeIn>

      <ListItem list={listLinks} />
    </StyledLauncherLinks>
  );
};

export default LauncherLinks;
