import React from "react";
import { EditorialWrapper, Cta, WrapperLink } from "../";
import { Headline, SubHeaderHeavy, Body, Caption } from "../Typography";
import {
  StyledLauncherText,
  StyledLauncherTextHead,
  StyledLauncherTextArticles,
} from "./styles.js";
const LauncherText = ({ title, cta, list }) => {
  return (
    <StyledLauncherText>
      <StyledLauncherTextHead>
        <Headline>{title}</Headline>
        {cta && (
          <div>
            <WrapperLink data={cta}>
              <Cta>{cta.label}</Cta>
            </WrapperLink>
          </div>
        )}
      </StyledLauncherTextHead>
      <StyledLauncherTextArticles>
        {list.map((el, i) => {
          return (
            <EditorialWrapper key={i}>
              <Caption data-eyelet>{el.eyelet.toUpperCase()}</Caption>
              <SubHeaderHeavy data-title>{el.title}</SubHeaderHeavy>
              <Body data-richtext>{el.text}</Body>
              {el.cta && (
                <div data-cta>
                  <WrapperLink data={el.cta}>
                    <Cta>{el.cta.label}</Cta>
                  </WrapperLink>
                </div>
              )}
            </EditorialWrapper>
          );
        })}
      </StyledLauncherTextArticles>
    </StyledLauncherText>
  );
};

export default LauncherText;
