import styled, { css } from "styled-components";

export const StyledLauncherText = styled.div`
  padding: 0 calc(100vw / 12 * 1);
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}
`;

export const StyledLauncherTextHead = styled.div`
  > div {
    &:last-of-type {
      margin-top: 3.2rem;
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
        margin-top: 0;
      `)};
    }
  }
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `)};
  & + * {
    margin-top: calc(100vw / 12 * 0.5);
  }
`;

export const StyledLauncherTextArticles = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    display: flex;
    justify-content: space-between;
  `)};
  > div {
    border-top: 1px solid #cdcdcd;
    padding-top: 4rem;
    margin-top: 4rem;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      margin-top:0;
      width: 45%;
    `)};
  }
`;
