import { MouseContext } from "../../contexts/Mouse";
import React, { useState, useContext } from "react";
import {
  StyledHeroServices,
  StyledHeroServicesTitle,
  StyledWrapperBox,
  StyledBox,
  StyledHeroCta,
} from "./styles.js";
import { Cta, AnimateFadeIn, AnimateSplitFadeIn, Richtext } from "../";
import { Display } from "../Typography/index.js";

const HeroServices = ({ title, list, scrollto }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  const [currentAccordion, setCurrentAccordion] = useState(null);

  return (
    <StyledHeroServices>
      <StyledHeroServicesTitle>
        <Display>
          <h1>
            <AnimateSplitFadeIn>{title}</AnimateSplitFadeIn>
          </h1>
        </Display>

        <StyledHeroCta
          onClick={() => {
            if (window.scroller) {
              window.scroller.scrollTo(window.innerHeight, true);
            } else {
              window.scrollTo({
                top: window.innerHeight,
                left: 0,
                behavior: "smooth",
              });
            }
          }}
        >
          <AnimateFadeIn delay={1}>
            <Cta>{scrollto.label}</Cta>
          </AnimateFadeIn>
        </StyledHeroCta>
      </StyledHeroServicesTitle>

      <StyledWrapperBox>
        {list.map((item, i) => {
          return (
            <StyledBox
              key={i}
              data-status={currentAccordion === i ? "active" : null}
            >
              <Display
                onMouseOver={() => {
                  cursorChangeHandler("hide");
                  cursorChangeText("");
                }}
                onMouseLeave={() => {
                  cursorChangeHandler("");
                  cursorChangeText("");
                }}
                data-title
                onClick={() => {
                  if (i === currentAccordion) {
                    setCurrentAccordion(null);
                  } else {
                    setCurrentAccordion(i);
                  }
                }}
              >
                {item.title}
              </Display>
              <div data-content>
                <Richtext>{item.text}</Richtext>
              </div>
            </StyledBox>
          );
        })}
      </StyledWrapperBox>
    </StyledHeroServices>
  );
};

export default HeroServices;
