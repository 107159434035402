import React, { useContext } from "react";
import { MouseContext } from "../../contexts/Mouse";
import {
  StyledLauncherBoxes,
  StyledLauncherBoxesWrapperText,
  StyledLauncherBoxesWrapperBoxes,
} from "./styles.js";
import { Headline } from "../Typography/index.js";
import Richtext from "../Richtext/index.js";
import { Card, Cta, WrapperLink, AnimateFadeIn, EditorialWrapper } from "../";

import { Swiper, SwiperSlide } from "swiper/react";

const LauncherBoxes = ({ title, slider, text, list, cta }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  const renderSlider = (boxes) => {
    return (
      <StyledLauncherBoxesWrapperBoxes
        onMouseOver={() => {
          cursorChangeHandler("drag");
          cursorChangeText("");
        }}
        onMouseLeave={() => {
          cursorChangeHandler("");
          cursorChangeText("");
        }}
      >
        <Swiper
          slidesPerView={"auto"}
          enabled={false}
          breakpoints={{
            768: {
              enabled: true,
              slidesPerView: "auto",
            },
          }}
        >
          {boxes.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <WrapperLink data={item.cta}>
                  <Card
                    title={item.title}
                    description={item.description}
                    media={{
                      image: item.image,
                    }}
                  />
                </WrapperLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StyledLauncherBoxesWrapperBoxes>
    );
  };

  const renderGrid = (list) => {
    return (
      <StyledLauncherBoxesWrapperBoxes>
        {list &&
          list.map((item, i) => {
            return (
              <WrapperLink key={i} data={item.cta}>
                <Card
                  title={item.title}
                  description={item.description}
                  media={{
                    image: item.image,
                  }}
                />
              </WrapperLink>
            );
          })}
      </StyledLauncherBoxesWrapperBoxes>
    );
  };

  return (
    <StyledLauncherBoxes data-launcher-boxes>
      {title || text || cta.label ? (
        <StyledLauncherBoxesWrapperText>
          {title && (
            <Headline data-title>
              <AnimateFadeIn>{title}</AnimateFadeIn>
            </Headline>
          )}
          {text && (
            <div data-richtext>
              <AnimateFadeIn>
                <Richtext>{text}</Richtext>
              </AnimateFadeIn>
            </div>
          )}

          {cta.label && (
            <div data-cta>
              <AnimateFadeIn>
                <WrapperLink data={cta}>
                  <Cta>{cta.label}</Cta>
                </WrapperLink>
              </AnimateFadeIn>
            </div>
          )}
        </StyledLauncherBoxesWrapperText>
      ) : null}

      {slider ? renderSlider(list) : renderGrid(list)}
    </StyledLauncherBoxes>
  );
};

export default LauncherBoxes;
