const EditorialAccordion = ({ title, text, meta, load_more }) => {
  const normalizedData = {
    title,
    text,
    meta,
    load_more
  };

  return normalizedData;
};

export default EditorialAccordion;
