const CaseCarousel = ({ list, title, cta }) => {
  const normalizedData = {
    title: title,
    list: list,
    cta: cta,
  };

  return normalizedData;
};

export default CaseCarousel;
