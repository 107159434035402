import React, { createContext, useState } from "react";

export const MouseContext = createContext({
  cursorType: "",
  cursorText: "",
  cursorChangeHandler: () => {},
  cursorChangeText: () => {},
});

const MouseProvider = (props) => {
  const [cursorType, setCursorType] = useState("");
  const [cursorText, setCursorText] = useState("");

  const cursorChangeHandler = (cursorType) => {
    setCursorType(cursorType);
  };

  const cursorChangeText = (cursorText) => {
    setCursorText(cursorText);
  };

  return (
    <MouseContext.Provider
      value={{
        cursorText: cursorText,
        cursorType: cursorType,
        cursorChangeHandler: cursorChangeHandler,
        cursorChangeText: cursorChangeText,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  );
};

export default MouseProvider;
