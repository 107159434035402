import Link from "next/link";
import { useRouter } from "next/router.js";

import {
  StyledFooter,
  StyledFooterWrapper,
  StyledFooterOffices,
  StyledFooterSocials,
  StyledFooterNavs,
  StyledFooterLogos,
  StyledFooterData,
  StyledFooterEnd,
  StyledFooterLogoCaffeina,
} from "./styles.js";

import { Icon, WrapperLink } from "../";

const Footer = ({ footerData }) => {
  const router = useRouter();
  const currentYr = new Date().getFullYear();

  const innerLinks = [];
  const outerLinks = [];

  if (footerData.links) {
    footerData.links.map((el, i) => {
      if (el.internal) {
        innerLinks.push(el);
      } else {
        outerLinks.push(el);
      }
    });
  }

  return (
    <StyledFooter
      initial={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        duration: 1.5,
        ease: "anticipate",
      }}
      style={{ willChange: "opacity" }}
    >
      <StyledFooterWrapper>
        <div>
          <StyledFooterLogoCaffeina>
            <Icon name="never-sleep" />
          </StyledFooterLogoCaffeina>
          <StyledFooterNavs data-nav-mobile>
            <nav>
              <ul>
                {innerLinks &&
                  innerLinks.map((item, i) => {
                    return (
                      <li key={i}>
                        <WrapperLink
                          data={{
                            open_link_in_new_tab: false,
                            url: item.url,
                          }}
                        >
                          {item.title}
                        </WrapperLink>
                      </li>
                    );
                  })}
              </ul>

              <ul>
                {outerLinks &&
                  outerLinks.map((item, i) => {
                    return (
                      <li key={i}>
                        <Link href={item.url} passHref>
                          <a>{item.title}</a>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </StyledFooterNavs>
        </div>

        <div>
          <StyledFooterNavs data-nav-desktop>
            <nav>
              <ul>
                {innerLinks &&
                  innerLinks.map((item, i) => {
                    return (
                      <li key={i}>
                        <WrapperLink
                          data={{
                            open_link_in_new_tab: false,
                            url: item.url,
                            title: item.keyword,
                          }}
                        >
                          {item.title}
                        </WrapperLink>
                      </li>
                    );
                  })}
              </ul>

              <ul>
                {outerLinks &&
                  outerLinks.map((item, i) => {
                    return (
                      <li key={i}>
                        <Link href={item.url} passHref>
                          <a target="_blank" title={item.keyword}>
                            {item.title}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </StyledFooterNavs>

          {footerData.socials && (
            <StyledFooterSocials>
              {footerData.socials.map((item, i) => {
                return (
                  <li key={i}>
                    <a
                      target="_blank"
                      href={item.url}
                      rel="noopener noreferrer"
                      title={item.keyword}
                    >
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </StyledFooterSocials>
          )}

          {footerData.locations && (
            <StyledFooterOffices>
              {footerData.locations.map((item, i) => {
                return (
                  <div key={i}>
                    <strong>{item.name}</strong>
                    <p>
                      {item.address} {item.cap}
                    </p>
                  </div>
                );
              })}
            </StyledFooterOffices>
          )}

          <StyledFooterData>
            <div>
              {router.locale == "it"
                ? "CAFFEINA S.p.A Società Benefit"
                : "CAFFEINA S.p.A Benefit Society"}
              <br></br>
              {footerData.company_metadata && footerData.company_metadata.text}
            </div>
            <StyledFooterLogos>
              {footerData.company_metadata &&
                footerData.company_metadata.logos.map((el, i) => {
                  return (
                    <div key={i}>
                      <img
                        src={`/_next/image?url=${encodeURIComponent(
                          el.logo.url
                        )}&w=1200&q=75`}
                        alt={el.logo.alt}
                      />
                    </div>
                  );
                })}
            </StyledFooterLogos>
          </StyledFooterData>

          <StyledFooterEnd>
            <nav>
              <ul>
                <li>
                  <div>©{currentYr} caffeina</div>
                </li>
                {footerData.additional_links &&
                  footerData.additional_links.list.map((item, i) => {
                    return (
                      <li key={i}>
                        <WrapperLink
                          data={{
                            open_link_in_new_tab: item.open_link_in_new_tab,
                            url: item.url,
                            title: item.keyword,
                          }}
                        >
                          {item.label}
                        </WrapperLink>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </StyledFooterEnd>
        </div>
      </StyledFooterWrapper>
    </StyledFooter>
  );
};

export default Footer;
