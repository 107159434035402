import styled, { css } from "styled-components";

export const StyledWrapperLink = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;

  a {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
  }
`;
