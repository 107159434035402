const PeopleGallery = ({ text, list }) => {
  const normalizedData = {
    text,
    list,
  };

  return normalizedData;
};

export default PeopleGallery;
