const MediaCarousel = ({ style, list }) => {
  const normalizedData = {
    style,
    list,
  };

  return normalizedData;
};

export default MediaCarousel;
