import React, { useState } from "react";
import { StyledNewsletter, StyledRow, StyledCol } from "./styles.js";

import { Cta } from "../";
import { Title, Body } from "../Typography";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch(
        "https://hooks.zapier.com/hooks/catch/5704794/bfhouki/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      );

      let resJson = await res.json();
      if (res.status === 200) {
        setResponse(
          "Grazie per esserti iscritto! Riceverai aggiornamenti e novità dal mondo Bluemotion."
        );
      } else {
        setResponse("Some error occured");
      }
    } catch (err) {
      console.log(err);
      setResponse("Some critical error occured");
    }
  };

  return (
    <StyledNewsletter>
      <Title>
        Non trovi la posizione che desideri? Rimani aggiornato con la nostra
        newsletter
      </Title>
      <form onSubmit={handleSubmit}>
        <StyledRow>
          <StyledCol>
            <label>
              <input
                type="email"
                required={true}
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div />
            </label>
          </StyledCol>
          <StyledCol>
            <button type="submit">
              <Cta>Invia</Cta>
            </button>
          </StyledCol>
        </StyledRow>

        <StyledRow data-cb>
          <div>
            <label data-label-cb>
              <input type="checkbox" required />
              <span />
              <Body>
                Ho letto l'informativa privacy e presto il consenso al
                trattamento dei miei dati personali per l'invio di newsletter
              </Body>
            </label>
          </div>
        </StyledRow>
      </form>
    </StyledNewsletter>
  );
};

export default Newsletter;
