import styled from 'styled-components'

export const StyledMedia = styled.div`
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;

    img, video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .plyr {
        height: 100%;
    }
`
