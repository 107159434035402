import Image from 'next/image'

import { StyledBannerDynamo, StyledBannerDynamoBG, StyledBannerDynamoTitle } from './styles.js'
import { Headline, StyledRegularLink } from '../Typography/index.js'
import { Icon, WrapperLink, AnimateFadeIn, EditorialWrapper } from '../'

const BannerDynamo = ({ title, cta }) => {
  return (
    <>
      <AnimateFadeIn>
        <StyledBannerDynamo>
          <EditorialWrapper>
            {title ? (
              <StyledBannerDynamoTitle data-subtitle>
                <Headline>{title}</Headline>
              </StyledBannerDynamoTitle>
            ) : null}
            {cta ? (
              <div data-cta>
                <WrapperLink data={cta}>
                  <div>
                    <StyledRegularLink>
                      <div>
                        <Icon name="caret-right" />
                        <span>{cta.label}</span>
                        <Icon name="caret-right" />
                      </div>
                    </StyledRegularLink>
                  </div>
                </WrapperLink>
              </div>
            ) : null}
          </EditorialWrapper>
        </StyledBannerDynamo>
      </AnimateFadeIn>
      <StyledBannerDynamoBG>
        <Image
          src="/images/banner-dynamo-bg.jpg"
          alt="Dynamo Banner Abstract Background"
          quality={100}
          width={1920}
          layout="fill"
          sizes="(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 100vw"
        />
      </StyledBannerDynamoBG>
    </>
  )
}

export default BannerDynamo
