import styled, { css } from "styled-components";

export const StyledRichtext = styled.div`
  * {
    & + * {
      margin-top: ${({ theme }) => theme.margins.default};
      margin-bottom: ${({ theme }) => theme.margins.default};
    }
  }

  li {
    margin-top: 0.3rem;
    list-style-type: disc;
    padding-left: 0.5rem;
    margin-left: 1.3rem;
    margin-bottom: 0.6rem !important;
  }

  strong,
  b {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
  }
`;
