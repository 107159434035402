const Hero = ({ small, text, scrollto }) => {
  const normalizedData = {
    small,
    text,
    scrollto
  };

  return normalizedData;
};

export default Hero;
