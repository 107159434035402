import styled, { css } from "styled-components";

export const StyledEditorialData = styled.div`
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
      display: flex;
      justify-content: space-between;

      > div {
          width: 50%;
          &:first-of-type {
              padding-right: 3.2rem;
          }
          &:last-of-type {
              padding-left: 3.2rem;
          }
      }
  `)};
`;

export const StyledEditorialDataContent = styled.div``;

export const StyledEditorialDataData = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(100vw/12*2);

  ${({ theme, type }) => theme.mediaQuery.tablet(`
    margin-top: 0;
  `)};

  &.-odd {
    > div {
      &:nth-child(even) {
        ${({ theme }) =>
          theme.mediaQuery.tablet(`transform: translateY(50%);`)};
      }
    }
  }

  > div {
    margin-bottom: 3.2rem;
    padding-right: 3.2rem;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.mediaQuery.tablet(`width: 50%;`)};

    strong {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.accent};

      [data-theme="dark"] & {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
