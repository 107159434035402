const HeroJobPositionV2 = ({ title, text, cta, form_id, badges: _badges }) => {
  const ctaHiddenProps = {}
  const badges = Array.isArray(_badges) ? _badges : []

  if (cta && cta.form_id && cta.label.length > 2) {
    const { job_id, driver, form_action, portfolio, questions } = cta
    Object.assign(ctaHiddenProps, {
      position: job_id,
      driver,
      action: form_action,
      portfolio: Number(portfolio),
    })

    if (driver == 'factorial') {
      Object.assign(ctaHiddenProps, {
        questions,
      })
    }
  }
  const normalizedData = {
    title,
    text,
    cta,
    form_id,
    ctaHiddenProps,
    badges,
  }

  return normalizedData
}

export default HeroJobPositionV2
