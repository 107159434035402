import styled, { css } from "styled-components";

export const StyledFeaturedCases = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        display: flex;
    `)}

  > div {
    overflow: hidden;
    cursor: pointer;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
            width: 50%;
        `)}

    > div {
      aspect-ratio: 1 / 1;
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
                aspect-ratio: 16 / 9;
            `)}
    }
  }
`;
