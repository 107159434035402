const HeroContacts = ({ text, offices, contacts }) => {
  const normalizedData = {
    text,
    offices,
    contacts,
  };

  return normalizedData;
};

export default HeroContacts;
