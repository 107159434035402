const Editorial = ({ eyelet, title, text, type, cta, score, cases }) => {
  const normalizedData = {
    eyelet: eyelet,
    title: title,
    text: text,
    type: type,
    cta: cta,
    score: score,
    cases: cases,
  };

  return normalizedData;
};

export default Editorial;
