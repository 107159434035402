import styled, { css } from "styled-components";

export const StyledListItem = styled.ul`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  width: 100%;

  li {
    border-bottom: 1px solid #555555;

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
        `)}

    &:first-of-type {
      padding-top: 0;
    }
  }
`;

export const StyledListItemElement = styled.div`
  position: relative;
  padding: 3.2rem 5rem 3.2rem 0;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      padding: 3.2rem 8rem 3.2rem 0;
  `)};

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    right: 0;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      right: 3.2rem;
    `)};
    * {
      transition: stroke 0.2s ease;
      stroke: ${({ theme }) => theme.colors.accent};
    }
  }

  > div {
    position: relative;
    z-index: 1;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.accent};
    z-index: 0;
    transform: scaleY(0);
    transform-origin: bottom left;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .no-touch & {
    &:hover {
      color: ${({ theme }) => theme.colors.white100};

      svg {
        * {
          stroke: ${({ theme }) => theme.colors.white};
        }
      }

      > div {
        transform: translateX(32px);
      }

      &:after {
        transform: scaleY(1);
      }
    }
  }

  small,
  strong {
    display: block;
    position: relative;
    z-index: 1;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  small {
    ${({ theme }) => theme.typo("small")};
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => theme.rem(5)};
    letter-spacing: 1px;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: flex;
    `)};

    .area{
      display: inline-flex;
      gap: .5rem;
    }

    > div:not(.area) {
      font-weight: 700;
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
          margin-left: 0.5rem;
          margin-right: 0;
      `)};

      &:before {
        ${({ theme }) =>
          theme.mediaQuery.tablet(`
          content: "/";
          display: inline;
      `)};
      }

      span {
        margin-right: 0.5rem;
        white-space: nowrap;
        ${({ theme }) =>
          theme.mediaQuery.tablet(`
          margin-left: 0.5rem;
          margin-right: 0;
        `)};
      }
    }
  }

  strong {
    ${({ theme }) => theme.typo("titleSmall")};
  }
`;
