const FeaturedCases = ({ list }) => {
  const normalizedData = {
    cases: list.map((item) => {
      const { post_title, acf } = item;

      return {
        pretitle: acf.client_name,
        title: post_title,
        href: "https://google.com",
        media: {
          type: acf.hero.acf_fc_layout,
          src: acf.hero.image_case_study.url,
          alt: "yeah",
        },
      };
    }),
  };

  return normalizedData;
};

export default FeaturedCases;
