import React, { useState, useRef } from "react";
import { TitleSmall } from "../Typography";
import {
  StyledEditorialAccordion,
  StyledEditorialAccordionTitle,
  StyledEditorialAccordionContent,
  StyledEditorialAccordionGrid,
  StyledEditorialAccordionTrigger,
  StyledAccordionShadow,
} from "./styles.js";

import { Richtext, AnimateFadeIn } from "../";

const EditorialAccordion = ({ title, text, meta, load_more }) => {
  const domContainer = useRef(null);
  const domInnerContainer = useRef(null);

  const [isActive, setActive] = useState(false);

  const toggleClass = async () => {
    const gsap = (await import('gsap')).default;
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        window.dispatchEvent(new Event("resize"));
      },
    });

    tl.to(domContainer.current, {
      duration: 0.5,
      height: isActive
        ? "100"
        : domInnerContainer.current.offsetHeight + window.innerWidth / 12,
    });

    tl.play();
    setActive(!isActive);
  };

  return (
    <StyledEditorialAccordion className={isActive ? "-active" : null}>
      {title && (
        <StyledEditorialAccordionTitle>
          <TitleSmall>
            <AnimateFadeIn>{title}</AnimateFadeIn>
          </TitleSmall>
        </StyledEditorialAccordionTitle>
      )}

      <StyledEditorialAccordionTrigger>
        <AnimateFadeIn>
          <button onClick={toggleClass}>
            {load_more} <span>+</span>
            <span>-</span>
          </button>
        </AnimateFadeIn>
      </StyledEditorialAccordionTrigger>

      <StyledEditorialAccordionContent ref={domContainer} onClick={toggleClass}>
        <div ref={domInnerContainer}>
          {text && (
            <AnimateFadeIn>
              <Richtext>{text}</Richtext>
            </AnimateFadeIn>
          )}
          <StyledEditorialAccordionGrid>
            {meta.map((el, i) => {
              return (
                <div key={i}>
                  <strong>{el.key}</strong>
                  {el.values && <Richtext>{el.values}</Richtext>}
                </div>
              );
            })}
          </StyledEditorialAccordionGrid>
        </div>
      </StyledEditorialAccordionContent>
      <StyledAccordionShadow
        onClick={toggleClass}
        className={isActive ? "-active" : null}
      />
    </StyledEditorialAccordion>
  );
};

export default EditorialAccordion;
