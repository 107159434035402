import styled, { css } from "styled-components";

export const StyledCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 61.6%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 0;
    opacity: 0.5;
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  [data-launcher-boxes] & {
    &:after {
      opacity: 0.9;
    }
  }

  img,
  video {
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .no-touch & {
    &:hover {
      &:after {
        opacity: 0.8;
      }
      img,
      video {
        transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scale(1.02);
      }
    }
  }
`;
export const StyledCardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 1;
  padding: ${({ theme }) => theme.col(1)};

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    [data-caption] {
      max-width: 60%;
    }
  `)}

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    padding: 4rem 5.6rem;
  `)}
`;
