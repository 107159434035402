import styled, { css } from "styled-components";
import { motion } from "framer-motion";
export const StyledHeroServices = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ theme }) => theme.col(1)};
  margin-bottom: ${({ theme }) => theme.col(-3)};
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding-right: 82rem;
    height: 100vh;
    margin-bottom: calc(100vw/12*-1);
    min-height: 90rem;
  `)};

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
      padding-left: calc(100vw/12*2);
      padding-right: calc(100vw/12*5.5);
    `)};
`;

export const StyledHeroServicesTitle = styled.div`
  padding-top: calc(100vw / 12 * 4);
  padding-bottom: calc(100vw / 12 * 4);
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        padding: 0;
    `)};
`;

export const StyledHeroCta = styled.div`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  bottom: calc(100vw / 12 * 1);
  left: calc(100vw / 12 * 1);
  display: none;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    display: inline-block;
  `)}

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
      left: calc(100vw / 12 * 2);
    `)};

  svg {
    transform: rotate(90deg);
  }
`;

export const StyledWrapperBox = styled(motion.div)`
  background: ${({ theme }) => theme.colors.black};
  width: 100vw;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    bottom: 0;
    width: auto;
    right: 0;
    display: flex;
    border: 1px solid white;
    border-bottom: 0;
    position: absolute;
    min-height: 67rem;
  `)};
`;

export const StyledBox = styled(motion.div)`
  transition: all 0.5s ease;
  transform: translateX(calc(100vw / 12 * -1));
  max-height: 10rem;
  overflow: hidden;
  border-top: 1px solid white;

  .no-touch & {
    &:hover {
      background-color: ${({ theme }) => theme.colors.accent};
      [data-title] {
        color: ${({ theme }) => theme.colors.white} !important;
      }

      [data-content] {
        color: ${({ theme }) => theme.colors.white} !important;
      }
    }
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        transform: none;
        border-top: 0 solid white;
        width: 40rem;
        max-height: none;
        padding-top: 4rem;
        padding-left: 10rem;
        padding-right: 2rem;
        border-right: 1px solid white;
        position: relative;
        max-width: 12rem;
  `)};

  &[data-status="active"] {
    background-color: white;
    color: ${({ theme }) => theme.colors.black};
    max-height: 100rem;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
        max-width: 40rem;
      `)};
  }

  [data-title] {
    padding: 0 calc(100vw / 12 * 1);
    line-height: 10rem;
    transition: color 0.4s ease;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
        line-height: auto;
        cursor: pointer;
        position: absolute;
        top: 4rem;
        left: 1rem;
        padding: 0;
        writing-mode: vertical-lr;
        transform: rotate(-180deg);
    `)};
  }

  [data-content] {
    transition: color 0.4s ease;
    padding: calc(100vw / 12 * 0.5) calc(100vw / 12 * 1) calc(100vw / 12 * 1);

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
        padding: 0;
        position: absolute;
        top: 4rem;
        width: 23rem;
        left: 13rem;
    `)};

    font-size: 1.2rem !important;
    line-height: 1.4 !important;

    * {
      & + * {
        margin-top: 1.2rem !important;
        margin-bottom: 1.2rem !important;
      }
    }
  }
`;
