import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-creative";

import initReactFastclick from "react-fastclick";
import { AnimatePresence } from "framer-motion";

import ReelProvider from "../contexts/Reel";
import ThemeProvider from "../contexts/Theme";
import MouseProvider from "../contexts/Mouse";
import ScrollProvider from "../contexts/Scroll";

import { Header, Reel, Preloader, Cursor, Footer, IframeModal } from "../components";

initReactFastclick();

const MyApp = ({ Component, pageProps, router }) => {
  const exitPreview = async () => {
    try {
      router.push("/api/clear-preview");
    } catch (error) {
      console.warn("Error cleaning preview cookie", error);
    }
  };

  return (
    <ReelProvider>
      <ThemeProvider>
        <MouseProvider>
          <AnimatePresence exitBeforeEnter>
            {pageProps.reelData && (
              <Reel key={"reel"} reelData={pageProps.reelData} />
            )}
            <Preloader />
            {pageProps.isPreview ? (
              <button
                style={{
                  position: "absolute",
                  zIndex: 1000,
                  top: "20px",
                  right: "30px",
                }}
                onClick={exitPreview}
              >
                Clear preview
              </button>
            ) : null}
            <Header
              key={router.query.id}
              mainMenuData={pageProps.mainMenuData}
            />
            <ScrollProvider key={router.asPath}>
              <Component {...pageProps} key={router.asPath} />
              <Footer footerData={pageProps.footerData} />
            </ScrollProvider>
          </AnimatePresence>
          <Cursor />
          <IframeModal/>
        </MouseProvider>
      </ThemeProvider>
    </ReelProvider>
  );
};

export default MyApp;
