import React from "react";

import { ListItem, AnimateFadeIn, WrapperLink, Richtext, Cta } from "../";

import {
  StyledFeaturedCareers,
  StyledFeaturedCareersHeader,
  StyledFeaturedCareerLink,
} from "./styles.js";

const injectHtml = (children) => {
  return { __html: children };
};

const FeaturedCareers = ({ list, cta, text }) => {
  return (
    <StyledFeaturedCareers>
      <AnimateFadeIn>
        <StyledFeaturedCareersHeader>
          {text && <Richtext>{text}</Richtext>}
        </StyledFeaturedCareersHeader>
      </AnimateFadeIn>

      {list && <ListItem list={list} />}

      {cta && (
        <AnimateFadeIn>
          <StyledFeaturedCareerLink>
            <WrapperLink data={cta}>
              <Cta>
                <span>{cta.label}</span>
              </Cta>
            </WrapperLink>
          </StyledFeaturedCareerLink>
        </AnimateFadeIn>
      )}
    </StyledFeaturedCareers>
  );
};

export default FeaturedCareers;
