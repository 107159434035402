import styled, { css } from "styled-components";

export const StyledMediaCarousel = styled.div`
  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    width: calc(10 / 12 * 100vw);
    margin: 0 1.6rem;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      width: calc(4 / 12 * 100vw);
    `)};
  }
  > span {
    position: absolute;
    top: 0;
    height: 100%;
    width: ${({ theme }) => theme.col(2)};
    cursor: pointer;
    z-index: 9;
    display: block;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }
`;

export const StyledMediaCarouselSlide = styled.div`
  &[data-ratio="all4/3"] {
    aspect-ratio: 3/4;
  }
`;
