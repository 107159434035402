import SwiperCore, { EffectCreative, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/autoplay";

import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Display, SubHeader } from "../Typography";
import {
  StyledArchiveJobs,
  StyledHeroJobs,
  StyledHeroJobsTitle,
  StyledHeroJobsFilters,
  StyledListJobs,
  StyledListItemElement,
  StyledZeroResults
} from "./styles.js";

SwiperCore.use([EffectCreative, Autoplay]);

const ArchiveJobs = ({ generic, unit_filters, office_filters, list }) => {
  const { locale } = useRouter();

  const [cityFilter, setCityFilter] = useState("");
  const [cityFilterOpen, setCityFilterOpen] = useState(false);
  const [areaFilter, setAreaFilter] = useState("");
  const [areaFilterOpen, setAreaFilterOpen] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState(list);

  useEffect(() => {
    const onScroll = () => {
      if (areaFilterOpen === true) {
        setAreaFilterOpen(false);
      }
      if (cityFilterOpen === true) {
        setCityFilterOpen(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [areaFilterOpen, cityFilterOpen]);

  const filterJobs = (list, areaFilter, cityFilter) => {
    const _list = [...list];

    return _list.filter(({ area, location }) => {
      const _area = area.map(({ slug }) => slug);
      const _location = location.map(({ slug }) => slug);
      const isArea = areaFilter === "" || _area.includes(areaFilter);
      const isLocation = cityFilter === "" || _location.includes(cityFilter);
      return isArea && isLocation;
    });
  };

  useEffect(() => {
    if (areaFilter === "" && cityFilter === "") {
      setFilteredJobs(list);
    } else {
      setFilteredJobs(filterJobs(list, areaFilter, cityFilter));
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  }, [areaFilter, cityFilter]);

  return (
    <>
      <StyledArchiveJobs>
        <StyledHeroJobs>
          <StyledHeroJobsTitle>
            <Display>
              <h1>We need <em>{list.length}</em> more creators</h1>
            </Display>
            <Display data-cities>
              to work from
              <Swiper
                loop={true}
                autoplay={{
                  delay: 2000,
                }}
                modules={[EffectCreative, Autoplay]}
                slidesPerView={1}
                effect={"creative"}
                creativeEffect={{
                  progressMultiplier: 1,
                  limitProgress: 2,
                  prev: {
                    translate: ["0%", "-100%", 0],
                    opacity: 0,
                  },
                  next: {
                    translate: ["0%", "100%", 0],
                    opacity: 0,
                  },
                }}
              >
                <SwiperSlide>
                  <em>Parma</em>
                </SwiperSlide>
                <SwiperSlide>
                  <em>Milano</em>
                </SwiperSlide>
                <SwiperSlide>
                  <em>Roma</em>
                </SwiperSlide>
              </Swiper>
            </Display>
          </StyledHeroJobsTitle>

          <StyledHeroJobsFilters>
            <SubHeader>
              {locale == "en"
                ? "See job openings "
                : "Visualizza le posizioni "}
              <br />
              {locale == "en" ? "in " : "aperte in"}

              <div data-filter>
                <button
                  data-status={areaFilterOpen}
                  onClick={() => {
                    setAreaFilterOpen(!areaFilterOpen);
                  }}
                >
                  {locale == "en" &&
                    (areaFilter === ""
                      ? "All Areas"
                      : `${areaFilter
                          .replace("-", " ")
                          .replaceAll("-en", "")
                          .replaceAll(" en", "")}`)}

                  {locale == "it" &&
                    (areaFilter === ""
                      ? "Ogni Area"
                      : `${areaFilter.replace("-", " ")}`)}

                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {unit_filters && (
                  <ul data-status={areaFilterOpen}>
                    <li>
                      <span
                        onClick={() => {
                          setAreaFilter("");
                          setAreaFilterOpen(!areaFilterOpen);
                        }}
                      >
                        {locale == "en" ? "All Areas " : "Ogni Area"}
                      </span>
                    </li>
                    {unit_filters.map((item, i) => {
                      return (
                        <li
                          key={i}
                          data-active={
                            item.slug === areaFilter ? "active" : null
                          }
                        >
                          <span
                            onClick={() => {
                              setAreaFilter(item.slug);
                              setAreaFilterOpen(!areaFilterOpen);
                            }}
                          >
                            {item.name}
                          </span>
                        </li>
                      );
                    })}
                    <div
                      onClick={() => {
                        setAreaFilterOpen(!areaFilterOpen);
                      }}
                    />
                  </ul>
                )}
              </div>
              <br />
              {locale == "en" ? "for " : "per"}
              <div data-filter>
                <button
                  data-status={cityFilterOpen}
                  onClick={() => {
                    setCityFilterOpen(!cityFilterOpen);
                  }}
                >
                  {locale == "en" &&
                    (cityFilter === ""
                      ? "Every Office"
                      : `${cityFilter
                          .replace("-", " ")
                          .replaceAll("-en", "")
                          .replaceAll(" en", "")}`)}

                  {locale == "it" &&
                    (cityFilter === ""
                      ? "Ogni Ufficio"
                      : cityFilter.replace("-", " "))}

                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {office_filters && (
                  <ul data-status={cityFilterOpen}>
                    <li>
                      <span
                        onClick={() => {
                          setCityFilter("");
                          setCityFilterOpen(!cityFilterOpen);
                        }}
                      >
                        {locale == "en" ? "Every Office" : "Ogni Ufficio"}
                      </span>
                    </li>
                    {office_filters.map((item, i) => {
                      return (
                        <li
                          key={i}
                          data-active={
                            item.slug === areaFilter ? "active" : null
                          }
                        >
                          <span
                            onClick={() => {
                              setCityFilter(item.slug);
                              setCityFilterOpen(!cityFilterOpen);
                            }}
                          >
                            {item.name}
                          </span>
                        </li>
                      );
                    })}
                    <div
                      onClick={() => {
                        setCityFilterOpen(!cityFilterOpen);
                      }}
                    />
                  </ul>
                )}
              </div>
            </SubHeader>
          </StyledHeroJobsFilters>
        </StyledHeroJobs>

        <StyledListJobs>
          {filteredJobs.length > 0 ? (
            <ul>
              {filteredJobs.map((item, i) => {
                return (
                  <li key={i}>
                    <Link passHref href={item.url}>
                      <a title={item.keyword}>
                        <StyledListItemElement>
                          <div>
                            <small>
                              {item.area && (
                                <>
                                  {item.area.map((area, j) => {
                                    return <span key={j}>{area.name}</span>;
                                  })}
                                </>
                              )}

                              {item.location && (
                                <div>
                                  {item.location.map((office, h) => {
                                    return <span key={h}>{office.name}</span>;
                                  })}
                                </div>
                              )}
                            </small>
                            {item.position && <strong>{item.position}</strong>}
                          </div>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M6.59961 25.4004L24.5996 7.40039"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4004 7.40039H24.5997L24.5997 16.5997"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </StyledListItemElement>
                      </a>
                    </Link>
                  </li>
                );
              })}

              {generic &&
                generic.map((el, i) => {
                  return (
                    <li data-bg="grey" key={"g" + i}>
                      <Link passHref href={el.url}>
                        <a>
                          <StyledListItemElement>
                            <div>
                              <strong>{el.title}</strong>
                            </div>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                            >
                              <path
                                d="M6.59961 25.4004L24.5996 7.40039"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.4004 7.40039H24.5997L24.5997 16.5997"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </StyledListItemElement>
                        </a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          ) : (
            <StyledZeroResults>
              <SubHeader>
                {locale == "en"
                  ? "We don’t have job openings for this selection at the moment, try changing some filters!"
                  : "Non ci sono posizioni per questa selezione, prova a cambiare uno dei filtri!"}
              </SubHeader>
            </StyledZeroResults>
          )}
        </StyledListJobs>
      </StyledArchiveJobs>
    </>
  );
};

export default ArchiveJobs;
