import React, { useRef, useEffect } from "react";
import { StyledVideoPlayer } from "./styles.js";
import "plyr-react/dist/plyr.css";

const VideoPlayer = ({ video_id, poster, active }) => {
  const ref = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    const initPlayer = async () => {
      if (ref.current == null) return;
      ref.current.querySelector(
        "iframe"
      ).src = `https://player.vimeo.com/video/${video_id}`;
      const Plyr = (await import('plyr')).default;
      player.current = new Plyr(ref.current, {
        autopause: true,
        resetOnEnd: true,
      });
  
      player.current.poster = poster;
  
      player.current.on("ended", (event) => {
        player.current.currentTime = 0;
        player.current.pause();
      });
    }
    initPlayer().catch(console.error);
  }, []);

  useEffect(() => {
    if (player.current == null) return;
    if (active == "active") {
      player.current.play();
    } else {
      player.current.pause();
    }
  }, [active]);

  return (
    <StyledVideoPlayer>
      <div>
        <div ref={ref} className="plyr__video-embed">
          <iframe
            allowFullScreen
            allowtransparency="true"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </StyledVideoPlayer>
  );
};

export default VideoPlayer;
