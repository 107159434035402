const EditorialTwoCols = ({ eyelet, title, text, cta, score, cases }) => {
  const normalizedData = {
    eyelet,
    title,
    text,
    cta,
    score,
    cases,
  };

  return normalizedData;
};

export default EditorialTwoCols;
