import styled from "styled-components";

export const StyledGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.col(1)};
  position: relative;
  z-index: 2;
`;

export const StyledGridElement = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &[data-mobile="true"] {
    aspect-ratio: 530/611;
    ${({ theme, type }) => theme.mediaQuery.desktop(`
      aspect-ratio: 16/9;
    `)};

    > div {
      > div {
        &:first-of-type {
          display: none;
          ${({ theme, type }) => theme.mediaQuery.desktop(`
            display: block;
          `)};
        }
        &:last-of-type {
          ${({ theme, type }) => theme.mediaQuery.desktop(`
            display: none;
          `)};
        }
      }
    }
  }

  ${({ type }) =>
    type === "full" ? "aspect-ratio: 16/9;" : "aspect-ratio: 530/611;"}

  ${({ theme, type }) =>
    theme.mediaQuery.desktop(`
        width: ${type === "full" ? "100%" : `calc(50% - ${theme.rem(42)})`};
        aspect-ratio: ${type === "full" ? "16/9" : `530/611`};
    `)}

  > div {
    will-change: transform;
    backface-visibility: hidden;
    height: 100%;
    width: 100%;
  }

  margin-bottom: ${({ theme }) => theme.col(1)};

  [data-length="1"] & {
    margin-bottom: 0;
  }

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    margin-bottom: ${theme.rem(73)};
  `)}
`;
