import {
  StyledSection,
  StyledSectionBackground,
  StyledSectionBackgroundImage,
} from "./styles.js";

import Image from "next/image";

export default function Section({
  children,
  variant,
  background = false,
  ...props
}) {
  return (
    <StyledSection
      {...props}
      variant={variant}
      data-theme={variant}
      data-extra-bg={background.colour ? true : false}
      background={background}
    >
      {background.background && (
        <StyledSectionBackgroundImage>
          <div>
            <Image alt="" src={background.background.url} layout="fill" />
          </div>
        </StyledSectionBackgroundImage>
      )}

      {background.height && <StyledSectionBackground {...background} />}
      {children}
    </StyledSection>
  );
}
