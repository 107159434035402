import React from "react";
import { StyledIconsList } from "./styles.js";
import { Headline, BodyBig } from "../Typography";
import { AnimateFadeIn, Media } from "../";
const IconsList = ({ title, list }) => {
  return (
    <StyledIconsList>
      {title ? (
        <Headline data-title>
          <AnimateFadeIn>{title}</AnimateFadeIn>
        </Headline>
      ) : null}

      {list.length > 0 ? (
        <div data-list>
          <ul>
            {list.map((el, i) => {
              return (
                <li key={i}>
                  <AnimateFadeIn>
                    <div data-wrapper>
                      <div data-media>
                        <Media {...el.media} />
                      </div>
                      <BodyBig>{el.title}</BodyBig>
                    </div>
                  </AnimateFadeIn>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </StyledIconsList>
  );
};

export default IconsList;
