import styled, { css } from "styled-components";

export const StyledLauncherLinks = styled.div`
  padding: 0 calc(100vw / 12 * 1);

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  ul {
    padding: 0;
    margin-top: calc(100vw / 12 * 0.5);
  }
`;
