import styled, { css } from "styled-components";

export const StyledEditorialWrapper = styled.div`
  [data-eyelet] {
    color: #afafaf;
    margin-bottom: 0.8rem;
  }

  [data-subtitle] {
    & + [data-cta] {
      margin-top: 3.2rem;
    }
  }

  [data-cta] {
    a {
      display: inline-block;
      width: auto;
    }
  }

  [data-title] {
    & + [data-cta] {
      margin-top: calc(100vw / 12 * 1);
    }
    & + [data-richtext] {
      margin-top: 3.2rem;
    }
  }

  [data-richtext] {
    & + [data-cta] {
      margin-top: 3.2rem;
    }
  }
`;
