// https://samuelkraft.com/blog/spring-parallax-framer-motion-guide

import { useState, useRef, useEffect } from "react";
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
} from "framer-motion";

const Parallax = ({ children, offset = 300 }) => {
  const [elementTop, setElementTop] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const ref = useRef(null);

  const { scrollY } = useViewportScroll();

  const initial = elementTop - clientHeight;
  const final = elementTop + clientHeight;
  //   const final = elementTop + offset;

  const xRange = useTransform(scrollY, [initial, final], [0, -offset]);
  //   const xRange = useTransform(scrollY, [initial, final], [offset, -offset]);
  const x = useSpring(xRange, { stiffness: 400, damping: 100 });
  //   const x = xRange;

  useEffect(() => {
    const element = ref.current;
    const onResize = () => {
      setElementTop(
        element.getBoundingClientRect().top + window.scrollY ||
          window.pageYOffset
      );
      setClientHeight(window.innerHeight);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref]);

  return (
    <motion.div ref={ref} style={{ x, willChange: "transform" }}>
      {children}
    </motion.div>
  );
};

export default Parallax;
