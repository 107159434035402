const LauncherText = ({ title, cta, list }) => {
  const normalizedData = {
    title,
    cta,
    list,
  };

  return normalizedData;
};

export default LauncherText;
