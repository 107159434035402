export default () => (
  <svg width="72" height="72" viewBox="0 0 72 72">
    <rect
      x="16.9082"
      y="15"
      width="56"
      height="2"
      transform="rotate(45 16.9082 15)"
    />
    <rect
      x="15.4941"
      y="55"
      width="56"
      height="2"
      transform="rotate(-45 15.4941 55)"
    />
  </svg>
);
