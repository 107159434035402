import Logo from "./logo";
import LogoBig from "./logo-big";
import Hexagon from "./hexagon";
import CaretRight from "./caret-right";
import PreFooterBolt from "./pre-footer-bolt";
import NeverSleep from "./never-sleep";
import ArrowDown from "./arrow-down";
import Hamburger from "./hamburger";

export default {
  logo: Logo,
  logoBig: LogoBig,
  hexagon: Hexagon,
  "caret-right": CaretRight,
  "pre-footer-bolt": PreFooterBolt,
  "never-sleep": NeverSleep,
  "arrow-down": ArrowDown,
  hamburger: Hamburger,
};
