import styled, { css } from "styled-components";

export const StyledIconsList = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding-right: calc(100vw/12*2);
    padding-left: calc(100vw/12*2);
  `)}

  [data-title] {
    margin-bottom: ${({ theme }) => theme.col(1)};
  }

  ul {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: flex;
      flex-wrap: wrap;
    `)}
    li {
      margin-bottom: ${({ theme }) => theme.col(0.5)};
      padding-right: ${({ theme }) => theme.col(0.5)};
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
        width: 50%;
        `)}
    }
  }

  [data-wrapper] {
    display: flex;
  }

  [data-media] {
    min-width: 6rem;
    width: 6rem;
    height: 6rem;
    margin-right: 2rem;
    img {
      object-fit: contain;
    }
  }
`;
