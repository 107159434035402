import styled from "styled-components";

export const StyledSection = styled.section`
  width: 100%;
  padding-top: ${({ theme }) => theme.col(2)};
  padding-bottom: ${({ theme }) => theme.col(2)};
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding-top: ${theme.col(1)};
    padding-bottom: ${theme.col(1)};
  `)}

  &:first-of-type {
    padding-top: 0;
  }

  & ~ section {
    transform: translateY(-1px);
    margin-top: -1px;
  }

  &[data-theme="dynamo"] {
    color: ${({ theme }) => theme.colors.white};
  }

  &[data-theme="dark"] {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};

    & + [data-theme="dark"] {
      padding-top: 0;
    }

    & + [data-theme="light"] {
      [data-launcher-boxes] {
        margin-top: ${({ theme }) => theme.col(-1)};
      }
    }
  }

  &[data-theme="grey"] {
    & + [data-theme="light"] {
      [data-launcher-boxes] {
        margin-top: ${({ theme }) => theme.col(-1)};
      }
    }
  }

  &[data-theme="light"] {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};

    & + [data-theme="light"] {
      padding-top: 0;
    }

    & + [data-theme="light"] {
      &[data-extra-bg="true"] {
        padding-top: ${({ theme }) => theme.col(1)};
      }
    }

    &[data-extra-bg="true"] {
      & + [data-extra-bg="true"] {
        padding-top: 0;
      }
    }
  }

  &[data-theme="grey"] {
    background-color: #ebebeb;
    color: ${({ theme }) => theme.colors.black};

    & + [data-theme="grey"] {
      padding-top: 0;
    }

    & + [data-theme="grey"] {
      &[data-extra-bg="true"] {
        padding-top: ${({ theme }) => theme.col(1)};
      }
    }

    &[data-extra-bg="true"] {
      & + [data-extra-bg="true"] {
        padding-top: 0;
      }
    }
  }
`;

export const StyledSectionBackground = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${({ colour }) => colour};
  position: absolute;
  ${({ position }) => (position === "top" ? "top: 0;" : "bottom: 0;")}
  z-index: 0;
`;

export const StyledSectionBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  pointer-events: none;

  > div {
    position: relative;
    height: 100%;
    width: 100%;
    > span,
    img {
      position: relative !important;
      width: 100% !important;
      height: auto !important;
    }
  }
`;
