import React from "react";
import {
  StyledPeopleCard,
  StyledPeopleCardContent,
  StyledPeopleCardAnimoji,
  StyledPeopleCardContentAnimoji,
  StyledPeopleCardAnimojiText,
  StyledPeopleCardRatio,
} from "./styles.js";

import Image from "next/image";

import { Richtext, EditorialWrapper } from "../";
import {
  TitleSmall,
  SubHeader,
  SubHeaderSmall,
  Body,
} from "../Typography/index.js";

const PeopleCard = ({ title, text, media, animoji }) => {
  const renderPeopleCard = (title, text, media) => {
    return (
      <StyledPeopleCard>
        <StyledPeopleCardRatio>
          <StyledPeopleCardContent>
            {title && <SubHeader>{title}</SubHeader>}
            {text && (
              <Body>
                <Richtext>{text}</Richtext>
              </Body>
            )}
          </StyledPeopleCardContent>
          <div>
            {media.image && (
              <Image
                src={media && media.image.url}
                alt={media.image.alt}
                layout="fill"
                loading="eager"
                alt={title ? title : ""}
              />
            )}
          </div>
        </StyledPeopleCardRatio>
      </StyledPeopleCard>
    );
  };

  const renderAnimojiCard = (title, text, media) => {
    return (
      <StyledPeopleCard data-animoji>
        <StyledPeopleCardRatio data-animoji>
          <StyledPeopleCardContentAnimoji>
            <StyledPeopleCardAnimoji>
              <div>
                <video
                  poster={media.image.url}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                >
                  <source type="video/mp4" src={media.video.mp4} />
                  <source type="video/webm" src={media.video.webm} />
                </video>
              </div>
            </StyledPeopleCardAnimoji>
            <StyledPeopleCardAnimojiText>
              <EditorialWrapper>
                <TitleSmall data-title>{title}</TitleSmall>
                <SubHeaderSmall data-richtext>
                  <Richtext>{text}</Richtext>
                </SubHeaderSmall>
              </EditorialWrapper>
            </StyledPeopleCardAnimojiText>
          </StyledPeopleCardContentAnimoji>
        </StyledPeopleCardRatio>
      </StyledPeopleCard>
    );
  };

  return animoji
    ? renderAnimojiCard(title, text, media)
    : renderPeopleCard(title, text, media);
};

export default PeopleCard;
