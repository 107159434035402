import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import React from "react";
import * as Components from "../";
import * as Models from "../../models";

const SectionBuilder = ({ data }) => {
  const router = useRouter();
  const normalizedComponents = {
    HorizontalPreview: "HorizontalPreview",
    Editorial: "Editorial",
    ClientCarousel: "ClientCarousel",
    CaseCarousel: "CaseCarousel",
    PreFooter: "Prefooter",
    Grid: "Grid",
    Hero: "Hero",
    FeaturedCareers: "FeaturedCareers",
    featured_cases: "FeaturedCases",
    HeroSingleCase: "HeroProject",
    AccordionExtra: "EditorialAccordion",
    EditorialData: "EditorialData",
    Quote: "Quote",
    EditorialGridSlider: "EditorialGrid",
    Cards: "EditorialCards",
    LauncherBoxes: "LauncherBoxes",
    ArchiveCases: "ListProjects",
    BannerThunder: "BannerThunder",
    BannerThunderV2: "BannerThunderV2",
    BannerThunderV3: "BannerThunderV3",
    PeopleHero: "PeopleHero",
    MediaCarousel: "MediaCarousel",
    EditorialMedia: "EditorialMedia",
    EditorialStories: "EditorialStories",
    HeroJobPosition: "HeroJobPosition",
    HeroJobPositionV2: "HeroJobPositionV2",
    VideoPlayer: "VideoPlayer",
    GridLogo: "GridLogo",
    LauncherLinks: "LauncherLinks",
    LauncherText: "LauncherText",
    HeroServices: "HeroServices",
    Newsletter: "Newsletter",
    ArchiveJobs: "ArchiveJobs",
    HeroContacts: "HeroContacts",
    EditorialTwoCols: "EditorialTwoCols",
    IconsList: "IconsList",
    PeopleGallery: "PeopleGallery",
    BannerDynamo: "BannerDynamo",
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={router.asPath}
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 1.5,
          ease: "anticipate",
        }}
        style={{ willChange: "opacity" }}
        animate={{
          opacity: 1,
        }}
      >
        {data.sections.map(
          ({ acf_fc_layout, data, theme, background = false }, i) => {
            const Component = Components[normalizedComponents[acf_fc_layout]]
            const formattedData = Component ? Models[normalizedComponents[acf_fc_layout]](data) : null

            return Component ? (
              <Components.Section
                variant={theme}
                background={background}
                key={i}
              >
                <Component {...formattedData} />
              </Components.Section>
            ) : null
          }
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default SectionBuilder;
