import styled, { css } from "styled-components";

export const StyledBannerDynamoTitle = styled.div``;

export const StyledBannerDynamo = styled.div`
  position: relative;
  padding: 0 calc(100vw / 12 * 1);
  overflow: hidden;
  margin-top: calc(100vw / 12 * -1);
  margin-bottom: calc(100vw / 12 * -1);

  button {
    cursor: pointer;
    background: none;
    border: 0;
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
    padding: 0;
    font-weight: 600;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;

    span {
      font-weight: 600;
      font-family: Manrope, sans-serif;
    }
  }

  ${({ theme }) => theme.mediaQuery.tablet(`
    padding: calc(100vw / 12 * 1) calc(100vw / 12 * 2);
    display: flex;
  `)};

  > div {
    &:first-child {
      max-width: 80%;
      position: relative;
      margin: 0 auto;
      text-align: center;
      z-index: 1;

      ${({ theme }) => theme.mediaQuery.tablet(`
        max-width: 60%;
      `)};
    }
  }
`;

export const StyledBannerDynamoBG = styled.div`
  width: 100%;
  height: 100%;
  padding-top: calc(100vw / 12 * -1);
  padding-bottom: calc(100vw / 12 * -1);

  span {
    position: relative;
  }

  img {
    object-fit: cover;
    object-position: center;
  }
`;
