import React, { useContext } from "react";
import { StyledWrapperLink } from "./styles.js";
import Link from "next/link";

import { MouseContext } from "../../contexts/Mouse";

const WrapperLink = ({ children, data }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  const dataLink = {
    url: data ? data.url : "https://google.com",
    open_link_in_new_tab: data ? data.open_link_in_new_tab : "",
    label: data ? data.label : "",
    title: data.keyword ? data.keyword : "",
  };

  return (
    <StyledWrapperLink data-wrapper-link>
      {dataLink.open_link_in_new_tab == true ? (
        <Link passHref href={dataLink.url}>
          <a target="_blank" rel="noopener noreferrer" title={dataLink.title}>
            {children}
          </a>
        </Link>
      ) : (
        <Link passHref href={dataLink.url} scroll={false}>
          <a title={dataLink.title}>{children}</a>
        </Link>
      )}
    </StyledWrapperLink>
  );
};

export default WrapperLink;
