import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledHeroCta = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }
`;

export const StyledHero = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ theme }) => theme.col(1)};
  margin-bottom: ${({ theme }) => theme.col(-1)};
  overflow: hidden;

  &[data-small="true"] {
    height: auto;
    min-height: auto;
    padding-top: calc(100vw / 12 * 4);
    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      padding-top: calc(100vw / 12 * 2);
    `)};
  }

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
        padding-right: ${theme.col(2)};
    `)}
  
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-right: ${theme.col(2)};
        padding-left: ${theme.col(2)};
    `)}
`;

export const StyledHeroTitle = styled.h1`
  ${({ theme }) => theme.typo("display")};
  z-index: 1;
  position: relative;
  z-index: 1;

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    max-width: calc(100vw/12*6);
  `)}

  & + div {
    margin-top: ${({ theme }) => theme.col(1)};
  }
`;

export const StyledHeroLogo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 90%;
  width: 66.6vw;
  z-index: 0;
  pointer-events: none;
  z-index: 0;
  &.-inView {
    path {
      will-change: opacity;
      animation: 2s ${pulse} ease-in-out infinite alternate;
    }
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }

  path {
    opacity: 0;
    will-change: opacity;

    &:nth-child(1) {
      animation-delay: 0.3s;
    }
    &:nth-child(2) {
      animation-delay: 0.6s;
    }
    &:nth-child(3) {
      animation-delay: 0.9s;
    }
    &:nth-child(4) {
      animation-delay: 1.2s;
    }
    &:nth-child(5) {
      animation-delay: 1.5s;
    }
    &:nth-child(6) {
      animation-delay: 1.8s;
    }
    &:nth-child(7) {
      animation-delay: 2.1s;
    }
    &:nth-child(8) {
      animation-delay: 2.4s;
    }
    &:nth-child(9) {
      animation-delay: 2.7s;
    }
  }
`;

export const StyledHeroLink = styled.a`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => theme.typo("subHeaderSmall")};
  z-index: 1;
  position: relative;

  > div {
    margin-left: ${({ theme }) => theme.rem(8)};
  }
`;
