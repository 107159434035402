import React from "react";
import { StyledPreloader, StyledPreloaderOut } from "./styles.js";

const Preloader = () => {
  return (
    <StyledPreloader>
      <StyledPreloaderOut
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        transition={{
          duration: 1.5,
          ease: "anticipate",
        }}
        style={{ willChange: "transform, opacity" }}
      />
    </StyledPreloader>
  );
};

export default Preloader;
