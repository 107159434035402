import styled, { css } from "styled-components";

export const StyledPeopleHero = styled.div`
  padding-top: calc(100vw / 12 * 4);
  padding-right: calc(100vw / 12 * 1);
  padding-left: calc(100vw / 12 * 1);
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      padding-top: calc(100vw/12*2);
      padding-right: calc(100vw/12*1);
      padding-left: calc(100vw/12*1);
      // margin-bottom: calc(100vw/12*-1.5);
  `)}

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
      padding-left: calc(100vw/12*2);
    `)};
`;

export const StyledPeopleHeroTitle = styled.div`
  ${({ theme }) => theme.mediaQuery.tablet(`max-width: 60%;`)}
`;

export const StyledPeopleHeroCta = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.col(2)};
  left: ${({ theme }) => theme.col(1)};
  z-index: 3;
  cursor: pointer;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      bottom: calc(100vw/12*2);
  `)}

  * {
    color: white;
  }
`;

export const StyledPeopleHeroParallax = styled.div`
  display: inline-flex;
  will-change: transform;
  flex-wrap: nowrap;
  padding-top: ${({ theme }) => theme.col(3)};
  padding-left: ${({ theme }) => theme.col(1)};
  padding-bottom: calc(3 / 12 * 100vw);

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      padding-top: calc(100vw/12*1);
      padding-bottom: 0;
  `)}

  > div {
    margin-right: ${({ theme }) => theme.margins.default};
    min-width: calc(8 / 12 * 100vw - 32px);

    > div {
      height: auto;
      > div {
        margin-bottom: ${({ theme }) => theme.margins.default};
      }
    }

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
            min-width: calc(3 / 12 * 100vw - 32px);
        `)}

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      min-width: calc(2.75 / 12 * 100vw - 32px);
    `)}

    &:nth-child(even) {
      transform: translateY(calc(3 / 12 * 100vw));

      ${({ theme }) =>
        theme.mediaQuery.desktop(`
        transform: translateY(calc(0.33 / 12 * 100vw));
      `)}
    }
  }
`;

export const StyledPeopleHeroCase = styled.div`
  > div {
    aspect-ratio: 266 / 400;
  }
`;
