import React from "react";
import { Media } from "../";
import { TitleSmall, Caption } from "../Typography";
import { StyledCard, StyledCardContent } from "./styles.js";

const Card = ({ title, description, media }) => {
  return (
    <StyledCard>
      <Media {...media} />
      <StyledCardContent>
        <Caption data-caption>{title.toUpperCase()}</Caption>
        <TitleSmall>{description}</TitleSmall>
      </StyledCardContent>
    </StyledCard>
  );
};

export default Card;
