import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture {
    display: block;
}

body {
    line-height: 1;
    overscroll-behavior-y: none;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
}

a {
    color: inherit;
    text-decoration: none;
}

// Basic Styles
html {
    font-size: 10px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    box-sizing: border-box;

    ${({ theme }) => theme.mediaQuery.desktop(`font-size: 0.6vw`)};
    ${({ theme }) => theme.mediaQuery.wide(`font-size: 0.55vw`)};
    ${({ theme }) => theme.mediaQuery.ultraWide(`font-size: 0.4vw`)};

    *, *:before, *:after {
       box-sizing: border-box;
    }
}

body {
    display: block !important;
    ${({ theme }) => theme.typo("bodyBig")};
}

#smooth-content {
    background: #000;
}

main {
    overflow: hidden;
}

.pin-spacer {
  position: relative;
  z-index: 2 !important;
}


// WP MCS STYLES
.mce-content-body {
  padding: 1rem;
  margin: 0;

  p {

  }

  > * {
      margin: 0;
      margin-bottom: 1.6rem !important;
  }
  
  h1, h2, h3, h4, h5, h6 {
      font-size: inherit;
      line-height: normal;
      margin: 0;
      font-weight: inherit;
  }
}

.t-headline {
    ${({ theme }) => theme.typo("headline")};
}

.t-title {
    ${({ theme }) => theme.typo("title")};
}

.t-titlesmall {
    ${({ theme }) => theme.typo("titleSmall")};
}

.t-caption {
    color: #AFAFAF;
    text-transform: uppercase;
    ${({ theme }) => theme.typo("caption")};
    display: block;
    margin-bottom: -3rem;
}
// WP MCS STYLES END

`;
export default GlobalStyles;
