import React, { useEffect, useRef } from "react";
import { StyledAnimateSplitFadeIn } from "./styles.js";

const AnimateSplitFadeIn = ({ children }) => {
  const ref = useRef(null);

  useEffect(() => {
    const initFadeIn = async () => {
      const gsap = (await import("gsap")).gsap;
      const ScrollTrigger = (await import("gsap/dist/ScrollTrigger")).default;
      const SplitText = (await import("gsap/dist/SplitText")).default;

      gsap.registerPlugin(ScrollTrigger, SplitText);

      const mySplitText = new SplitText(ref.current, { type: "lines" }),
        lines = mySplitText.lines;

      gsap.set(lines, {
        opacity: 0,
        y: "5rem",
      });

      ScrollTrigger.batch(ref.current, {
        start: "bottom bottom",
        onEnter: (batch) => {
          gsap.to(lines, {
            force3D: true,
            duration: 1,
            opacity: 1,
            willChange: "transform, opacity",
            delay:
              window.scroller && window.scroller.scrollTrigger.progress < 0.1
                ? 1
                : 0.5,
            y: 0,
            stagger: 0.2,
          });
        },
      });
    };
    initFadeIn().catch(console.error);
  }, []);

  return (
    <StyledAnimateSplitFadeIn>
      <div ref={ref}>{children}</div>
    </StyledAnimateSplitFadeIn>
  );
};

export default AnimateSplitFadeIn;
