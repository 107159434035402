import { React, useState } from "react";
import { Media, AnimateFadeIn } from "../";
import {
  StyledQuote,
  StyledQuoteSlide,
  StyledQuotePagination,
  StyledQuoteThumb,
} from "./styles.js";

import { TitleSmall } from "../Typography";

import SwiperCore, { EffectCreative, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Quote = ({ list }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <StyledQuote>
      <AnimateFadeIn>
        <Swiper
          modules={[EffectCreative, Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          slidesPerView={"auto"}
          effect={"creative"}
          creativeEffect={{
            progressMultiplier: 1,
            limitProgress: 2,
            prev: {
              scale: 0.8,
              opacity: 0,
            },
            next: {
              scale: 0.8,
              opacity: 0,
            },
          }}
        >
          {list.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <StyledQuoteSlide>
                  {item.text && <TitleSmall>{item.text}</TitleSmall>}
                  {item.name && (
                    <strong>
                      {item.name}
                    </strong>
                  )}
                  {item.role && (
                    <span>{item.role}</span>
                  )}
                </StyledQuoteSlide>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <StyledQuotePagination>
          <Swiper
            slidesPerView={"auto"}
            onSwiper={setThumbsSwiper}
            modules={[Thumbs]}
            watchSlidesProgress
          >
            {list.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <StyledQuoteThumb>
                    <Media {...item} />
                  </StyledQuoteThumb>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </StyledQuotePagination>
      </AnimateFadeIn>
    </StyledQuote>
  );
};

export default Quote;
