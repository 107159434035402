export default {
  siteColumns: 12,
  ease: "ease",
  timing: {
    fast: "0.2s",
    normal: "0.5s",
    slow: "1s",
    verySlow: "5s",
  },
  margins: {
    default: "3.2rem",
    large: "5.2rem",
  },
  colors: {
    accent: "#0082FF",
    black: "#131212",
    grey100: "#212121",
    grey80: "#373737",
    grey60: "#555555",
    grey40: "#737373",
    grey20: "#919191",
    white: "#FFFFFF",
    white100: "#FFFFFF",
    white80: "#F5F5F5",
    white60: "#EBEBEB",
    white40: "#CDCDCD",
    white20: "#AFAFAF",
  },

  typography: {
    display: {
      "font-size": 30,
      "line-height": 44,
      "font-weight": 300,
      mq: {
        tablet: {
          "font-size": 36,
          "line-height": 56,
        },
        desktop: {
          "font-size": 72,
          "line-height": 104,
        },
      },
    },
    headline: {
      "font-size": 32,
      "line-height": 40,
      mq: {
        desktop: {
          "font-size": 56,
          "line-height": 78,
        },
      },
    },
    title: {
      "font-size": 30,
      "line-height": 38,
      mq: {
        desktop: {
          "font-size": 48,
          "line-height": 72,
        },
      },
    },
    titleSmall: {
      "font-size": 24,
      "line-height": 32,
      mq: {
        desktop: {
          "font-size": 40,
          "line-height": 54,
        },
      },
    },
    subHeader: {
      "font-size": 24,
      "line-height": 36,
      mq: {
        desktop: {
          "font-size": 30,
          "line-height": 44,
        },
      },
    },
    subHeaderSmall: {
      "font-size": 20,
      "line-height": 32,
      mq: {
        desktop: {
          "font-size": 24,
          "line-height": 36,
        },
      },
    },
    bodyBig: {
      "font-size": 20,
      "line-height": 30,
      "letter-spacing": "2%",
      mq: {
        desktop: {
          "font-size": 24,
          "line-height": 40,
        },
      },
    },
    body: {
      "font-size": 14,
      "line-height": 20,
      mq: {
        desktop: {
          "font-size": 16,
          "line-height": 26,
        },
      },
    },
    small: {
      "font-size": 14,
      "line-height": 22,
      mq: {
        desktop: {
          "font-size": 14,
          "line-height": 22,
        },
      },
    },
    caption: {
      "font-size": 16,
      "line-height": 21,
      "font-weight": 700,
    },
    captionBig: {
      "font-size": 20,
      "line-height": 27,
      "font-weight": 700,
    },
  },
  theme: {
    dark: {
      backgroundColor: "#131212",
      color: "#FFFFFF",
    },
    light: {
      backgroundColor: "#FFFFFF",
      color: "#373737",
    },
  },
  breakpoints: {
    phablet: 430,
    tablet: 768,
    desktop: 1024,
    large: 1440,
    wide: 1680,
    ultraWide: 1920,
  },
};
