import React from "react";
import { StyledRichtext } from "./styles.js";

const Richtext = ({ children }) => {
  const injectHtml = (children) => {
    return { __html: children };
  };

  return (
    <StyledRichtext data-richtext-wrapper>
      <div dangerouslySetInnerHTML={injectHtml(children)} />
    </StyledRichtext>
  );
};

export default Richtext;
