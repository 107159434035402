const EditorialCards = ({ title, text, cta, animoji, cards }) => {
  const normalizedData = {
    title: title,
    text: text,
    cta: cta,
    animoji: animoji,
    cards: cards,
  };

  return normalizedData;
};

export default EditorialCards;
