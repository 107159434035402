import React from "react";
import { Card } from "../";
import { StyledFeaturedCases } from "./styles.js";

const FeaturedCases = ({ cases }) => {
  return (
    <section>
      {cases && (
        <StyledFeaturedCases>
          {cases.map((item, i) => {
            return (
              <div key={i}>
                <Card {...item} />
              </div>
            );
          })}
        </StyledFeaturedCases>
      )}
    </section>
  );
};

export default FeaturedCases;
