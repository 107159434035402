import styled, { css } from "styled-components";

export const StyledFilter = styled.div`
  display: inline-block;
  position: relative;

  button {
    position: relative;
    appearence: none;
    -webkit-appearence: none;
    ${({ theme }) => theme.typo("display")};
    background: none;
    white-space: nowrap;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: currentColor;
    text-transform: capitalize;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(150%, -10%);
    }

    .no-touch & {
      &:hover {
        color: ${({ theme }) => theme.color("accent")};
        &:after {
          transform-origin: top right;
          transform: scaleX(0);
        }
      }
    }

    &:after {
      content: "";
      transform: scaleX(1);
      transform-origin: top left;
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
        background 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      display: block;
      width: 100%;
      background: currentColor;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &[data-status="true"] {
      opacity: 0;
    }
  }

  > ul {
    position: fixed;
    top: 50%;
    left: 0;
    padding-left: calc(100vw / 12 * 1);
    padding-top: calc(100vw / 12 * 4);
    width: 100%;
    height: 100%;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateY(-50%);

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      position: absolute;
      padding: 0;
      width: auto;
      height: auto;
      left: 0;
    `)};

    > div {
      display: block;
      width: 200vw;
      height: 200vh;
      background-color: #000;
      position: fixed;
      top: -100vh;
      left: -100vw;
      opacity: 0.9;
      z-index: 0;
    }

    li {
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      position: relative;
      z-index: 1;
      opacity: 0;
      transform: translateY(-2rem);
      &[data-active="active"] {
        color: ${({ theme }) => theme.color("accent")};
      }
    }

    span {
      cursor: pointer;
      transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        color: ${({ theme }) => theme.color("accent")};
      }
    }

    &[data-status="true"] {
      opacity: 1;
      pointer-events: visible;

      li {
        transform: translateY(0);
        opacity: 1;
        &:nth-child(1) {
          transition-delay: 0.1s;
        }
        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.3s;
        }
        &:nth-child(4) {
          transition-delay: 0.4s;
        }
        &:nth-child(5) {
          transition-delay: 0.5s;
        }
        &:nth-child(6) {
          transition-delay: 0.6s;
        }
      }
    }
  }
`;

export const StyledIndustryFilters = styled.div`
  margin-top: calc(100vw / 12 * 7);
  width: 80vw;
  margin-bottom: calc(100vw / 12 * -0.5);

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-bottom: calc(100vw / 12 * -1);
    margin-top: calc(100vw / 12 * 1);
  `)};

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
    width: 60vw;
  `)};

  &[data-active-filter="true"] {
    opacity: 0.3;
    pointer-events: none;
  }

  [data-trigger-industry-wrapper] {
    display: flex;
  }

  [data-tag] {
    margin-left: 3rem;
    text-transform: capitalize;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.accent};
    display: flex;
    align-items: center;

    svg {
      rotate: 45deg;
      margin-left: 0.6rem;
      margin-top: 0.1rem;
    }
  }

  ul {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    pointer-events: none;
    position: absolute;

    &[data-is-open="true"] {
      opacity: 1;
      position: relative;
      pointer-events: visible;

      li {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  li {
    color: #afafaf;
    margin: 0 1.4rem 1.4rem 0;
    opacity: 0;
    transform: translateY(50%);
    transition: all 1s ease;

    span {
      cursor: pointer;
      transition: color 0.2s ease;
      &[data-active="true"] {
        color: ${({ theme }) => theme.colors.accent};
      }
      .no-touch & {
        &:hover {
          color: ${({ theme }) => theme.colors.accent};
        }
      }
    }
  }

  [data-trigger-industry] {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &[data-is-open="true"] {
      color: ${({ theme }) => theme.colors.accent};
      svg {
        rotate: 180deg;
      }
    }
    * {
      transition: color 0.2s ease;
    }
    .no-touch & {
      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
    svg {
      transition: rotate 0.3s ease;
      margin-left: 1rem;
    }
  }
`;

export const StyledAllWrapperList = styled.div`
  width: 100%;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.color("black")};
  &[data-status="true"] {
    filter: blur(2px);
    opacity: 1;
    pointer-events: none;
  }
`;

export const StyledListProjectsHero = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 51rem;
  /* align-items: center; */
  padding: ${({ theme }) => theme.col(1)};
  padding-top: ${({ theme }) => theme.col(4)};
  padding-bottom: ${({ theme }) => theme.col(1.5)};
  padding-right: ${({ theme }) => theme.col(2)};
  background: ${({ theme }) => theme.color("black")};
  color: ${({ theme }) => theme.color("white100")};

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding: calc(100vw/12*1);
    padding-top: calc(100vw/12*2);
    padding-bottom: calc(100vw/12*1.5);
    padding-right: calc(100vw/12*2);
  `)};

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
      padding-left: calc(100vw/12*2);
      padding-top: calc(100vw/12*2);
      padding-bottom: calc(100vw/12*1.5);
      padding-right: calc(100vw/12*2);
    `)};
`;

export const StyledListProjectsHeroTitle = styled.div`
  ${({ theme }) => theme.typo("display")};
  z-index: 1;
  position: relative;
  width: 70vw;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    width: 50vw;
  `)};

  h1 {
    display: inline;

    span {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  }

  button {
    font-family: "Manrope", sans-serif;
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      width: 50vw;
  `)};
`;

export const StyledListProjects = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${({ theme }) => theme.col(1)};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledListProjectsNormalList = styled.div`
  margin-top: ${({ theme }) => theme.col(1)};
  width: 100%;
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
    padding-right: calc(100vw/12*1);
    padding-left: calc(100vw/12*1);
  `)};
`;

export const StyledListProjectsFeaturedList = styled.div`
  display: flex;
  width: 100%;
  background-color: #000;
  flex-wrap: wrap;
`;

export const StyledListProjectsCard = styled.div`
  display: block;
  width: 100%;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      width: 50%;
  `)};

  > div {
    aspect-ratio: 375/470;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      aspect-ratio: 16/9;
    `)};
  }
`;

export const StyledListProjectsCardLine = styled.div`
  display: block;
  width: 100%;
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};
  padding-top: ${({ theme }) => theme.rem(32)};
  padding-bottom: ${({ theme }) => theme.rem(32)};
`;

export const StyledReelTrigger = styled.div`
  position: absolute;
  top: 30rem;
  width: calc(100vw / 12 * 5);
  right: calc(100vw / 12 * 1);
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transition: all 1s ease;
  &[data-status="true"] {
    opacity: 0.2;
    pointer-events: none;
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    top: 27rem;
    width: calc(100vw / 12 * 2);
    right: calc(100vw / 12 * 2);
  `)};

  .no-touch & {
    &:hover {
      > svg {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  > div {
    transform: scale(1.7);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      path {
        transform: scale(0.55) translate(0%, 0%) rotate(-165deg);
        transform-origin: 50% 50%;
        animation: yo-yo 10s infinite alternate;
      }
    }
  }

  @keyframes yo-yo {
    from {
      transform: scale(0.55) translate(0%, 0%) rotate(-165deg);
    }
    to {
      transform: scale(0.55) translate(0%, 0%) rotate(165deg);
    }
  }
`;

export const StyledHole = styled.div`
  color: #fff;
  padding: 4rem 5.6rem;
  width: 50%;
  background-color: #131212;
  display: none;
  position: relative;
  overflow: hidden;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    align-items: flex-end;
    display: flex;
  `)};
  [data-wrapper] {
    width: 66.6%;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }

  [data-thunder] {
    position: absolute;
    top: 10%;
    opacity: 0.2;
    right: 10%;
    width: 40%;
  }
`;
