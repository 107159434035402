import React, { useContext } from "react";
import {
  StyledEditorialGrid,
  StyledEditorialGridWrapper,
  StyledListItemElementWrapper,
  StyledEditorialGridSliderWrapper,
} from "./styles.js";

import { MouseContext } from "../../contexts/Mouse";

import { Richtext, AnimateFadeIn } from "../";

import { Swiper, SwiperSlide } from "swiper/react";

import { Headline, SubHeader, BodyBig, Body } from "../Typography/index.js";

const EditorialGrid = ({ slider, title, list }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);

  const renderSlider = (title, list) => {
    return (
      <StyledEditorialGrid
        onMouseOver={() => {
          cursorChangeHandler("drag");
          cursorChangeText("");
        }}
        onMouseLeave={() => {
          cursorChangeHandler("");
          cursorChangeText("");
        }}
      >
        <StyledEditorialGridSliderWrapper>
          {title && (
            <AnimateFadeIn>
              <Headline>{title}</Headline>
            </AnimateFadeIn>
          )}

          <AnimateFadeIn>
            <Swiper slidesPerView={"auto"}>
              {list.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    {item.title && (
                      <strong>
                        <BodyBig>{item.title}</BodyBig>
                      </strong>
                    )}
                    {item.text && (
                      <Body>
                        <Richtext>{item.text}</Richtext>
                      </Body>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </AnimateFadeIn>
        </StyledEditorialGridSliderWrapper>
      </StyledEditorialGrid>
    );
  };

  const renderGrid = (title, list) => {
    return (
      <StyledEditorialGrid>
        <StyledEditorialGridWrapper>
          <div>
            <Headline>
              <AnimateFadeIn>{title}</AnimateFadeIn>
            </Headline>
          </div>

          {list.map((item, i) => {
            return (
              <AnimateFadeIn delay={i * 0.2} key={i}>
                <StyledListItemElementWrapper>
                  <strong>
                    <SubHeader>{item.title}</SubHeader>
                  </strong>
                  <BodyBig>
                    <Richtext>{item.text}</Richtext>
                  </BodyBig>
                </StyledListItemElementWrapper>
              </AnimateFadeIn>
            );
          })}
        </StyledEditorialGridWrapper>
      </StyledEditorialGrid>
    );
  };

  return slider === true ? renderSlider(title, list) : renderGrid(title, list);
};

export default EditorialGrid;
