const FeaturedCareers = ({ list, cta, text }) => {
  const normalizedData = {
    list: list,
    cta: cta,
    text: text,
  };

  return normalizedData;
};

export default FeaturedCareers;
