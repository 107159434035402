import dynamic from "next/dynamic";
const SliderButton = dynamic(() =>
  import("@typeform/embed-react").then((mod) => mod.SliderButton)
);

import React, { useState } from "react";
import {
  StyledBannerThunder,
  StyledBannerThunderIcon,
  StyledBannerThunderTitle,
} from "./styles.js";

import { Title, StyledRegularLink } from "../Typography/index.js";

import {
  Icon,
  WrapperLink,
  AnimateFadeIn,
  EditorialWrapper,
  Cta,
  IframeModal,
} from "../index.js";

const BannerThunderNew = ({
  title,
  type,
  cta,
  label,
  form_url,
  form_id,
  typeformHiddenProps,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {form_url ? (
        <IframeModal isOpen={modalOpen} setIsOpen={setModalOpen}>
          <div data-wrapper-iframe>
            <iframe src={form_url} />
          </div>
        </IframeModal>
      ) : null}

      <AnimateFadeIn>
        <StyledBannerThunder>
          <EditorialWrapper>
            <StyledBannerThunderTitle data-subtitle>
              <Title>{title}</Title>
            </StyledBannerThunderTitle>

            {cta && (
              <div data-cta>
                <WrapperLink data={cta}>
                  <div>
                    <StyledRegularLink>
                      <div>
                        <Icon name="caret-right" />
                        <span>{cta.label}</span>
                        <Icon name="caret-right" />
                      </div>
                    </StyledRegularLink>
                  </div>
                </WrapperLink>
              </div>
            )}

            {type == "typeform" ? (
              <div data-cta>
                <SliderButton
                  id={form_id}
                  hidden={typeformHiddenProps}
                >
                  <Cta>{label}</Cta>
                </SliderButton>
              </div>
            ) : (
              form_url && (
                <div data-cta>
                  <button onClick={() => {
                    setModalOpen(true);
                  }}>
                    <div>
                      <StyledRegularLink>
                        <div>
                          <Icon name="caret-right" />
                          <span>{label}</span>
                          <Icon name="caret-right" />
                        </div>
                      </StyledRegularLink>
                    </div>
                  </button>
                </div>
              )
            )}
          </EditorialWrapper>

          <div>
            <StyledBannerThunderIcon>
              <div>
                <Icon name="pre-footer-bolt" />
              </div>
            </StyledBannerThunderIcon>
          </div>
        </StyledBannerThunder>
      </AnimateFadeIn>
    </>
  );
};

export default BannerThunderNew;
