import React, { createContext, useState } from "react";
import Router from "next/router";

export const ReelContext = createContext({
  reelType: "closed",
  reelChangeHandler: () => {},
});

const ReelProvider = (props) => {
  const [reelType, setReelType] = useState("closed");

  const reelChangeHandler = (reelType) => {
    setReelType(reelType);
  };

  Router.events.on('routeChangeStart', (url) => {
    setReelType('closed');
  });

  return (
    <ReelContext.Provider
      value={{
        reelType: reelType,
        reelChangeHandler: reelChangeHandler,
      }}
    >
      {props.children}
    </ReelContext.Provider>
  );
};

export default ReelProvider;
