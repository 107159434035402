import styled, { css } from "styled-components";

export const StyledEditorial = styled.div`
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};
  position: relative;
  z-index: 2;

  [data-cta] {
    white-space: nowrap;
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        display: flex;
    `)}

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
    padding-left: calc(100vw/12*2);
    padding-right: calc(100vw/12*2);
  `)}
`;

export const StyledEditorialCenteredWrapper = styled.div`
  text-align: center;
`;

export const StyledEditorialRichtext = styled.div``;

export const StyledEditorialWrapper = styled.div`
  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
        &[data-type="half"] {
          [data-richtext-wrapper] {
            > div {
              column-count: 2;
              column-gap: calc(100vw/12*1);
            }
          }
  
          [data-wrapper-cta-score] {
            margin-top: 3.2rem;
            padding-left: calc(50% + 100vw/12*0.5)
          }
        }
    `)}
  [data-wrapper-cta-score] {
    &:empty {
      display: none;
    }
  }
`;

export const StyledEditorialCaption = styled.div`
  text-transform: uppercase;
  color: #afafaf;
`;

export const StyledEditorialTitle = styled.div``;

export const StyledScore = styled.div`
  margin-top: 4.8rem;
  font-weight: 600;
  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
      display: flex;
  `)}
`;

export const StyledScoreBox = styled.div`
  display: inline-flex;
  position: relative;
  margin-top: 2rem;
  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
    margin-left: 2rem;
    margin-top: 0rem;
  `)}

  > div {
    display: inline-flex;

    &:last-of-type {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 1s ease;
      span {
        background: ${({ theme }) => theme.colors.accent};
      }
    }

    span {
      background: black;
      width: 2.5rem;
      height: 2.5rem;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const StyledCases = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    height: 4rem;
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;
    max-width: 8rem;
    cursor: pointer;

    ${({ theme, type }) =>
      theme.mediaQuery.tablet(`
      margin-right: 2rem;
      height: 5rem;
      margin-bottom: 2rem;
    `)}

    img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;
