import { useRouter } from "next/router";
import React, { useContext } from "react";
import { MouseContext } from "../../contexts/Mouse";
import {
  StyledMenu,
  StyledMenuMain,
  StyledMenuOthers,
  StyledMenuIcon,
  StyledMenuWrapper,
} from "./styles.js";
import Link from "next/link";
import { Headline, TitleSmall } from "../Typography/index.js";
import Icon from "../Icon/index.js";
import { ReelContext } from "../../contexts/Reel.js";

const Menu = ({ mainMenuData, isMenuOpen = false }) => {
  const { locale, locales } = useRouter();

  const { reelChangeHandler } = useContext(ReelContext);
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);

  const innerLinks = [];
  const outerLinks = [];

  if (mainMenuData && mainMenuData.links) {
    mainMenuData.links.map((el, i) => {
      if (el.internal) {
        innerLinks.push(el);
      } else {
        outerLinks.push(el);
      }
    });
  }

  return (
    <StyledMenu data-is-menu-open={isMenuOpen}>
      <StyledMenuWrapper>
        {innerLinks && (
          <StyledMenuMain>
            <ul>
              {innerLinks.map((item, i) => {
                return (
                  <li key={i}>
                    <Link href={item.url} passHref scroll={false}>
                      <a title={item.title}>
                        <Headline
                          onMouseOver={() => {
                            cursorChangeHandler("hide");
                            cursorChangeText("");
                          }}
                          onMouseLeave={() => {
                            cursorChangeHandler("");
                            cursorChangeText("");
                          }}
                        >
                          {item.title}
                        </Headline>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </StyledMenuMain>
        )}
        {outerLinks && (
          <StyledMenuOthers>
            <ul>
              {outerLinks.map((item, i) => {
                return (
                  <li key={i}>
                    <Link
                      href={item.url}
                      passHref
                      onMouseOver={() => {
                        cursorChangeHandler("hide");
                        cursorChangeText("");
                      }}
                      onMouseLeave={() => {
                        cursorChangeHandler("");
                        cursorChangeText("");
                      }}
                    >
                      <a target="_blank" title={item.title}>
                        <TitleSmall>{item.title}</TitleSmall>
                      </a>
                    </Link>
                  </li>
                );
              })}
              <li data-menu-showreel>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    reelChangeHandler("active");
                  }}
                  onMouseOver={() => {
                    cursorChangeHandler("hide");
                    cursorChangeText("");
                  }}
                  onMouseLeave={() => {
                    cursorChangeHandler("");
                    cursorChangeText("");
                  }}
                >
                  <TitleSmall>Showreel</TitleSmall>
                  <svg width="19" height="26" viewBox="0 0 19 26">
                    <path
                      d="M1.33203 25V1L17.332 13L1.33203 25Z"
                      stroke="#CDCDCD"
                    />
                  </svg>
                </span>
              </li>
              <li data-switch-lang>
                {locales.map((lang, i) => {
                  return (
                    <div key={i}>
                      <a
                        href={lang == "it" ? "/" : "/en"}
                        data-disabled={lang == locale ? true : false}
                        title={lang == "it" ? "cambia lingua" : "switch language"}
                      >
                        {lang}
                      </a>
                    </div>
                  );
                })}
              </li>
            </ul>
          </StyledMenuOthers>
        )}
      </StyledMenuWrapper>
      <StyledMenuIcon>
        <Icon name="pre-footer-bolt" />
      </StyledMenuIcon>
    </StyledMenu>
  );
};

export default Menu;
