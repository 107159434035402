import styled, { css } from "styled-components";

export const StyledEditorialMedia = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};

  [data-title] {
    margin-bottom: 3.2rem;
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    display: flex;
    &[data-alignment="left"] {
        flex-direction: row;
        > div {
        &:first-child {
            padding-right: calc(100vw / 12 * 0.5);
        }
        }
    }

    &[data-alignment="right"] {
        flex-direction: row-reverse;
        > div {
        &:first-child {
            padding-left: calc(100vw / 12 * 0.5);
        }
        }
    }
  `)};

  ${({ theme }) => theme.mediaQuery.ultraWide(`
    padding-left: calc(100vw/12*2);
    padding-right: calc(100vw/12*2);
  `)};

  > div {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
        width: 50%;
    `)};
  }
`;

export const StyledEditorialMediaContent = styled.div`
  [data-editorial-wrapper] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`;

export const StyledEditorialMediaImage = styled.div`
  > div {
    aspect-ratio: 327/355;
    margin-top: 3.2rem;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      margin-top: 0;
      width: 100%;
    `)};
  }
`;
