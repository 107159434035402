import React, { useContext } from "react";
import { StyledListItem, StyledListItemElement } from "./styles.js";

import { BodyBig } from "../Typography/index.js";
import WrapperLink from "../WrapperLink/index.js";

import AnimateFadeIn from "../AnimateFadeIn/index.js";

import { MouseContext } from "../../contexts/Mouse";

const ListItem = ({ list }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  return (
    <StyledListItem>
      {list.map((item, i) => {
        return (
          <li
            key={i}
            onMouseOver={() => {
              cursorChangeHandler("hide");
              cursorChangeText("");
            }}
            onMouseLeave={() => {
              cursorChangeHandler("");
              cursorChangeText("");
            }}
          >
            <AnimateFadeIn>
              <WrapperLink data={item.cta}>
                <StyledListItemElement>
                  <div>
                    <small>
                      {item.pretitle}

                      {item.area && (
                        <div className="area">
                          {item.area.map((area, j) => {
                            return <span key={j}>{area}</span>;
                          })}
                        </div>
                      )}

                      {item.location && (
                        <div>
                          {item.location.map((office, h) => {
                            return <span key={h}>{office}</span>;
                          })}
                        </div>
                      )}
                    </small>
                    {item.position && <strong>{item.position}</strong>}
                    {item.description && <BodyBig>{item.description}</BodyBig>}
                  </div>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                      d="M6.59961 25.4004L24.5996 7.40039"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.4004 7.40039H24.5997L24.5997 16.5997"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </StyledListItemElement>
              </WrapperLink>
            </AnimateFadeIn>
          </li>
        );
      })}
    </StyledListItem>
  );
};

export default ListItem;
