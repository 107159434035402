import { MouseContext } from "../../contexts/Mouse";
import Link from "next/link.js";
import React, { useContext } from "react";
import {
  StyledEditorialCaption,
  StyledEditorialTwoCols,
  StyledEditorialWrapper,
  StyledEditorialRichtext,
  StyledScore,
  StyledScoreBox,
  StyledCases,
} from "./styles.js";
import {
  Richtext,
  AnimateFadeIn,
  WrapperLink,
  EditorialWrapper,
  Cta,
} from "../";

import { Body, Headline, Caption } from "../Typography";

const EditorialTwoCols = (props) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);

  const stars = [0, 1, 2, 3, 4];

  const renderPartialRichtext = (text) => {
    return (
      <StyledEditorialRichtext type="full" data-richtext>
        <AnimateFadeIn>
          <Richtext>{text}</Richtext>
        </AnimateFadeIn>
      </StyledEditorialRichtext>
    );
  };

  const renderPartialCta = (cta) => {
    return (
      <div data-cta>
        <AnimateFadeIn>
          <WrapperLink data={cta}>
            <Cta>{cta.label}</Cta>
          </WrapperLink>
        </AnimateFadeIn>
      </div>
    );
  };

  const renderPartialScore = (score) => {
    return (
      <AnimateFadeIn>
        <StyledScore>
          <div>
            <Body>
              {score.text} <span>{score.vote}</span>
            </Body>
          </div>
          <StyledScoreBox>
            <div>
              {stars.map((e, i) => {
                return (
                  <span key={i}>
                    <svg width="17" height="16" viewBox="0 0 17 16">
                      <path
                        d="M7.60326 0.816986C7.97008 0.07374 9.02992 0.07374 9.39674 0.816986L11.0593 4.18573C11.205 4.48087 11.4865 4.68544 11.8122 4.73277L15.5299 5.27297C16.3501 5.39216 16.6776 6.40013 16.0841 6.97866L13.394 9.60086C13.1583 9.8306 13.0508 10.1616 13.1064 10.486L13.7414 14.1886C13.8815 15.0055 13.0241 15.6285 12.2905 15.2428L8.96534 13.4946C8.67402 13.3415 8.32598 13.3415 8.03466 13.4946L4.70951 15.2428C3.97588 15.6285 3.11845 15.0055 3.25856 14.1886L3.89361 10.486C3.94925 10.1616 3.8417 9.8306 3.60601 9.60086L0.915912 6.97866C0.322395 6.40013 0.649905 5.39216 1.47013 5.27297L5.18775 4.73277C5.51346 4.68544 5.79503 4.48087 5.94069 4.18573L7.60326 0.816986Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                );
              })}
            </div>
            <div
              data-scroll
              style={{
                clipPath:
                  "polygon(0% 0%, " +
                  (score.vote / 5) * 100 +
                  "% 0%, " +
                  (score.vote / 5) * 100 +
                  "% 100%, 0% 100%)",
              }}
            >
              {stars.map((e, i) => {
                return (
                  <span key={i}>
                    <svg width="17" height="16" viewBox="0 0 17 16">
                      <path
                        d="M7.60326 0.816986C7.97008 0.07374 9.02992 0.07374 9.39674 0.816986L11.0593 4.18573C11.205 4.48087 11.4865 4.68544 11.8122 4.73277L15.5299 5.27297C16.3501 5.39216 16.6776 6.40013 16.0841 6.97866L13.394 9.60086C13.1583 9.8306 13.0508 10.1616 13.1064 10.486L13.7414 14.1886C13.8815 15.0055 13.0241 15.6285 12.2905 15.2428L8.96534 13.4946C8.67402 13.3415 8.32598 13.3415 8.03466 13.4946L4.70951 15.2428C3.97588 15.6285 3.11845 15.0055 3.25856 14.1886L3.89361 10.486C3.94925 10.1616 3.8417 9.8306 3.60601 9.60086L0.915912 6.97866C0.322395 6.40013 0.649905 5.39216 1.47013 5.27297L5.18775 4.73277C5.51346 4.68544 5.79503 4.48087 5.94069 4.18573L7.60326 0.816986Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                );
              })}
            </div>
          </StyledScoreBox>
        </StyledScore>
      </AnimateFadeIn>
    );
  };

  const renderPartialCases = (cases) => {
    return (
      <AnimateFadeIn>
        <StyledCases>
          {cases.map((el, i) => {
            return (
              <div
                key={i}
                onMouseOver={() => {
                  cursorChangeHandler("hide");
                  cursorChangeText("");
                }}
                onMouseLeave={() => {
                  cursorChangeHandler("");
                  cursorChangeText("");
                }}
              >
                <Link href={el.url ? el.url : "https://google.com"}>
                  <img src={el.logo.url} />
                </Link>
              </div>
            );
          })}
        </StyledCases>
      </AnimateFadeIn>
    );
  };

  const renderHalfLayout = ({ eyelet, title, text, cta, score, cases }) => {
    return (
      <StyledEditorialTwoCols>
        <StyledEditorialWrapper>
          <EditorialWrapper data-text-wrapper>
            <div data-title>
              {eyelet && (
                <StyledEditorialCaption data-eyelet>
                  <AnimateFadeIn>
                    <Caption>
                      <h3>{eyelet}</h3>
                    </Caption>
                  </AnimateFadeIn>
                </StyledEditorialCaption>
              )}
              {title && (
                <div data-title>
                  <AnimateFadeIn>
                    <Headline>
                      <h2>{title}</h2>
                    </Headline>
                  </AnimateFadeIn>
                </div>
              )}
            </div>

            <div data-richtext>
              {text && renderPartialRichtext(text)}
              {cta && cta.url && renderPartialCta(cta)}
              {score && score.text && renderPartialScore(score)}
              {cases && renderPartialCases(cases)}
            </div>
          </EditorialWrapper>
        </StyledEditorialWrapper>
      </StyledEditorialTwoCols>
    );
  };

  return renderHalfLayout(props);
};

export default EditorialTwoCols;
