import styled, { css } from "styled-components";

export const StyledEditorialTwoCols = styled.div`
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};
  position: relative;
  z-index: 2;

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    display: flex;
  `)}
`;

export const StyledEditorial = styled.div``;
export const StyledEditorialWrapper = styled.div`
  [data-editorial-wrapper] {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: flex;
      > div {
        &:first-of-type {
          min-width: calc(100vw / 12 * 4);
          padding-right: calc(100vw / 12 * 0.5);
        }
      }
      [data-richtext] {
        padding-left: calc(100vw / 12 * 0.5);
        [data-richtext] {
          padding-left: 0;
        }
      }
    `)}
  }
`;
export const StyledEditorialRichtext = styled.div``;

export const StyledScore = styled.div`
  margin-top: 4.8rem;
  font-weight: 600;
  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
      display: flex;
  `)}
`;

export const StyledScoreBox = styled.div`
  display: inline-flex;
  position: relative;
  margin-top: 2rem;
  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
    margin-left: 2rem;
    margin-top: 0rem;
  `)}

  > div {
    display: inline-flex;

    &:last-of-type {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 1s ease;
      span {
        background: ${({ theme }) => theme.colors.accent};
      }
    }

    span {
      background: black;
      width: 2.5rem;
      height: 2.5rem;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const StyledEditorialCaption = styled.div`
  text-transform: uppercase;
  color: #afafaf;
`;

export const StyledCases = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;

  > div {
    height: 4rem;
    max-width: 8rem;
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;
    cursor: pointer;
    transition: transform 0.2s ease;

    .no-touch & {
      &:hover {
        transform: scale(0.9);
      }
    }

    ${({ theme, type }) =>
      theme.mediaQuery.tablet(`
      margin-right: 5rem;
      height: 5rem;
      margin-bottom: 2rem;
      max-width: 14rem;
    `)}

    &:last-of-type {
      margin-right: 0;
    }

    img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;
