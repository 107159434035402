const HeroJobPosition = ({ title, text, cta, form_id, badges: _badges }) => {
  const ctaHiddenProps = {}
  const badges = Array.isArray(_badges) ? _badges : []

  if (cta && cta.form_id && cta.label.length > 2) {
    Object.assign(ctaHiddenProps, {
      position: cta.trakstar_job_id,
    })
  }

  const normalizedData = {
    title,
    text,
    cta,
    form_id,
    ctaHiddenProps,
    badges,
  }

  return normalizedData
}

export default HeroJobPosition
