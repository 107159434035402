import styled, { css } from "styled-components";

export const StyledPeopleGallery = styled.div`
  padding: 0 ${({ theme }) => theme.col(1)};

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  [data-header] {
    margin-bottom: ${({ theme }) => theme.col(1)};
  }
  [data-subtitle] {
    margin-top: 1.6rem;
  }

  [data-cta] {
    margin-top: 3.2rem;
  }

  .swiper {
    width: 100%;
  }

  [data-media] {
    aspect-ratio: 360/482;
    position: relative;
    margin-bottom: 3.2rem;

    a {
      position: absolute;
      background: ${({ theme }) => theme.colors.white};
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      bottom: 2.4rem;
      right: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.2s ease;
      path {
        transition: background-color 0.2s ease;
      }
      .no-touch & {
        &:hover {
          background-color: ${({ theme }) => theme.colors.accent};
          path {
            fill: ${({ theme }) => theme.colors.white};
          }
        }
      }
    }
  }

  [data-wrapper-expand="true"] {
    ~ [data-expand] {
      visibility: visible;
    }
  }

  [data-expand] {
    visibility: hidden;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.colors.accent};
    font-family: "Manrope";
    font-weight: 600;
    margin-top: 0.8rem;
    cursor: pointer;
  }

  [data-body] {
    overflow: hidden;
    color: #737373;
    margin-top: 2.4rem;
    max-height: 16rem;
    min-height: 16rem;
    will-change: max-height;
    transition: max-height 1s ease;
    position: relative;
    &[data-wrapper-expand="true"] {
        &:after {
          transition: opacity 0.2s ease;
          content: "";
          display: block;
          width: 100%;
          height: 10rem;
          position: absolute;
          bottom: 0;
          left: 0;
          [data-theme="light"] & {
            background-image: linear-gradient(0deg, #fff, #ffffff00);
          }
          [data-theme="grey"] & {
            background-image: linear-gradient(0deg, #ebebeb, #ebebeb00);
          }
          [data-theme="dark"] & {
            background-image: linear-gradient(0deg, #131212, #13121200);
          }
        }
    }
    &[data-is-open="true"] {
      max-height: 40em;
      &:after {
        opacity: 0;
      }
    }
  }

  [data-role] {
    text-transform: uppercase;
    color: #737373;
    margin-bottom: 0.4rem;
    > div {
      font-weight: lighter;
    }
  }

  [data-grid] {
    display: flex;
    left: calc(100vw / 12 * -1);
    width: 100vw;
    position: relative;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      left: 0;
      width: 100%
    `)};

    .swiper-wrapper {
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
        width: calc(100% + 6rem);
        gap: 6rem;
        flex-wrap: wrap;
      `)};
    }

    .swiper-slide {
      height: auto;
      width: 80%;
      padding-left: 2.4rem;
      &:last-of-type {
        margin-right: 2.4rem;
      }

      ${({ theme }) =>
        theme.mediaQuery.tablet(`
        padding-left: 0 !important;
        margin-right: 0 !important;
        width: calc(33.3% - 6rem);
      `)};
    }
  }
`;
