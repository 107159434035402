import React, { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";

import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledHeaderIcon,
  StyledHeaderHamburger,
} from "./styles.js";
import { Icon, Menu } from "../";
import Link from "next/link";

import { MouseContext } from "../../contexts/Mouse.js";

const Header = ({ mainMenuData }) => {
  const router = useRouter();

  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  const [isMenuOpen, setMenuState] = useState(false);

  useEffect(() => {
    const handleStart = (url) => {
      window.scroller && window.scroller.paused(true);
      cursorChangeHandler("");
      cursorChangeText("");
    };
    const handleEnd = (url) => {
      setTimeout(() => {
        setMenuState(false);
        window.scroller && window.scroller.paused(false);
      }, 600);

      setTimeout(() => {
        if (!window.scroller) {
          window.scrollTo(0, 0);
        }
      }, 800);

      setTimeout(() => {
        window.scroller && window.scroller.scrollTop(0);
      }, 1100);
    };
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleEnd);
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleEnd);
    };
  }, [router]);

  const handleTrigger = (e) => {
    setMenuState(!isMenuOpen);

    if (document.querySelector("body").classList.contains("no-touch")) {
      if (isMenuOpen === true) {
        window.scroller && window.scroller.paused(false);
      }

      if (isMenuOpen === false) {
        window.scroller && window.scroller.paused(true);
      }
    }
  };

  return (
    <>
      <StyledHeader
        data-header-type="top"
        exit={{
          opacity: 0,
        }}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
      >
        <StyledHeaderWrapper>
          <StyledHeaderIcon>
            <Link href="/" scroll={false}>
              <div>
                <Icon name="logoBig" />
              </div>
            </Link>
          </StyledHeaderIcon>

          <StyledHeaderHamburger
            data-is-menu-open={isMenuOpen}
            onClick={handleTrigger}
            onMouseOver={() => {
              cursorChangeHandler("hide");
            }}
            onMouseLeave={() => {
              cursorChangeHandler("");
            }}
          >
            <div />
            <div />
          </StyledHeaderHamburger>
        </StyledHeaderWrapper>
      </StyledHeader>

      {mainMenuData && (
        <Menu mainMenuData={mainMenuData} isMenuOpen={isMenuOpen} />
      )}
    </>
  );
};

export default Header;
