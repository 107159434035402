import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const StyledPreloader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
`;

export const StyledPreloaderOut = styled(motion.div)`
  z-index: 999999;
  background: #000;
  will-change: transform;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
`;