const GridLogo = ({ title, list }) => {
  const normalizedData = {
    title,
    list,
  };

  return normalizedData;
};

export default GridLogo;
