import React from "react";
import {
  Richtext,
  Icon,
  AnimateFadeIn,
  EditorialWrapper,
  WrapperLink,
} from "../";
import {
  StyledEditorialData,
  StyledEditorialDataContent,
  StyledEditorialDataData,
} from "./styles.js";
import { StyledRegularLink, Display } from "../Typography";

const EditorialData = ({ text, list, cta }) => {
  return (
    <StyledEditorialData>
      <StyledEditorialDataContent>
        <EditorialWrapper>
            <AnimateFadeIn>
              <Richtext>{text}</Richtext>
            </AnimateFadeIn>
          {cta.label && (
            <div data-cta>
              <AnimateFadeIn>
                <WrapperLink data={cta}>
                  <StyledRegularLink>
                    <div>
                      <Icon name="caret-right" />
                      <span>{cta.label}</span>
                      <Icon name="caret-right" />
                    </div>
                  </StyledRegularLink>
                </WrapperLink>
              </AnimateFadeIn>
            </div>
          )}
        </EditorialWrapper>
      </StyledEditorialDataContent>

      <StyledEditorialDataData
        className={list.length % 2 == 0 ? "-even" : "-odd"}
      >
        {list.map((el, i) => {
          return (
            <AnimateFadeIn delay={i * 0.2} key={i}>
              <Display>
                <strong>{el.key}</strong>
              </Display>
              <span>{el.value}</span>
            </AnimateFadeIn>
          );
        })}
      </StyledEditorialDataData>
    </StyledEditorialData>
  );
};

export default EditorialData;
