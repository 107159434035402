import { useRouter } from "next/router";
import React, { useState, useContext, useRef } from "react";
import {
  StyledCaseCarousel,
  StyledCaseCarouselSlide,
  StyledCaseCarouselWrapper,
  StyledCaseCarouselHeader,
  StyledCaseCarouselContent,
  StyledCaseCarouselPagination,
  StyledCaseCarouselSlideMedia,
  StyledCaseCarouselText,
} from "./styles.js";

import { Headline, CaptionBig } from "../Typography/index.js";

import SwiperCore, { EffectCreative, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Media, WrapperLink } from "../";

import { MouseContext } from "../../contexts/Mouse";

SwiperCore.use([Navigation]);

const CaseCarousel = ({ list, title, cta }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);

  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);

  const arrowPrev = useRef(null);
  const arrowNext = useRef(null);

  const { locale } = useRouter();

  return (
    <StyledCaseCarousel>
      <StyledCaseCarouselHeader>
        <StyledCaseCarouselText>
          <div>{title}</div>
        </StyledCaseCarouselText>

        <div>
          <StyledCaseCarouselPagination>
            <span>{currentSlideIndex}</span>
            <svg width="44" height="1" viewBox="0 0 44 1">
              <rect width="44" height="1" fill="#EBEBEB" />
            </svg>
            {list.length}
          </StyledCaseCarouselPagination>
        </div>
      </StyledCaseCarouselHeader>
      <StyledCaseCarouselWrapper>
        <span
          ref={arrowPrev}
          onMouseOver={() => {
            cursorChangeHandler("arrow-slide-prev");
            cursorChangeText("");
          }}
          onMouseLeave={() => {
            cursorChangeHandler("");
            cursorChangeText("");
          }}
        ></span>
        <Swiper
          modules={[EffectCreative, Navigation]}
          navigation={{
            nextEl: arrowNext.current,
            prevEl: arrowPrev.current,
          }}
          slidesPerView={"auto"}
          effect={"creative"}
          onSlideChange={(slide) => setCurrentSlideIndex(slide.activeIndex + 1)}
          onTouchMove={() => {
            cursorChangeHandler("drag");
            cursorChangeText("");
            // window.scroller.paused(true)
          }}
          onTouchEnd={() => {
            cursorChangeHandler("hovered");
            cursorChangeText(
              locale == "en" ? "Explore the project" : "Esplora il progetto"
            );
          }}
          onClick={() => {
            cursorChangeHandler("");
            cursorChangeText("");
          }}
          followFinger={false}
          creativeEffect={{
            progressMultiplier: 1,
            limitProgress: 2,
            prev: {
              translate: ["-87.5%", 0, 0],
              scale: 1,
              origin: "center right",
            },
            next: {
              translate: ["87.5%", 0, 0],
              scale: 1,
              origin: "center left",
            },
          }}
          breakpoints={{
            768: {
              followFinger: true,
              creativeEffect: {
                progressMultiplier: 1,
                limitProgress: 2,
                prev: {
                  translate: ["-82%", 0, 0],
                  scale: 0.7,
                  opacity: 1,
                  origin: "center right",
                },
                next: {
                  translate: ["82%", 0, 0],
                  scale: 0.7,
                  opacity: 1,
                  origin: "center left",
                },
              },
            },
          }}
        >
          {list.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <WrapperLink data={item}>
                  <StyledCaseCarouselSlide
                    onMouseOver={() => {
                      cursorChangeHandler("hovered");
                      cursorChangeText(
                        locale == "en"
                          ? "Explore the project"
                          : "Esplora il progetto"
                      );
                    }}
                    onMouseLeave={() => {
                      cursorChangeHandler("");
                      cursorChangeText("");
                    }}
                  >
                    <StyledCaseCarouselSlideMedia>
                      <Media {...item.media} />
                    </StyledCaseCarouselSlideMedia>
                    <StyledCaseCarouselContent>
                      {item.customer && (
                        <div data-h2>
                          <CaptionBig>{item.customer.toUpperCase()}</CaptionBig>
                        </div>
                      )}
                      {item.title && (
                        <div data-h1>
                          <Headline>{item.title}</Headline>
                        </div>
                      )}
                      <div data-h3>
                        {item.unit.map((tax, i) => {
                          return <span key={i}>{tax} </span>;
                        })}
                      </div>
                    </StyledCaseCarouselContent>
                  </StyledCaseCarouselSlide>
                </WrapperLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <span
          ref={arrowNext}
          onMouseOver={() => {
            cursorChangeHandler("arrow-slide-next");
            cursorChangeText("");
          }}
          onMouseLeave={() => {
            cursorChangeHandler("");
            cursorChangeText("");
          }}
        ></span>
      </StyledCaseCarouselWrapper>
    </StyledCaseCarousel>
  );
};

export default CaseCarousel;
