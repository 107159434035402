import styled, { css } from "styled-components";

export const StyledHeroProject = styled.div`
  height: 80vh;
  position: relative;
  margin-bottom: ${({ theme }) => theme.col(-2)};
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-bottom: ${theme.col(-1)};
  `)}

  &:after {
    content: "";
    display: block;
    left: 0;
    bottom: ${({ theme }) => theme.col(-1)};
    position: absolute;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0deg, #131212, transparent);
    height: 100%;
  }
`;

export const StyledHeroProjectMedia = styled.div`
  position: relative;
  height: 100%;
  will-change: transform;
  pointer-events: none;

  > div {
    height: 130%;
    margin-top: -2%;
  }
`;

export const StyledHeroProjectContent = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.col(2)};
  left: ${({ theme }) => theme.col(1)};
  z-index: 2;
  width: 75%;

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        left: calc(100vw/12*2);
      `)}

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    bottom: ${theme.col(0.5)};
    width: 80%;
  `)}
`;
