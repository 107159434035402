import React, { createContext, useEffect } from "react";

export const ScrollContext = createContext({});

const ScrollProvider = ({ children }) => {
  let scroller = null;

  useEffect(() => {
    const body = document.querySelector("body");
    const header = document.querySelector("[data-header-type]");

    const initScroll = async () => {
      if (body.classList.contains("no-touch")) {
        const gsap = (await import("gsap")).default;
        const ScrollSmoother = (await import("gsap/dist/ScrollSmoother"))
          .default;
        const ScrollTrigger = (await import("gsap/dist/ScrollTrigger")).default;
        gsap.registerPlugin(ScrollSmoother, ScrollTrigger);
        scroller = ScrollSmoother.create({
          smooth: 0.5, // how long (in seconds) it takes to "catch up" to the native scroll position
          effects: true, // looks for data-speed and data-lag attributes on elements
          normalizeScroll: true, // prevents showing/hiding of address bar, works around iOS Safari jitter, etc.
          smoothTouch: false, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
          normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
          ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
          onUpdate: () => {
            if (scroller && scroller.scrollTrigger) {
              let direction = scroller.scrollTrigger.direction;
              let progress = scroller.scrollTrigger.progress;
              if (header) {
                if (progress < 0.01) {
                  header.setAttribute("data-header-type", "top");
                } else {
                  if (direction > 0) {
                    header.setAttribute("data-header-type", "hidden");
                  } else {
                    header.setAttribute("data-header-type", "visible");
                  }
                }
              }
            }
          },
        });
        window.scroller = scroller;
      }
    };
    setTimeout(() => {
      initScroll().catch(console.error);
    }, 100);
  }, []);

  return (
    <ScrollContext.Provider
      value={{
        scroller: scroller,
      }}
    >
      <div id="smooth-wrapper">
        <div id="smooth-content">{children}</div>
      </div>
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
