import React, { useEffect, useState } from "react";
import { StyledPageBackground } from "./styles.js";

// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const PageBackground = () => {
  const [variant, setVariant] = useState("light");

  useEffect(() => {
    const elements = document.querySelectorAll("[data-theme]");

    // elements.forEach((elem) => {
    //   ScrollTrigger.create({
    //     trigger: elem,
    //     start: "top 50%",
    //     end: "bottom 50%",
    //     onEnter: () => setVariant(elem.dataset.theme),
    //     onLeave: () => setVariant(elem.dataset.theme),
    //     onLeaveBack: () => setVariant(elem.dataset.theme),
    //     onEnterBack: () => setVariant(elem.dataset.theme),
    //   });
    // });
  }, []);

  return <StyledPageBackground variant={variant} />;
};

export default PageBackground;
