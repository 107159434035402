import {
  StyledHorizontalPreview,
  StyledHorizontalPreviewParallax,
  StyledHorizontalPreviewCase,
} from "./styles.js";
import { Media, WrapperLink, AnimateParallax } from "../";

const renderItem = (items) => {
  return items.map((item, i) => {
    return (
      <StyledHorizontalPreviewCase key={i}>
        <WrapperLink data={item}>
          <div>
            <Media {...item.media} />
          </div>
        </WrapperLink>
      </StyledHorizontalPreviewCase>
    );
  });
};

const HorizontalPreview = ({ list }) => {
  const itemPerColumns = 2;

  return (
    <StyledHorizontalPreview>
      <AnimateParallax>
        <StyledHorizontalPreviewParallax>
          {list.map((item, i) => {
            let arr = [];

            if ((i + 1) % itemPerColumns === 0) {
              arr.push(list[i - 1]);
              arr.push(list[i]);

              return <div key={i}>{renderItem(arr)}</div>;
            } else {
              arr = [];
            }
          })}
        </StyledHorizontalPreviewParallax>
      </AnimateParallax>
    </StyledHorizontalPreview>
  );
};

export default HorizontalPreview;
