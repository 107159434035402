const EditorialStories = ({ title, text, cta, list }) => {
  const normalizedData = {
    title,
    text,
    cta,
    list,
  };

  return normalizedData;
};

export default EditorialStories;
