const BannerThunder = ({
  title,
  type,
  cta,
  label,
  form_id,
  form_url,
  trakstar_job_id,
}) => {
  const typeformHiddenProps = {}
  
  if(type == "typeform"){
    Object.assign(typeformHiddenProps, {
      position: trakstar_job_id,
    })
  }
  const normalizedData = {
    title,
    type,
    cta,
    label,
    form_id,
    form_url,
    typeformHiddenProps,
  };

  return normalizedData;
};

export default BannerThunder;
