import styled, { css } from "styled-components";

export const StyledPageBackground = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${({ theme, variant }) =>
    theme.theme[`${variant}`].backgroundColor};
  transition: background-color 0.3s ease-out;
  pointer-events: none;
  z-index: -1;
`;
