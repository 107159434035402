import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { Fragment } from 'react'
import { AnimateFadeIn, Cta, Icon, Richtext, WrapperLink } from '../'
import { Display, SubHeader } from '../Typography'
import {
  StyledHeroJobPosition,
  StyledHeroJobPositionBadge,
  StyledHeroJobPositionContent,
  StyledHeroJobPositionCta,
  StyledHeroJobPositionFlexWrapper,
  StyledHeroJobPositionThunder,
  StyledHeroJobPositionTitle,
} from './styles.js'
const SliderButton = dynamic(() =>
  import('@typeform/embed-react').then((mod) => mod.SliderButton),
)

const HeroJobPosition = ({
  title,
  text,
  cta,
  ctaAllCareers = null,
  ctaHiddenProps,
  badges = [],
}) => {
  const { locale } = useRouter()
  const renderBadges = () =>
    badges.map((badge, index) => {
      const isFirst = index === 0
      const label = locale === 'en' ? 'Location' : 'Sede'
      return (
        <Fragment>
          {isFirst ? (
            <Fragment>
              <StyledHeroJobPositionBadge key={`sede-${index}`}>
                {label}
              </StyledHeroJobPositionBadge>
              <StyledHeroJobPositionBadge key={`sede-${index}`}>
                {'/'}
              </StyledHeroJobPositionBadge>
            </Fragment>
          ) : null}
          <StyledHeroJobPositionBadge
            key={`${badge}-${index}`}
            style={{ fontWeight: 700 }}
          >
            {badge}
          </StyledHeroJobPositionBadge>
        </Fragment>
      )
    })

  return (
    <StyledHeroJobPosition>
      <StyledHeroJobPositionContent>
        <StyledHeroJobPositionTitle>
          <AnimateFadeIn delay={1}>
            <Display>
              <h1>{title}</h1>
            </Display>
          </AnimateFadeIn>
        </StyledHeroJobPositionTitle>
        {badges.length > 0 ? (
          <AnimateFadeIn delay={1}>
            <StyledHeroJobPositionFlexWrapper>
              {renderBadges()}
            </StyledHeroJobPositionFlexWrapper>
          </AnimateFadeIn>
        ) : null}
        {text && (
          <AnimateFadeIn delay={1.1}>
            <SubHeader>
              <Richtext>{text}</Richtext>
            </SubHeader>
          </AnimateFadeIn>
        )}
        {cta && cta.form_id && cta.label.length > 2 && (
          <AnimateFadeIn delay={1.2}>
            <StyledHeroJobPositionCta>
              <SliderButton
                id={cta.form_id}
                hidden={ctaHiddenProps}
              >
                <Cta>{cta.label}</Cta>
              </SliderButton>
            </StyledHeroJobPositionCta>
          </AnimateFadeIn>
        )}
        {ctaAllCareers && (
          <StyledHeroJobPositionCta>
            <WrapperLink data={ctaAllCareers}>
              <Cta>{ctaAllCareers.label}</Cta>
            </WrapperLink>
          </StyledHeroJobPositionCta>
        )}
      </StyledHeroJobPositionContent>
      <StyledHeroJobPositionThunder>
        <div data-speed='0.7'>
          <Icon name='pre-footer-bolt' />
        </div>
      </StyledHeroJobPositionThunder>
    </StyledHeroJobPosition>
  )
}

export default HeroJobPosition
