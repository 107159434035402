const PeopleHero = ({ title, list, cta }) => {
  const normalizedData = {
    title,
    list,
    cta,
  };

  return normalizedData;
};

export default PeopleHero;
