import styled, { css } from "styled-components";

export const StyledBannerThunderTitle = styled.div``;

export const StyledBannerThunder = styled.div`
  position: relative;
  padding: 0 calc(100vw / 12 * 1);
  overflow: hidden;
  margin-top: calc(100vw / 12 * -1);
  margin-bottom: calc(100vw / 12 * -1);

  button {
    cursor: pointer;
    background: none;
    border: 0;
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
    padding: 0;
    font-weight: 600;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    span {
      font-weight: 600;
      font-family: Manrope, sans-serif;
    }
  }

  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
    padding: calc(100vw / 12 * 1) calc(100vw / 12 * 2);
    display: flex;
  `)};

  > div {
    &:first-child {
      max-width: 80%;
      ${({ theme, type }) =>
        theme.mediaQuery.tablet(`
            max-width: 60%;
      `)};
    }
    &:last-child {
      position: relative;
      padding-bottom: 40%;
      width: 70%;
      float: right;
      ${({ theme, type }) =>
        theme.mediaQuery.tablet(`
        float: none;
        padding: 0;
        width: 40%;
      `)};
    }
  }
`;

export const StyledBannerThunderIcon = styled.div`
  position: absolute;
  width: 110%;
  top: 50%;
  right: calc(100vw / 12 * -1);
  transform: translateY(-50%);
  opacity: 0.2;
`;
