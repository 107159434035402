import theme from './config'

const processCss = (css) => {
  let injectedCss = ''
  if (typeof css === 'string') return css
  for (const [key, value] of Object.entries(css)) {
    let processedValue = value
    if (key === 'font-size' || key === 'line-height') processedValue = rem(value)
    injectedCss += `${key}: ${processedValue}; `
  }
  
  return injectedCss
}

// export const mediaQuery = {
//     tablet: function(css) {
//       return `
//         @media screen and (min-width: ${theme.breakpoints.tablet}px) {
//           ${processCss(css)}
//         }
//       `
//     },
//     desktop: function(css) {
//       return `
//         @media screen and (min-width: ${theme.breakpoints.desktop}px) {
//           ${processCss(css)}
//         }
//       `
//     },
//     wide: function(css) {
//       return `
//         @media screen and (min-width: ${theme.breakpoints.wide}px) {
//           ${processCss(css)}
//         }
//       `
//     }
// }

export let mediaQuery = {}

for (const key in theme.breakpoints) {
  mediaQuery[key] = function(css) {
    return `
      @media screen and (min-width: ${theme.breakpoints[key]}px) {
        ${processCss(css)}
      }
    `
    }
}

export function rem(...values) {
    return values.map(px => `${parseInt(px)/10}rem`).join(' ')
}

export function col(...values) {
    return values.map(col => `calc(${col} / ${theme.siteColumns} * 100vw);`)
}

export function color(key, variant = 'default') {
    if (!theme.colors[key]) return 'inherit'
    return theme.colors[key][variant] ? theme.colors[key][variant] : theme.colors[key]
}



export function typo(key) {
  let rules = [];

  if (theme.typography[key]) {
    for (const [rule, ruleValue] of Object.entries(theme.typography[key])) {
      if (rule === 'mq') {
        for (const [mq, mqValue] of Object.entries(ruleValue)) {
          rules.push(mediaQuery[mq](mqValue))
        }
      } else {
        let processedValue = ruleValue
        if (rule === 'font-size' || rule === 'line-height') processedValue = rem(ruleValue)
        rules.push(`${rule}: ${processedValue};`)
      }
    }

    // return theme.typography[key] ? theme.typography[key] : theme.typography[key]
    return [...rules]
  }  
}