import React, { useContext } from "react";
import { StyledCta } from "./styles.js";
import Icon from "../Icon/index.js";
import { StyledRegularLink } from "../Typography/index.js";

import { MouseContext } from "../../contexts/Mouse";

const Cta = ({ children }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  return (
    <StyledCta
      onMouseOver={() => {
        cursorChangeHandler("hide");
        cursorChangeText("");
      }}
      onMouseLeave={() => {
        cursorChangeHandler("");
        cursorChangeText("");
      }}
    >
      <StyledRegularLink>
        <div>
          <Icon name="caret-right" />
          <span>{children}</span>
          <Icon name="caret-right" />
        </div>
      </StyledRegularLink>
    </StyledCta>
  );
};

export default Cta;
