import styled, { css } from "styled-components";

export const StyledGridLogo = styled.div`
  padding: 0 calc(100vw / 12 * 1);
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}
`;

export const StyledGridLogoTitle = styled.div`
margin-bottom: calc(100vw / 12 * 1.5);
  ${({ theme }) =>
      theme.mediaQuery.tablet(`
      margin-bottom: calc(100vw / 12 * 0.5);
    `)};
`;

export const StyledGridLogoTable = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 3.2rem);
  }
`;

export const StyledGridLogoCel = styled.div`
  position: relative;
  width: calc(50% - 3.2rem);
  margin-right: 3.2rem;
  margin-bottom: 3.2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      width: calc(33.3% - 3.2rem);
    `)};

  > div {
    height: 10rem;
    width: 20rem;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
