const ClientCarousel = ({ title, text, customers, cta, lower_cta }) => {
  const normalizedData = {
    title: title,
    text: text,
    cta: cta,
    customers: customers,
    lower_cta: lower_cta,
  };

  return normalizedData;
};

export default ClientCarousel;
