import styled, { css } from "styled-components";

export const StyledNewsletter = styled.div`
  padding: 0 calc(100vw / 12 * 1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    margin-top: 6rem;

    button {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      cursor: pointer;
    }
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 70rem;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-bottom: 5.4rem;
  `)};

  &[data-cb] {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `)};

    button {
      padding: 0;
      margin: 4rem 0;
      ${({ theme }) =>
        theme.mediaQuery.tablet(`
        margin:0;
      `)};
    }

    > div {
      width: auto;
    }
  }

  [data-label-cb] {
    ${({ theme }) => theme.typo("caption")};
    display: flex;
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      &:checked {
        & ~ span {
          background-color: black;
        }
      }
    }

    span {
      width: 16px;
      height: 16px;
      display: block;
      border: 1px solid black;
      background: white;
      margin-right: 6px;
    }
  }
`;

export const StyledCol = styled.div`
  display: flex;
  margin-bottom: 2.8rem;
  width: auto;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-bottom: 0;
  `)};

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-right: 5.4rem;
  `)};

  label {
    width: 100%;
    div {
      height: 1px;
      width: 100%;
      background: black;
      opacity: 0.35;
    }
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    padding: 0;
    padding-bottom: 1.5rem;
    ${({ theme }) => theme.typo("body")};
    width: 100%;
    display: block;
    background: transparent;
    border-radius: 0;
    border: 0;
    color: black;
    min-width: 40rem;
    &::placeholder {
      color: black;
      opacity: 1;
    }
  }
`;
