import styled, { css } from "styled-components";

export const StyledEditorialGrid = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}
`;

export const StyledEditorialGridSliderWrapper = styled.div`
  > div {
    margin-bottom: 4rem;
  }
  .swiper {
    overflow: visible;
    margin-bottom: 0;
  }

  .swiper-slide {
    margin-right: 2.4rem;
    height: auto;
    border: 1px solid #ebebeb;
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 26rem;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      width: calc(100vw/12*4.5);
    `)};

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
      width: calc(100vw/12*2);
    `)};

    &:last-child {
      margin-right: 0;
    }

    > div {
      padding: 2.4rem;
      height: 100%;
      background: #ffffff12;
    }

    strong {
      display: block;
      margin-bottom: 1.6rem;
    }
  }
`;

export const StyledEditorialGridWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.col(-2)};

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-bottom: calc(100vw/12*-1);
`)}

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    width: calc(100% + 100vw / 12 * 1);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(100vw/12*-1);
`)}

  > div {
    padding-bottom: ${({ theme }) => theme.col(1)};

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
        width: 33.3%;
        padding-right: calc(100vw/12*1);
        padding-bottom: calc(100vw/12*1);
    `)};

    strong {
      display: block;
      margin-bottom: 3.2rem;

      ${({ theme }) =>
        theme.mediaQuery.desktop(`
        margin-bottom: 3.8rem;
    `)}
    }
  }
`;

export const StyledListItemElementWrapper = styled.div`
  border-top: 1px solid #cdcdcd;
  padding-top: 2.4rem;
`;
