const HeroServices = ({ title, scrollto, list }) => {
  const normalizedData = {
    title,
    scrollto,
    list,
  };

  return normalizedData;
};

export default HeroServices;
