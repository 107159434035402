import styled, { css } from "styled-components";

export const StyledReel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      padding-left: ${theme.col(1.5)};
      padding-right: ${theme.col(1.5)};
  `)}

  &[data-type="active"] {
    opacity: 1;
    pointer-events: visible;

    > div {
      opacity: 1;
      transform: translateY(0);
    }
  }

  > div {
    width: 100%;
    transition: all 0.5s ease;
    margin-top: -3rem;
    opacity: 0;
    transform: translateY(5rem);
  }

  [data-video] {
    padding: 0 calc(100vw / 12 * 1);
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
    `)}
    > div {
      aspect-ratio: 16/9;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
`;

export const StyledClose = styled.span`
  display: block;
  height: 3rem;
  width: 3rem;
  margin-bottom: 7rem;
  margin-top: -4rem;
  cursor: pointer;
  position: relative;

  svg {
    display: block;
    width: 100%;
  }
`;
