import styled, { css } from "styled-components";

export const StyledLauncherBoxes = styled.div`
  margin-bottom: calc(-100% / 12 * 2);

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
      margin-bottom: calc(-100% / 12 * 1);
  `)};

  .swiper {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      padding: 0 calc(100% / 12 * 1);
    `)};
    ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}
  }

  .swiper-wrapper {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      transform: none !important;
    }
  }

  .swiper-slide {
    width: auto;
    min-width: calc(100% / 12 * 11);
    height: 47rem;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      min-width: calc(100% / 12 * 7);
  `)};
  }
`;

export const StyledLauncherBoxesWrapperText = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  & + * {
    margin-top: ${({ theme }) => theme.col(0.5)};
  }

  > div {
    margin-bottom: 3.2rem;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      margin-bottom: 4.2rem;
  `)};
  }
`;

export const StyledLauncherBoxesWrapperBoxes = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    display: flex;
  `)};

  [data-wrapper-link] {
    display: flex;
    flex-grow: 1;
    cursor: pointer;
    aspect-ratio: 4/3.6;
  }
`;
