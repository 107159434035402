const EditorialGrid = ({ slider, title, list }) => {
  const normalizedData = {
    slider: slider,
    title: title,
    list: list,
  };

  return normalizedData;
};

export default EditorialGrid;
