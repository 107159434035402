import styled from 'styled-components'

export const StyledHeroJobPosition = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ theme }) => theme.col(1)};
  margin-bottom: ${({ theme }) => theme.col(-1)};
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
    padding-left: calc(100vw/12*2);
    padding-right: calc(100vw/12*2);
  `)};
`

export const StyledHeroJobPositionTitle = styled.div`
  margin-block-end: 1rem;
`

export const StyledHeroJobPositionContent = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.desktop(`
    width: 40%;
    `)};
`

export const StyledHeroJobPositionCta = styled.div`
  margin-top: calc(100vw / 12 * 0.5);
  button {
    cursor: pointer;
    background: none;
    border: 0;
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
    padding: 0;
    font-weight: 600;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    span {
      font-family: Manrope, sans-serif;
    }
  }
`

export const StyledHeroJobPositionThunder = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  right: 0;
  width: 50%;
  padding-right: calc(100vw / 12 * 1);
  padding-left: calc(100vw / 12 * 1);
  height: 100%;
  display: none;

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
      display: flex;
      align-items: flex-end;
    `)};
  &:after {
    content: '';
    display: block;
    background: linear-gradient(
      180deg,
      rgba(19, 18, 18, 0) -6.18%,
      #131212 77.75%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  > div {
    width: 100%;
  }
`

export const StyledHeroJobPositionBadge = styled.span`
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 0.2s ease;
  letter-spacing: 1px;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    font-size: 20px;
  `)};
`
export const StyledHeroJobPositionFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-block: 0.5rem 4rem;
`
