import styled, { css } from "styled-components";

export const StyledCaseCarousel = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.col(4)};

  .swiper-button-disabled {
    pointer-events: none;
  }

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
        padding-bottom: 0; 
    `)}

  .swiper, .swiper-slide {
    overflow: visible;
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
            overflow: hidden;
        `)}
  }

  .swiper-slide {
    padding-right: ${({ theme }) => theme.col(1)};
    padding-left: ${({ theme }) => theme.col(1)};
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
            padding-right: ${theme.col(2)};
            padding-left: ${theme.col(2)};
        `)}
  }
`;

export const StyledCaseCarouselSlideMedia = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledCaseCarouselSlide = styled.div`
  position: relative;
  cursor: pointer;
  background: #000;
  height: 0;
  padding-bottom: 126.6%;

  ${({ theme }) => theme.mediaQuery.tablet(`
    padding-bottom: 56.6%;
  `)}

  img,
  video {
    opacity: 1;
  }

  &:after {
    content: "";
    display: block;
    left: 0;
    bottom: ${({ theme }) => theme.col(-1)};
    position: absolute;
    width: 100%;
    z-index: 0;
    background: linear-gradient(0deg, #131212, transparent);
    height: 100%;
  }
`;

export const StyledCaseCarouselContent = styled.article`
  position: absolute;
  bottom: ${({ theme }) => theme.col(-2)};
  left: 1.6rem;
  z-index: 2;

  ${({ theme }) => theme.mediaQuery.tablet(`
      bottom: 3.4rem;
      left: ${theme.col(-1)};
  `)}

  [data-h2] {
    .swiper-slide-active & {
      transition-delay: 0.2s;
    }
  }

  [data-h1] {
    .swiper-slide-active & {
      transition-delay: 0.4s;
    }
    margin-bottom: 0.8rem;
  }

  [data-h3] {
    .swiper-slide-active & {
      transition-delay: 0.6s;
    }
    ${({ theme }) => theme.typo("body")};
  }

  [data-h1],
  [data-h2],
  [data-h3] {
    display: block;
    transform: translateY(1.5rem);
    transition: all 0.5s ease-out 0s;
    opacity: 0;

    .swiper-slide-active & {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const StyledCaseCarouselHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  margin-bottom: ${({ theme }) => theme.margins.default};
  color: ${({ theme }) => theme.colors.grey80};
  ${({ theme }) => theme.typo("subheaderSmall")};

  ${({ theme }) =>
    theme.mediaQuery.desktop(`
        padding-right:  ${theme.col(2)};
        padding-left:  ${theme.col(2)};
    `)}
`;

export const StyledCaseCarouselPagination = styled.div`
  font-size: ${({ theme }) => theme.rem(18)};
  line-height: ${({ theme }) => theme.rem(22)};
  align-items: center;
  display: none;
  ${({ theme }) =>
    theme.mediaQuery.desktop(`
        display: flex;
    `)}

  span {
    display: block;
    font-weight: bold;
  }

  svg {
    margin: 0 ${({ theme }) => theme.rem(8)};
  }
`;

export const StyledCaseCarouselText = styled.div``;

export const StyledCaseCarouselWrapper = styled.div`
  position: relative;

  > span {
    position: absolute;
    top: 0;
    height: 100%;
    width: ${({ theme }) => theme.col(1)};
    cursor: pointer;
    z-index: 9;
    display: block;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }
`;
