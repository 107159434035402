import dynamic from "next/dynamic";
const SliderButton = dynamic(() =>
  import("@typeform/embed-react").then((mod) => mod.SliderButton)
);

import React from "react";
import {
  StyledBannerThunder,
  StyledBannerThunderIcon,
  StyledBannerThunderTitle,
} from "./styles.js";

import { Title, StyledRegularLink } from "../Typography/index.js";

import {
  Icon,
  WrapperLink,
  AnimateFadeIn,
  EditorialWrapper,
  Cta,
  IframeModal,
} from "../";

const BannerThunder = ({
  title,
  is_typeform,
  cta,
  label,
  form_id,
  trakstar_job_id,
}) => {
  return (
    <>
      <AnimateFadeIn>
        <StyledBannerThunder>
          <EditorialWrapper>
            <StyledBannerThunderTitle data-subtitle>
              <Title>{title}</Title>
            </StyledBannerThunderTitle>

            {cta && (
              <div data-cta>
                <WrapperLink data={cta}>
                  <div>
                    <StyledRegularLink>
                      <div>
                        <Icon name="caret-right" />
                        <span>{cta.label}</span>
                        <Icon name="caret-right" />
                      </div>
                    </StyledRegularLink>
                  </div>
                </WrapperLink>
              </div>
            )}

            {is_typeform && (
              <div data-cta>
                <SliderButton
                  id={form_id}
                  hidden={{
                    position: trakstar_job_id,
                  }}
                >
                  <Cta>{label}</Cta>
                </SliderButton>
              </div>
            )}
          </EditorialWrapper>

          <div>
            <StyledBannerThunderIcon>
              <div>
                <Icon name="pre-footer-bolt" />
              </div>
            </StyledBannerThunderIcon>
          </div>
        </StyledBannerThunder>
      </AnimateFadeIn>
    </>
  );
};

export default BannerThunder;
