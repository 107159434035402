import React, { useContext } from "react";
import { MouseContext } from "../../contexts/Mouse";
import {
  StyledHeroContacts,
  StyledHeroLogo,
  StyledTitle,
  StyledContent,
  StyledOffices,
  StyledForm,
} from "./styles.js";

import dynamic from "next/dynamic";

const Widget = dynamic(() =>
  import("@typeform/embed-react").then((mod) => mod.Widget)
);

import { AnimateSplitFadeIn, AnimateFadeIn, WrapperLink, Cta } from "../";
import { Headline, BodyBig, Body } from "../Typography";

const HeroContacts = ({ text, offices, contacts }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);

  return (
    <StyledHeroContacts>
      <StyledHeroLogo>
        <svg viewBox="0 0 863 805">
          <path
            d="M407.631 238.855C436.907 221.982 451.545 213.546 467.618 213.546C483.692 213.546 498.33 221.982 527.605 238.855L550.495 252.046C579.771 268.919 594.408 277.355 602.445 291.25C610.482 305.145 610.482 322.018 610.482 355.762V382.146C610.482 415.89 610.482 432.763 602.445 446.658C594.408 460.553 579.771 468.99 550.495 485.862L527.605 499.054C498.33 515.926 483.692 524.362 467.618 524.362C451.545 524.362 436.907 515.926 407.631 499.054L384.742 485.862C355.466 468.99 340.828 460.553 332.791 446.658C324.754 432.763 324.754 415.89 324.754 382.146V355.762C324.754 322.018 324.754 305.145 332.791 291.25C340.828 277.355 355.466 268.919 384.742 252.046L407.631 238.855Z"
            fill="#070707"
          />
          <path
            d="M243.163 -42.6915C272.438 -59.5638 287.076 -68 303.15 -68C319.223 -68 333.861 -59.5638 363.137 -42.6915L386.027 -29.4999C415.302 -12.6275 429.94 -4.19136 437.977 9.70396C446.014 23.5993 446.014 40.4716 446.014 74.2163V100.6C446.014 134.344 446.014 151.217 437.977 165.112C429.94 179.007 415.302 187.443 386.027 204.316L363.137 217.507C333.861 234.38 319.223 242.816 303.15 242.816C287.076 242.816 272.438 234.38 243.163 217.507L220.273 204.316C190.998 187.443 176.36 179.007 168.323 165.112C160.286 151.217 160.286 134.344 160.286 100.6V74.2163C160.286 40.4716 160.286 23.5993 168.323 9.70396C176.36 -4.19136 190.998 -12.6275 220.273 -29.4999L243.163 -42.6915Z"
            fill="#070707"
          />
          <path
            d="M243.163 519.007C272.438 502.135 287.076 493.699 303.15 493.699C319.223 493.699 333.861 502.135 363.137 519.007L386.027 532.199C415.302 549.071 429.94 557.507 437.977 571.403C446.014 585.298 446.014 602.17 446.014 635.915V662.298C446.014 696.043 446.014 712.915 437.977 726.811C429.94 740.706 415.302 749.142 386.027 766.014L363.137 779.206C333.861 796.078 319.223 804.515 303.15 804.515C287.076 804.515 272.438 796.078 243.163 779.206L220.273 766.014C190.998 749.142 176.36 740.706 168.323 726.811C160.286 712.915 160.286 696.043 160.286 662.298V635.915C160.286 602.17 160.286 585.298 168.323 571.403C176.36 557.507 190.998 549.071 220.273 532.199L243.163 519.007Z"
            fill="#070707"
          />
          <path
            d="M732.385 238.855C761.661 221.982 776.299 213.546 792.373 213.546C808.446 213.546 823.084 221.982 852.36 238.855L875.249 252.046C904.525 268.919 919.163 277.355 927.2 291.25C935.236 305.145 935.236 322.018 935.236 355.762V382.146C935.236 415.89 935.236 432.763 927.2 446.658C919.163 460.553 904.525 468.99 875.249 485.862L852.36 499.054C823.084 515.926 808.446 524.362 792.373 524.362C776.299 524.362 761.661 515.926 732.385 499.054L709.496 485.862C680.22 468.99 665.582 460.553 657.546 446.658C649.509 432.763 649.509 415.89 649.509 382.146V355.762C649.509 322.018 649.509 305.145 657.546 291.25C665.582 277.355 680.22 268.919 709.496 252.046L732.385 238.855Z"
            fill="#070707"
          />
          <path
            d="M567.917 -42.6915C597.193 -59.5638 611.831 -68 627.904 -68C643.978 -68 658.616 -59.5638 687.892 -42.6915L710.781 -29.4999C740.057 -12.6275 754.695 -4.19136 762.731 9.70396C770.768 23.5993 770.768 40.4716 770.768 74.2163V100.6C770.768 134.344 770.768 151.217 762.731 165.112C754.695 179.007 740.057 187.443 710.781 204.316L687.892 217.507C658.616 234.38 643.978 242.816 627.904 242.816C611.831 242.816 597.193 234.38 567.917 217.507L545.028 204.316C515.752 187.443 501.114 179.007 493.077 165.112C485.041 151.217 485.041 134.344 485.041 100.6V74.2163C485.041 40.4716 485.041 23.5993 493.077 9.70396C501.114 -4.19136 515.752 -12.6275 545.028 -29.4999L567.917 -42.6915Z"
            fill="#070707"
          />
          <path
            d="M567.917 519.007C597.193 502.135 611.831 493.699 627.904 493.699C643.978 493.699 658.616 502.135 687.892 519.007L710.781 532.199C740.057 549.071 754.695 557.507 762.731 571.403C770.768 585.298 770.768 602.17 770.768 635.915V662.298C770.768 696.043 770.768 712.915 762.731 726.811C754.695 740.706 740.057 749.142 710.781 766.014L687.892 779.206C658.616 796.078 643.978 804.515 627.904 804.515C611.831 804.515 597.193 796.078 567.917 779.206L545.028 766.014C515.752 749.142 501.114 740.706 493.077 726.811C485.041 712.915 485.041 696.043 485.041 662.298V635.915C485.041 602.17 485.041 585.298 493.077 571.403C501.114 557.507 515.752 549.071 545.028 532.199L567.917 519.007Z"
            fill="#070707"
          />
          <path
            d="M892.671 -42.6915C921.947 -59.5638 936.585 -68 952.659 -68C968.732 -68 983.37 -59.5638 1012.65 -42.6915L1035.54 -29.4999C1064.81 -12.6275 1079.45 -4.19136 1087.49 9.70396C1095.52 23.5993 1095.52 40.4716 1095.52 74.2163V100.6C1095.52 134.344 1095.52 151.217 1087.49 165.112C1079.45 179.007 1064.81 187.443 1035.54 204.316L1012.65 217.507C983.37 234.38 968.732 242.816 952.659 242.816C936.585 242.816 921.947 234.38 892.671 217.507L869.782 204.316C840.506 187.443 825.868 179.007 817.832 165.112C809.795 151.217 809.795 134.344 809.795 100.6V74.2163C809.795 40.4716 809.795 23.5993 817.832 9.70396C825.868 -4.19136 840.506 -12.6275 869.782 -29.4999L892.671 -42.6915Z"
            fill="#070707"
          />
          <path
            d="M892.671 519.007C921.947 502.135 936.585 493.699 952.659 493.699C968.732 493.699 983.37 502.135 1012.65 519.007L1035.54 532.199C1064.81 549.071 1079.45 557.507 1087.49 571.403C1095.52 585.298 1095.52 602.17 1095.52 635.915V662.298C1095.52 696.043 1095.52 712.915 1087.49 726.811C1079.45 740.706 1064.81 749.142 1035.54 766.014L1012.65 779.206C983.37 796.078 968.732 804.515 952.659 804.515C936.585 804.515 921.947 796.078 892.671 779.206L869.782 766.014C840.506 749.142 825.868 740.706 817.832 726.811C809.795 712.915 809.795 696.043 809.795 662.298V635.915C809.795 602.17 809.795 585.298 817.832 571.403C825.868 557.507 840.506 549.071 869.782 532.199L892.671 519.007Z"
            fill="#070707"
          />
          <path
            d="M82.8766 238.855C112.152 221.982 126.79 213.546 142.864 213.546C158.937 213.546 173.575 221.982 202.851 238.855L225.74 252.046C255.016 268.919 269.654 277.355 277.691 291.25C285.728 305.145 285.728 322.018 285.728 355.762V382.146C285.728 415.89 285.728 432.763 277.691 446.658C269.654 460.553 255.016 468.99 225.74 485.862L202.851 499.054C173.575 515.926 158.937 524.362 142.864 524.362C126.79 524.362 112.152 515.926 82.8766 499.054L59.9872 485.862C30.7114 468.99 16.0735 460.553 8.03677 446.658C0 432.763 0 415.89 0 382.146V355.762C0 322.018 0 305.145 8.03677 291.25C16.0735 277.355 30.7114 268.919 59.9872 252.046L82.8766 238.855Z"
            fill="#070707"
          />
        </svg>
      </StyledHeroLogo>

      <StyledContent>
        <StyledTitle>
          <Headline>
            <h1>
              <AnimateSplitFadeIn>{text}</AnimateSplitFadeIn>
            </h1>
          </Headline>
        </StyledTitle>

        <StyledForm
          data-type={contacts.form_type}
          onMouseOver={() => {
            cursorChangeHandler("hide");
            cursorChangeText("");
          }}
          onMouseLeave={() => {
            cursorChangeHandler("");
            cursorChangeText("");
          }}
        >
          <AnimateFadeIn delay={1.5}>
            {contacts.form_type == "typeform" ? (
              <Widget id={contacts.form} className="my-form" />
            ) : (
              <iframe src={contacts.form} />
            )}
          </AnimateFadeIn>
        </StyledForm>

        <StyledOffices>
          <ul>
            {offices.list &&
              offices.list.map((el, i) => {
                return (
                  <li key={i}>
                    <AnimateFadeIn delay={1.5 + i / 10}>
                      <BodyBig>{el.name}</BodyBig>
                      <Body>{el.address}</Body>
                      <Body>{el.cap}</Body>

                      <div style={{ marginTop: "0.8rem" }}>
                        <WrapperLink data={el.cta}>
                          <Cta>{el.cta.label}</Cta>
                        </WrapperLink>
                      </div>
                    </AnimateFadeIn>
                  </li>
                );
              })}
          </ul>
        </StyledOffices>
      </StyledContent>
    </StyledHeroContacts>
  );
};

export default HeroContacts;
