import styled, { css } from "styled-components";

export const StyledEditorialCards = styled.div`
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
    padding-left: calc(100vw/12*2);
  `)};

  .swiper-slide {
    height: auto;
  }

  .swiper-slide-next,
  .swiper-slide-visible {
    z-index: 3 !important;
  }

  > div {
    &:last-child {
      margin-top: calc(100vw / 12 * 1);
    }
  }

  ${({ theme, type }) =>
    theme.mediaQuery.tablet(`
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
          width: 50%;
          &:first-of-type {
            padding-right: calc(100vw/12*1);
          }
          &:last-of-type {
            margin-top: 0;
            padding-right: calc(100vw/12*0.7);
          }
      }
  `)};
`;

export const StyledEditorialCardsContent = styled.div``;

export const StyledEditorialCardsSlider = styled.div`
  .swiper {
    overflow: visible;
  }
`;
