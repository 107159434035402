import styled, { css } from "styled-components";

export const StyledFeaturedCareers = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*1);
        padding-right: calc(100vw/12*1);
      `)}
`;

export const StyledFeaturedCareersHeader = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  ${({ theme }) => theme.typo("headline")};
  margin-bottom: ${({ theme }) => theme.col(0.5)};

  ${({ theme, type }) =>
    theme.mediaQuery.desktop(`
    padding-right: calc(100vw/12*6);
  `)};

  > * {
    strong {
      font-weight: 700;
    }
    em {
      font-style: normal;
    }
    > div {
      display: inline;
    }
  }

  em {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const StyledFeaturedCareerLink = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  margin-top: ${({ theme }) => theme.col(0.5)};
`;
