import React from "react";
import {
  StyledGridLogo,
  StyledGridLogoTitle,
  StyledGridLogoTable,
  StyledGridLogoCel,
} from "./styles.js";
import { Headline } from "../Typography/index.js";
import Image from "next/image";
import AnimateFadeIn from "../AnimateFadeIn/index.js";

const GridLogo = ({ title, list }) => {
  return (
    <StyledGridLogo>
      <StyledGridLogoTitle>
        <AnimateFadeIn>
          <Headline>{title}</Headline>
        </AnimateFadeIn>
      </StyledGridLogoTitle>

      <StyledGridLogoTable>
        <div>
          {list.map((el, i) => {
            return (
              <StyledGridLogoCel key={i}>
                <AnimateFadeIn delay={i * 0.15}>
                  <Image
                    src={el.image.url}
                    alt={el.image.alt}
                    width={200}
                    height={100}
                  />
                </AnimateFadeIn>
              </StyledGridLogoCel>
            );
          })}
        </div>
      </StyledGridLogoTable>
    </StyledGridLogo>
  );
};

export default GridLogo;
