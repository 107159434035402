import React, { useRef, useState, memo } from "react";
import useInterval from "../../utils/useInterval";
// import { slice, difference } from "lodash";
import slice from "lodash/slice";
import difference from "lodash/difference";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import {
  Richtext,
  WrapperLink,
  Cta,
  Icon,
  AnimateFadeIn,
  EditorialWrapper,
} from "../";
import {
  StyledClientCarouselWrapper,
  StyledClientCarouselContent,
  StyledClientCarouselRichtext,
  StyledLogoCarousel,
} from "./styles.js";

import { Headline } from "../Typography";

const CarouselItem = memo((props) => {
  const { el } = props;

  return (
    <div>
      <Icon name="hexagon" />
      {el && (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            data-logo
            key={el.image.url}
            initial={{ opacity: 0, scale: 0.8, y: "-50%", x: "-50%" }}
            animate={{ opacity: 1, scale: 1, y: "-50%", x: "-50%" }}
            exit={{ opacity: 0, scale: 0.8, y: "-50%", x: "-50%" }}
            transition={{ duration: 1.5 }}
          >
            <div>
                <Image
                  src={el.image.url ? el.image.url : "/preview.png"}
                  alt={el.image.alt}
                  layout="fill"
                />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
});

function generateRandom(min, max) {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  return num;
}

const ClientCarousel = ({ title, text, cta, customers, lower_cta }) => {
  const [clients, setClients] = useState(slice(customers, 0, 14));
  const prevRnd = useRef(null);

  prevRnd.current = -1;

  useInterval(() => {
    const newClients = [...clients];
    const differenceClients = difference(customers, newClients);
    let rnd;

    do {
      rnd = generateRandom(0, 14);
    } while (
      rnd === prevRnd.current ||
      rnd === 0 ||
      rnd === 6 ||
      rnd === 7 ||
      rnd === 14
    );
    // console.log(rnd);

    const randomNewItem =
      differenceClients[Math.floor(Math.random() * differenceClients.length)];

    newClients[rnd] = randomNewItem;
    setClients(newClients);

    prevRnd.current = rnd;
  }, 1000);

  return (
    <StyledClientCarouselWrapper>
      <StyledClientCarouselContent>
        <EditorialWrapper>
          {title && (
            <Headline data-title>
              <AnimateFadeIn>{title}</AnimateFadeIn>
            </Headline>
          )}

          {text && (
            <StyledClientCarouselRichtext data-richtext>
              <AnimateFadeIn>
                <Richtext>{text}</Richtext>
              </AnimateFadeIn>
            </StyledClientCarouselRichtext>
          )}

          {cta.label && (
            <div data-cta>
              <AnimateFadeIn>
                <WrapperLink data={cta}>
                  <Cta>{cta.label}</Cta>
                </WrapperLink>
              </AnimateFadeIn>
            </div>
          )}
        </EditorialWrapper>

        <StyledLogoCarousel>
          {clients &&
            clients.map((el, i) => {
              if (i < 14) {
                return <CarouselItem el={el} key={i} i={i} />;
              }
            })}
        </StyledLogoCarousel>

        {lower_cta && (
          <AnimateFadeIn>
            <WrapperLink data={lower_cta}>
              <Cta>{lower_cta.label}</Cta>
            </WrapperLink>
          </AnimateFadeIn>
        )}
      </StyledClientCarouselContent>
    </StyledClientCarouselWrapper>
  );
};

export default ClientCarousel;
