import styled, { css } from "styled-components";

export const StyledHorizontalPreview = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  overflow: hidden;
`;
export const StyledHorizontalPreviewParallax = styled.div`
  display: inline-flex;
  will-change: transform;
  flex-wrap: nowrap;
  padding-bottom: ${({ theme }) => theme.col(1)};

  > div {
    margin-right: ${({ theme }) => theme.margins.default};
    min-width: calc(8 / 12 * 100vw - 32px);

    > div {
      height: auto;
      margin-bottom: ${({ theme }) => theme.margins.default};
    }

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
            min-width: calc(6 / 12 * 100vw - 32px);
        `)}

    ${({ theme }) =>
      theme.mediaQuery.desktop(`
            min-width: calc(4 / 12 * 100vw - 32px);
        `)}

        &:nth-child(even) {
      transform: translateY(calc(1 / 12 * 100vw));
    }
  }
`;

export const StyledHorizontalPreviewCase = styled.div`
  > div {
    padding-bottom: 75%;
    height: 0;
    position: relative;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  a {
    height: 100%;
    > div {
      width: 100%;
      height: 100%;
    }
  }
`;
