import React, { useContext, useRef } from "react";
import { StyledMediaCarousel, StyledMediaCarouselSlide } from "./styles.js";
import { Media } from "../";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { MouseContext } from "../../contexts/Mouse";

SwiperCore.use([Navigation]);

const MediaCarousel = ({ style, list }) => {
  const { cursorChangeHandler, cursorChangeText } = useContext(MouseContext);
  const arrowPrev = useRef(null);
  const arrowNext = useRef(null);

  return (
    <StyledMediaCarousel
      onMouseEnter={() => {
        cursorChangeHandler("drag");
        cursorChangeText("");
      }}
      onMouseLeave={() => {
        cursorChangeHandler("");
        cursorChangeText("");
      }}
    >
      <span
        ref={arrowPrev}
        onMouseOver={() => {
          cursorChangeHandler("arrow-slide-prev");
          cursorChangeText("");
        }}
        onMouseLeave={() => {
          cursorChangeHandler("drag");
          cursorChangeText("");
        }}
      ></span>
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: arrowNext.current,
          prevEl: arrowPrev.current,
        }}
        slidesPerView={"auto"}
        loop={true}
        centeredSlides={false}
        centerInsufficientSlides={true}
        slideToClickedSlide={true}
      >
        {list.map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <StyledMediaCarouselSlide data-ratio={style}>
                <Media {...item.media} />
              </StyledMediaCarouselSlide>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <span
        ref={arrowNext}
        onMouseOver={() => {
          cursorChangeHandler("arrow-slide-next");
          cursorChangeText("");
        }}
        onMouseLeave={() => {
          cursorChangeHandler("drag");
          cursorChangeText("");
        }}
      ></span>
    </StyledMediaCarousel>
  );
};

export default MediaCarousel;
