import styled, { css } from "styled-components";

export const StyledArchiveJobs = styled.div`
  margin-bottom: ${({ theme }) => theme.col(0.5)};

  ul {
    padding-right: ${({ theme }) => theme.col(1)};
    padding-left: ${({ theme }) => theme.col(1)};
    width: 100%;

    li {
      border-bottom: 1px solid #cdcdcd;

      ${({ theme }) =>
        theme.mediaQuery.desktop(`
        `)}

      &:first-of-type {
        padding-top: 0;
      }
    }
  }
`;

export const StyledHeroJobs = styled.div`
  padding: ${({ theme }) => theme.col(1)};
  padding-top: ${({ theme }) => theme.col(4)};
  padding-right: ${({ theme }) => theme.col(1)};
  z-index: 99;
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding: calc(100vw/12*1);
    padding-top: calc(100vw/12*2);
    padding-right: calc(100vw/12*1);
  `)}

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  em {
    display: inline-block;
    color: ${({ theme }) => theme.colors.accent};
    text-transform: capitalize;
  }
`;

export const StyledHeroJobsFilters = styled.div`
  button {
    position: relative;
    appearence: none;
    -webkit-appearence: none;
    ${({ theme }) => theme.typo("subHeader")};
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: currentColor;
    margin-right: 2.8rem;
    text-transform: capitalize;
    font-family: "Manrope", sans-serif;

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(2.2rem, 0%);
    }

    &[data-status="true"] {
      opacity: 0;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: currentColor;
    }
  }

  br {
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      display: none;
    `)};
  }

  [data-filter] {
    cursor: pointer;
    display: inline-block;
    margin: 0 1rem;
    position: relative;
    white-space: nowrap;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    ul {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      padding: 0;
      transform: translate(0, -50%);
      white-space: nowrap;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

      &[data-status="true"] {
        opacity: 1;
        pointer-events: visible;
        z-index: 99999;
      }

      li {
        position: relative;
        z-index: 2;
        border: 0;
      }

      div {
        display: block;
        width: 200vw;
        height: 200vh;
        background-color: ${({ theme }) => theme.colors.white};
        position: fixed;
        top: -100vh;
        left: -100vw;
        opacity: 0.96;
        z-index: 0;
      }
    }

    .no-touch & {
      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

export const StyledHeroJobsTitle = styled.div`
  margin-bottom: calc(100vw / 12 * 0.5);
  overflow: visible;

  [data-cities] {
    display: flex;
    white-space: nowrap;
  }

  .swiper {
    margin-left: 0.3em;
    overflow: hidden;
    pointer-events: none;
  }

  > div {
    &[data-active="false"] {
      opacity: 0;
    }
  }

  em {
    div {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        position: absolute;
      }
    }
  }
`;

export const StyledListJobs = styled.div`
  [data-bg="grey"] {
    background: #f5f5f5;
  }

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*1);
        padding-right: calc(100vw/12*1);
      `)}
`;

export const StyledZeroResults = styled.div`
  padding-right: ${({ theme }) => theme.col(1)};
  padding-left: ${({ theme }) => theme.col(1)};
  margin-top: ${({ theme }) => theme.col(-0.5)};
  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}
`;

export const StyledListItemElement = styled.div`
  padding: ${({ theme }) => theme.margins.default} 0;
  padding-right: 4rem;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    right: 0;

    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      right: 3.2rem;
    `)};
    * {
      transition: stroke 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      stroke: ${({ theme }) => theme.colors.accent};
    }
  }

  > div {
    position: relative;
    z-index: 1;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.accent};
    z-index: 0;
    transform: scaleY(0);
    transform-origin: bottom left;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .no-touch & {
    &:hover {
      color: ${({ theme }) => theme.colors.white100};

      svg {
        * {
          stroke: ${({ theme }) => theme.colors.white};
        }
      }

      > div {
        transform: translateX(32px);
      }

      &:after {
        transform: scaleY(1);
      }
    }
  }

  small,
  strong {
    display: block;
    position: relative;
    z-index: 1;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  small {
    ${({ theme }) => theme.typo("small")};
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => theme.rem(5)};
    letter-spacing: 1px;
    display: flex;

    > div {
      margin-left: ${({ theme }) => theme.rem(5)};
      font-weight: 700;

      &:before {
        content: "/";
        display: inline;
      }

      span {
        margin-left: ${({ theme }) => theme.rem(5)};
      }
    }
  }

  strong {
    ${({ theme }) => theme.typo("titleSmall")};
  }
`;

export const StyledHeroCanvas = styled.div`
  height: 100vh;
  background-color: #000;
`;
