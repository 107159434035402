import styled, { css } from "styled-components";

export const StyledEditorialAccordion = styled.div`
  padding-left: ${({ theme }) => theme.col(1)};
  padding-right: ${({ theme }) => theme.col(1)};
  padding-top: ${({ theme }) => theme.col(2)};
  padding-bottom: 0;
  margin-bottom: ${({ theme }) => theme.col(-2)};
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.ultraWide(`
        padding-left: calc(100vw/12*2);
        padding-right: calc(100vw/12*2);
      `)}

  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    margin-bottom: ${theme.col(-1)};
    padding-top: ${theme.col(1)};
  `)}

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 8rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, black, transparent);
    pointer-events: none;
    transition: opacity 0.5s ease;
    will-change: opacity;
    z-index: 2;
  }

  &.-active {
    &:after {
      opacity: 0;
    }
  }
`;

export const StyledEditorialAccordionTitle = styled.div`
  padding-bottom: ${({ theme }) => theme.col(1)};
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding-bottom: ${theme.col(0.5)};
  `)}
`;

export const StyledEditorialAccordionContent = styled.div`
  height: 90px;
  overflow: hidden;
  box-sizing: border-box;

  > div {
    padding-bottom: ${({ theme }) => theme.col(1)};
    ${({ theme }) =>
      theme.mediaQuery.tablet(`
      padding-bottom: 0
    `)}
  }
`;

export const StyledEditorialAccordionGrid = styled.div`
  padding-top: ${({ theme }) => theme.col(1)};
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) =>
    theme.mediaQuery.tablet(`
    padding-top: ${theme.col(0.5)};
  `)}

  > div {
    padding-right: ${({ theme }) => theme.col(1)};
    ${({ theme }) => theme.typo("caption")};

    a {
      color: ${({ theme }) => theme.colors.accent};
    }

    strong {
      font-weight: 900;
      text-transform: uppercase;
      display: block;
    }

    > div {
      margin-top: 1.6rem;
      color: ${({ theme }) => theme.colors.grey40};
    }
  }
  [data-richtext-wrapper] {
    > div {
      * + * {
        margin-top: 1.2rem;
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledEditorialAccordionTrigger = styled.div`
  padding-bottom: ${({ theme }) => theme.col(0.25)};

  button {
    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
    color: white;
    background: none;
    padding: 0;
    text-transform: uppercase;
    ${({ theme }) => theme.typo("caption")};
    font-weight: 900;

    span {
      &:first-of-type {
        display: inline-block;
        .-active & {
          display: none;
        }
      }
      &:last-of-type {
        display: none;
        .-active & {
          display: inline-block;
        }
      }
    }
  }
`;

export const StyledAccordionShadow = styled.div`
  display: block;
  width: 100%;
  height: 10rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  &.-active {
    display: none;
  }
`;
